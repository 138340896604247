import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { ReactComponent as PictureIcon } from "./icons/picture.svg";
import { ReactComponent as IDScanIcon } from "./icons/idScan.svg";
import { ReactComponent as QuestionIcon } from "./icons/question.svg";
import { ReactComponent as PrintIcon } from "./icons/print.svg";
import { ReactComponent as TempCheckIcon } from "./icons/tempCheck.svg";
import { ReactComponent as MessageIcon } from "./icons/message.svg";

const items = [
  {
    key: 'picture',
    label: 'Picture',
    Icon: PictureIcon,
    canBeDuplicated: false,
  }, {
    key: 'idScan',
    label: 'ID Scan',
    Icon: IDScanIcon,
    canBeDuplicated: false,
  }, {
    key: 'form',
    label: 'Form',
    Icon: IDScanIcon,
    canBeDuplicated: false,
  // }, {
  //   key: 'form2',
  //   label: 'Form 2',
  //   Icon: IDScanIcon,
  //   canBeDuplicated: false,
  }, {
    key: 'question',
    label: 'Question',
    Icon: QuestionIcon,
    canBeDuplicated: true,
  }, {
    key: 'print',
    label: 'Print Badge',
    Icon: PrintIcon,
    canBeDuplicated: false,
  }, {
    key: 'tempCheck',
    label: 'Temp Check',
    Icon: TempCheckIcon,
    canBeDuplicated: false,
  }, {
    key: 'message',
    label: 'Message',
    Icon: MessageIcon,
    canBeDuplicated: true,
  },
];

const AddStepModal = ({ isOpen, toggle, steps, onSelect }) => (
  <Modal isOpen={isOpen} toggle={toggle} centered className="AddStepModal">
    <ModalHeader toggle={toggle}>Add step</ModalHeader>
    <ModalBody className="AddStepModal-content">
      {items.map((item) => (
        <div
          key={item.key}
          className={`AddStepModal-item${(steps.find((step) => step.type === item.key) && !item.canBeDuplicated) ? ' hidden' : ''}`}
          onClick={() => onSelect(item.key)}
        >
          <div className="AddStepModal-item-icon">
            <item.Icon />
          </div>
          <p>{item.label}</p>
        </div>
      ))}
    </ModalBody>
  </Modal>
);

export default AddStepModal;
