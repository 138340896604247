import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import '../addDevice/addDevice.scss'
import { formatPhoneNumber } from '../../utils'
import ToggleSwitch from '../sharedElements/ToggleSwitch'
import SelectSearch from 'react-select-search'
import { Button, Card, CardHeader, CardBody, FormGroup, Input, Row, Col, Label, Form, CustomInput } from 'reactstrap'
import { InputFilled } from "../ManualOnboarding/InputFilled";

function EditEmployeeModal(props) {
    const vaccines = useSelector((state) => state.vaccines)
    const [newEmployee, setNewEmployee] = useState({})
    const [otherVaccine, setOtherVaccine] = useState('')

    function parseEmployee(employee) {
        let parsed = {...employee, company: employee.company ? employee.company._id : ''}
        if(vaccines.includes(employee.vaccineName) || employee.vaccineName == '') {
            return parsed
        } else {
            setOtherVaccine(employee.vaccineName)
            return {...parsed, vaccineName: 'other'}
        }
    }

    const toggleVaccinated = (checked) => {
        handleEmployeeInfo({ target: { name: 'vaccinated', value: checked } });
    };

    useEffect(() => {
        console.log(props.employee)
        setNewEmployee(parseEmployee(props.employee))
    }, [props.employee])

    const handleEmployeeInfo = (e) => {
        if(e.target.name == 'phone') {
            setNewEmployee({
                ...newEmployee,
                [e.target.name]: formatPhoneNumber(e.target.value)
            })
        } else {
            setNewEmployee({
                ...newEmployee,
                [e.target.name]: e.target.value
            })
        }
    }

    const handleAddNewCompany = () => {
        props.setShowCreateCompany(true)
    }

    const filterContact = (contacts) => {
        return (searchValue) => {
            return contacts.filter(c => c.name.toLowerCase().includes(searchValue.toLowerCase()))
        }
    }

    const setSaveEmployee = (e, employee) => {
        if(employee.vaccineName == 'other') employee = { ...employee, vaccineName: otherVaccine}
        props.handleSave(e, employee)
    }

    return (
        <div className="card-wrapper whiteModalOverlay ContactManagementModal">
            <Card className="bg-white" style={{width: '560px', height: '638px', overflow: 'auto', paddingBottom: '16px'}}>
                <CardHeader className="d-flex justify-content-between align-items-center bg-white border-bottom-0 pb-0">
                    <h3 className="mb-0 font-weight-400">Edit staff contact</h3>
                    <div style={{ cursor: 'pointer' }} onClick={() => props.setIsShowing(false)}>x</div>
                </CardHeader>
                <CardBody className="bg-white">
                    <Form className="outline-dark" onSubmit={(e) => setSaveEmployee(e, newEmployee)}>
                        <Row>
                            <Col md="12">
                                <div className="row">
                                    <FormGroup className="col">
                                        <InputFilled className="InputFilledFullWidth" label="First name*" placeholder="First name" name="firstName" value={newEmployee.firstName} onChange={handleEmployeeInfo} />
                                    </FormGroup>
                                    <FormGroup className="col">
                                        <InputFilled className="InputFilledFullWidth" label="Last name*" placeholder="Last name" name="lastName" value={newEmployee.lastName} onChange={handleEmployeeInfo} />
                                    </FormGroup>
                                </div>
                                <div className="row">
                                    <FormGroup className="col">
                                        <Label className="font-weight-400">Company*</Label>
                                        <div className="companyRow">
                                            <SelectSearch className="searchselect-light searchselect-grey companySelect" placeholder="Select Company" printOptions="on-focus" value={newEmployee.company} filterOptions={filterContact} onChange={(e) => handleEmployeeInfo({target: {name: 'company', value: e}})} search
                                                          options={props.companies.map(company => {
                                                              return {name: company.company, value: company._id}
                                                          })}
                                            />
                                            <p style={{cursor: "pointer", color: "blue", background: '#F4F7FA'}} onClick={handleAddNewCompany}>+ Add new</p>
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className="row">
                                    <FormGroup className="col">
                                        <InputFilled className="InputFilledFullWidth" label="Phone number*" placeholder="Phone number" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required value={newEmployee.phone} name="phone" onChange={handleEmployeeInfo} />
                                    </FormGroup>
                                </div>
                                <div className="row">
                                    <FormGroup className="col">
                                        <InputFilled className="InputFilledFullWidth" label="Email*" placeholder="Email" required value={newEmployee.email} name="email" onChange={handleEmployeeInfo} />
                                    </FormGroup>
                                </div>
                                <div className="row">
                                    <FormGroup className="col">
                                        <InputFilled className="fieldRight" label="Days valid*" required value={newEmployee.expirationDays} type="number" name="expirationDays" min="1" max="365" onChange={handleEmployeeInfo} />
                                    </FormGroup>
                                </div>
                                <hr />
                                <div className="row">
                                    <FormGroup className="fieldRight">
                                        <div className="col">
                                            <Label>Has staff been vaccinated?*</Label>
                                            <div>
                                                <CustomInput type="radio" id="exampleCustomRadio" name="yes" value='yes' label="Yes" checked={!!newEmployee.vaccinated} onChange={() => toggleVaccinated(true)} />
                                                <CustomInput type="radio" id="exampleCustomRadio2" name="no" value='no' label="No" checked={!newEmployee.vaccinated} onChange={() => toggleVaccinated(false)} />
                                            </div>
                                        </div>
                                    </FormGroup>
                                </div>
                                {newEmployee.vaccinated ?
                                <>
                                    <div className="row">
                                        <div className="col">
                                            <FormGroup>
                                                <Label>Vaccine name*</Label>
                                                <Input type="select" name="vaccineName" id="vaccineName" className="selectFilled" value={newEmployee.vaccineName} onChange={handleEmployeeInfo} required>
                                                    <option value="">Select Vaccine</option>
                                                    {vaccines.map((vaccine, index) => <option key={index} value={vaccine}>{vaccine}</option>)}
                                                    <option value="other">Other</option>
                                                </Input>
                                            </FormGroup>
                                            {newEmployee.vaccineName == "other" ?
                                                <FormGroup>
                                                    <Input placeholder="Enter vaccine name" type="text" value={otherVaccine} onChange={(e) => setOtherVaccine(e.target.value)} required />
                                                </FormGroup>
                                            : null}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <FormGroup className="col">
                                            <Label className="font-weight-400">First jab date*</Label>
                                            <Input
                                                type="date"
                                                name="jab1Date"
                                                className="selectFilled"
                                                placeholder="Enter first date"
                                                value={newEmployee.jab1Date ? newEmployee.jab1Date.split('T')[0] : ''}
                                                onChange={handleEmployeeInfo}
                                                required
                                            />
                                        </FormGroup>
                                        <FormGroup className="col">
                                            <Label className="font-weight-400">Second jab date</Label>
                                            <Input
                                                type="date"
                                                name="jab2Date"
                                                className="selectFilled"
                                                placeholder="Enter second date"
                                                value={newEmployee.jab2Date ? newEmployee.jab2Date.split('T')[0] : ''}
                                                onChange={handleEmployeeInfo}
                                            />
                                        </FormGroup>
                                    </div>
                                </>
                                :
                                    <div className="row">
                                        <FormGroup className="col-4">
                                            <Label className="font-weight-400">Last test date</Label>
                                            <Input
                                                type="date"
                                                name="testDate"
                                                placeholder="Enter last test date"
                                                value={newEmployee.testDate ? newEmployee.testDate.split('T')[0] : ''}
                                                onChange={handleEmployeeInfo}
                                            />
                                        </FormGroup>
                                    </div>
                                }
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-end my-3 mr-0 buttons pb-4">
                            <Button color="secondary" size="md" onClick={() => props.setIsShowing(false)}>Cancel</Button>
                            <Button color="primary" size="md" type="submit">Save</Button>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}

export default EditEmployeeModal
