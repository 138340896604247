import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Row,
  Container,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
} from "reactstrap";
import axios from "../../axiosconfig";
import {
  updateAdmin,
  createAdmin,
  removeAdmin,
  findZones,
  addZone,
} from "../../actions/userActions";
import SimpleHeader from "../SimpleHeader/SimpleHeader";
import PaginationTable from "../VisitorLog/TablePagination";
import useForm from "./useForm";
import validateAdmin from "./validateAdmin";
import EditAdminModal from "./EditAdminModal";
import "./createAdmin.scss";
import useClickOutsideListenerRef from "./useClickOutsideListenerRef";
import NewZoneModal from "./NewZoneModal";
import { Table } from "../Table/Table";

import ConfirmAdminDeleteModal from "./ConfirmAdminDeleteModal";
import { sortArrByColumn } from "../../utils";
import PermissionWrapper from "../permissions/PermissionWrapper";
import { SCOPES } from "../permissions/permission-map";

const mapColumnToDataName = {
  name: "firstName",
  username: "username",
  authType: "authType",
  zone: "zoneName",
};

function CreateAdmin(props) {
  const {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
    setErrors,
  } = useForm(submitCreate, validateAdmin);

  const [admins, setAdmins] = useState([]);
  const [zones, setZones] = useState(props.zones);
  const [currentPage, setCurrentPage] = useState(1);
  const [adminsPerPage] = useState(5);
  const [adminToEdit, setAdminToEdit] = useState({});
  const [editing, setEdting] = useState(false);
  //these are for running the dropdown menus
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [zoneDropDownOpen, setZoneDropdown] = useState(false);
  const [newZoneModalOpen, setNewZoneModal] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleZone = () => setZoneDropdown((prevState) => !prevState);
  const toggleNewZone = () => setNewZoneModal((prevState) => !prevState);
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const auth_levels = ["admin", "manager"];
  const [adminToDelete, setAdminToDelete] = useState({});
  const [toggleDelAdmin, setToggleDelAdmin] = useState(false);
  const [hovered, setHovered] = useState(false);

  const [sort, setSort] = useState({
    col: null,
    desc: false,
  });

  const getAdmins = async () => {
    try {
      const response = await axios({
        url: "/admin/all-admin",
        method: "GET",
        headers: { authorization: `Bearer ${props.token}` },
      });
      if (response.data) {
        setAdmins(response.data);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const getZones = async () => {
    try {
      const response = await axios({
        url: "/admin/findZones",
        method: "GET",
        headers: { authorization: `Bearer ${props.token}` },
      });
      if (response.data) {
        setZones(response.data);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const onChangeSort = (e) => {
    const { newSort, newArr } = sortArrByColumn(
      e,
      sort,
      admins,
      mapColumnToDataName
    );
    setSort(newSort);
    setAdmins(newArr);
  };

  function submitCreate() {
    props.createAdmin(values, props.token);
    alert(`Admin ${values.firstName} has been added`);
    let page = 0;
    page = page + currentPage;
    setValues({
      ...values,
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      zoneName: "Unassigned",
    });
    setShowAddAdmin(false);
    setTimeout(() => {
      getAdmins();
      setCurrentPage(page);
    }, 500);
    setCurrentPage(1);
  }

  async function submitUpdate(adm, pg) {
    props.updateAdmin(adm, props.token);
    alert(`Admin ${adm.firstName} has been updated`);
    setEdting(false);
    setTimeout(() => {
      getAdmins();
      setAdminToEdit(adm);
      setCurrentPage(pg);
    }, 100);
  }

  function submitRemove(admin, pg) {
    props.removeAdmin(admin, props.token);
    alert(`Admin ${admin.firstName} ${admin.lastName} has been removed`);

    setToggleDelAdmin(false);
    setTimeout(() => {
      getAdmins();
      setCurrentPage(pg);
      setEdting(false);
    }, 500);
    setCurrentPage(1);
  }

  useEffect(() => {
    getAdmins(props.token);
  }, [props.admins]);

  function createZone(zone) {
    props.addZone(zone, props.token);
    setTimeout(() => {
      getZones();
    }, 250);
  }

  useEffect(() => {
    getZones();
  }, []);

  const indexOfLastAdmin = currentPage * adminsPerPage;
  const indexOfFirstAdmin = indexOfLastAdmin - adminsPerPage;
  let currentAdmins = admins.slice(indexOfFirstAdmin, indexOfLastAdmin);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const ref = useRef(false);

  useClickOutsideListenerRef(ref, () => {
    setEdting(false);
  });

  const getTableData = () => ({
    columns: [
      { key: "index", label: "# Number", alignCenter: true, small: true },
      { key: "name", label: "Name", sortable: true },
      { key: "username", label: "Username", sortable: true },
      { key: "authType", label: "Auth Type", sortable: true },
      { key: "zone", label: "Zone", sortable: true },
      {
        key: "actions",
        label: "Auth Type",
        alignCenter: true,
        noPadding: true,
        extraLarge: true,
      },
    ],
    content: currentAdmins?.map((admin, index) => {
      return [
        { key: "index", value: index + 1 },
        { key: "name", value: `${admin.firstName} ${admin.lastName}` },
        { key: "username", value: admin.username },
        {
          key: "authType",
          value:
            admin.authType.charAt(0).toUpperCase() +
            admin.authType.substring(1),
        },
        {
          key: "zone",
          value: admin.zoneName === "default" ? "Unassigned" : admin.zoneName,
        },
        {
          key: "actions",
          value: (
            <div
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(false)}
            >
              {props.admin.username === admin.username ? (
                <h5>---</h5>
              ) : (
                <div>
                  <Button
                    className="button-default"
                    color="light"
                    style={
                      hovered === index
                        ? { opacity: 1.0 }
                        : {
                            color: "white",
                            backgroundColor: "white",
                            opacity: 0.0,
                          }
                    }
                    onClick={() => {
                      setAdminToEdit(admin);
                      if (editing) {
                        setEdting(false);
                      }
                      setTimeout(() => {
                        setEdting(true);
                      }, 100);
                      // console.log(
                      //   "activate modal " + JSON.stringify(admin)
                      // );
                    }}
                  >
                    <i className="fa fa-pencil-square-o fa-1x mr-2" />
                    Edit
                  </Button>
                  <Button
                    className="button-success"
                    color="dark"
                    style={
                      hovered === index
                        ? { opacity: 1.0, backgroundColor: "#EB3446" }
                        : {
                            color: "white",
                            backgroundColor: "white",
                            opacity: 0.0,
                          }
                    }
                    onClick={() => {
                      setAdminToDelete(admin);
                      setToggleDelAdmin(true);
                    }}
                  >
                    <i className="fa fa-trash-o fa-1x mr-2" />
                    Delete
                  </Button>
                </div>
              )}
            </div>
          ),
        },
      ];
    }),
  });

  return (
    <PermissionWrapper scopes={[SCOPES.userManagement]}>
      <SimpleHeader name="User Management" parent="security" />
      <Container className="mt-4" fluid>
        <Row className="justify-content-start" md="7">
          {newZoneModalOpen ? (
            <NewZoneModal
              zones={props.zones}
              submit={createZone}
              setShowModal={setNewZoneModal}
            />
          ) : null}

          {showAddAdmin ? (
            <Row className="card-wrapper delModalOverlay">
              <Card className="bg-white">
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Row>
                            <Label className="font-weight-bold pl-2">
                              Name
                            </Label>
                          </Row>
                          <Row xs={12} className="d-flex">
                            <Col xs={6}>
                              <InputGroup className="input-group-merge input-group-alternative">
                                <Input
                                  type="text"
                                  name="firstName"
                                  value={values.firstName}
                                  onChange={handleChange}
                                  placeholder="First name"
                                  xs="6"
                                  inline
                                />
                              </InputGroup>
                              {errors.firstName && (
                                <p className="error">{errors.firstName}</p>
                              )}
                            </Col>
                            <Col xs={6}>
                              <InputGroup className="input-group-merge input-group-alternative">
                                <Input
                                  type="text"
                                  name="lastName"
                                  value={values.lastName}
                                  onChange={handleChange}
                                  placeholder="Last name"
                                  xs="6"
                                  inline
                                />
                              </InputGroup>
                              {errors.lastName && (
                                <p className="error">{errors.lastName}</p>
                              )}
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label className="font-weight-bold">Email</Label>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input
                              type="text"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              placeholder="Enter e-mail"
                            />
                          </InputGroup>
                          {errors.email && (
                            <p className="error">{errors.email}</p>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label className="font-weight-bold">
                            *
                            {values.authType.charAt(0).toUpperCase() +
                              values.authType.substring(1)}{" "}
                            Username
                          </Label>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input
                              type="text"
                              name="username"
                              value={values.username}
                              onChange={handleChange}
                              placeholder="Enter admin name"
                            />
                          </InputGroup>
                          {errors.username && (
                            <p className="error">{errors.username}</p>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label className="font-weight-bold">
                            *
                            {values.authType.charAt(0).toUpperCase() +
                              values.authType.substring(1)}{" "}
                            Password
                          </Label>
                          <InputGroup className="input-group-merge input-group-alternative">
                            <Input
                              type="password"
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                            />
                          </InputGroup>
                          {errors.password && (
                            <p className="error">{errors.password}</p>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Row xs="12">
                            <Col xs="6">
                              <Label className="font-weight-bold">
                                Zone Assignment
                              </Label>
                              <InputGroup>
                                <Dropdown
                                  isOpen={zoneDropDownOpen}
                                  toggle={toggleZone}
                                  style={{ width: "100%" }}
                                >
                                  <DropdownToggle
                                    caret
                                    style={{ width: "100%" }}
                                  >
                                    {values.zoneName === ""
                                      ? "Unassigned"
                                      : // values.zoneName.charAt(0).toUpperCase() +
                                        //   values.zoneName.substring(1)}
                                        values.zoneName}
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      name="zoneDropdown"
                                      onClick={handleChange}
                                      value={"Unassigned"}
                                    >
                                      Unassigned
                                    </DropdownItem>
                                    {zones
                                      ? zones.map((value, index) => {
                                          //console.log(value);
                                          return (
                                            <DropdownItem
                                              key={index}
                                              name="zoneDropdown"
                                              onClick={handleChange}
                                              value={value.zoneName}
                                            >
                                              {value.zoneName}
                                            </DropdownItem>
                                          );
                                        })
                                      : null}
                                    <DropdownItem
                                      onClick={() => {
                                        toggleNewZone();
                                      }}
                                    >
                                      <h5>+ Add New Zone...</h5>
                                      {/* <Button
                                            onClick={() => {
                                              toggleNewZone();
                                            }}
                                          >
                                            + Add new zone...
                                          </Button> */}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </InputGroup>
                            </Col>
                            <Col xs="6">
                              <Label className="font-weight-bold">
                                Access Level
                              </Label>
                              <InputGroup>
                                <Dropdown
                                  isOpen={dropdownOpen}
                                  toggle={toggle}
                                  style={{ width: "100%" }}
                                >
                                  <DropdownToggle
                                    caret
                                    style={{ width: "100%" }}
                                  >
                                    {values.authType.charAt(0).toUpperCase() +
                                      values.authType.substring(1)}
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    {auth_levels.map((value, index) => {
                                      return (
                                        <DropdownItem
                                          key={index}
                                          name="dropdown"
                                          onClick={handleChange}
                                          value={value}
                                        >
                                          {value.charAt(0).toUpperCase() +
                                            value.substring(1)}
                                        </DropdownItem>
                                      );
                                    })}
                                  </DropdownMenu>
                                </Dropdown>
                              </InputGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="ml-2">
                      <Col className="mb-2">
                        <small>
                          {/* * Please fill out all fields to create {values.authType} */}
                          ALL FIELDS ARE REQUIRED
                        </small>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-right" md="12">
                        <Button
                          size="md"
                          color="secondary"
                          onClick={() => setShowAddAdmin(false)}
                        >
                          Cancel
                        </Button>
                        <Button size="md" color="primary" type="submit">
                          {/* Create {values.authType.charAt(0).toUpperCase() + values.authType.substring(1)} */}
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Row>
          ) : // </Col>
          null}
          {editing ? (
            <EditAdminModal
              values={adminToEdit}
              setAdmins={setAdmins}
              updateAdmin={submitUpdate}
              removeAdmin={submitRemove}
              page={currentPage}
              back={() => {
                setEdting(false);
              }}
              zones={zones}
            >
              this is a thing
            </EditAdminModal>
          ) : null}
          <Col md="12">
            <Card>
              <Row className="table-responsive pb-3 ml-0 ">
                <Table
                  CustomHeaderTabs={() => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                      className="pr-4"
                    >
                      <h3 className="mb-0">Current Users</h3>
                      {/* <Button color="secondary" style={{margin: "1.3em"}} onClick={(prevState)=> setShowAddAdmin(!showAddAdmin)}> */}
                      {/* Add {values.authType.charAt(0).toUpperCase()+values.authType.substring(1)}   */}
                      <Button
                        className="bg-primary"
                        color="primary"
                        style={{ margin: "1.3em" }}
                        onClick={(prevState) => setShowAddAdmin(!showAddAdmin)}
                      >
                        <i className="fa fa-plus fa-1x mr-2" />
                        Create New Admin
                      </Button>
                    </div>
                  )}
                  sourcePage="createAdmin"
                  content={getTableData().content}
                  columns={getTableData().columns}
                  sort={sort}
                  onChangeSort={onChangeSort}
                  Pagination={() => (
                    <nav aria-label="...">
                      <PaginationTable
                        per_page={adminsPerPage}
                        current_page={currentPage}
                        total_data={admins.length}
                        handleClick={paginate}
                      />
                    </nav>
                  )}
                />

                {toggleDelAdmin ? (
                  <ConfirmAdminDeleteModal
                    adminToDelete={adminToDelete}
                    setToggleDelAdmin={setToggleDelAdmin}
                    remove={submitRemove}
                    page={currentPage}
                  />
                ) : null}
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </PermissionWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    admins: state.admins,
    admin: state.admin,
    zones: state.zones,
    token: state.token,
  };
};

export default connect(mapStateToProps, {
  updateAdmin,
  createAdmin,
  removeAdmin,
  findZones,
  addZone,
})(CreateAdmin);
