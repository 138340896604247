export const ROLES = {
  admin: "admin",
  manager: "manager",
};

export const SCOPES = {
  dashboard: "dashboard",
  manualOnboarding: "manualOnboarding",
  visitorsLog: "visitorsLog",
  userManagement: "userManagement",
  deviceManagment: "deviceManagement",
  zoneManagement: "zoneManagement",
  integrations: "integrations",
  configuration: "configuration",
};

export const PERMISSIONS = {
  [ROLES.manager]: [
    SCOPES.dashboard,
    SCOPES.manualOnboarding,
    SCOPES.visitorsLog,
  ],
  [ROLES.sentry]: [
    SCOPES.dashboard,
    SCOPES.manualOnboarding,
    SCOPES.visitorsLog,
    SCOPES.userManagement,
    SCOPES.deviceManagment,
    SCOPES.zoneManagement,
    SCOPES.integrations,
    SCOPES.configuration,
  ],
  [ROLES.admin]: [
    SCOPES.dashboard,
    SCOPES.manualOnboarding,
    SCOPES.visitorsLog,
    SCOPES.userManagement,
    SCOPES.deviceManagment,
    SCOPES.zoneManagement,
    SCOPES.integrations,
    SCOPES.configuration,
  ],
};
