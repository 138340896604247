import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleSelector } from "../../actions/common/index";
import { ReactComponent as AngleDownIcon } from "../../assets/tabs/angle-down.svg";
import { ReactComponent as CheckmarkIcon } from "../../assets/tabs/checkmark.svg";

export const ColumnsDropdown = ({
  options,
  selectedOptions,
  onToggleOption,
  className,
}) => {
  const open = useSelector((state) => state.openedSelector);
  const dispatch = useDispatch();

  const handleToggleSelector = () => {
    dispatch(toggleSelector());
  };

  return (
    <div className={`ColumnsDropdown${className ? ` ${className}` : ""}`}>
      <div className="ColumnsDropdown-button" onClick={handleToggleSelector}>
        Table columns
        <AngleDownIcon />
      </div>
      {open && (
        <div className="ColumnsDropdown-options">
          {options.map((option) => (
            <div
              key={option.key}
              className={`ColumnsDropdown-option${
                option.borderBottom ? " borderBottom" : ""
              }`}
              onClick={() => {
                option.onClick ? option.onClick() : onToggleOption(option.key);
              }}
            >
              <div
                className={`ColumnsDropdown-option-icon
                  ${
                    selectedOptions.includes(option.key) || option.active
                      ? " active"
                      : ""
                  }
                `}
              >
                <CheckmarkIcon />
              </div>
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
