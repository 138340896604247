import React, { useRef } from "react";
import { Input } from "reactstrap";

const FileUploader = ({ onFileSelectSuccess, onFileSelectError, required }) => {
  const fileInput = useRef(null);

  const handleFileInput = (e) => {
    // handle validations
    const file = e.target.files[0];
    // console.log("file:", file);
    if (file.size > 1024000 * 10)
      onFileSelectError({ error: "File size cannot exceed more than 1MB" });
    else onFileSelectSuccess(file);
  };

  return (
      <Input type="file" onChange={handleFileInput} required={required} />
  );
};

export default FileUploader;
