import React from "react";
import { PERMISSIONS } from "./permission-map";
import { useDispatch, useSelector } from "react-redux";
import { toggleSelector } from "../../actions/common";

const hasPermission = ({ permissions, scopes }) => {
  let scopesMap = {};
  scopes.forEach((scope) => {
    scopesMap[scope] = true;
  });

  return permissions.some((permission) => scopesMap[permission]);
};

export default function PermissionWrapper({ children, scopes = [] }) {
  const admin = useSelector((state) => state.admin);
  const role = admin.authType;
  const permissions = PERMISSIONS[role];

  const permissionGranted = hasPermission({ permissions, scopes });

  const open = useSelector((state) => state.openedSelector);
  const dispatch = useDispatch();

  const closeSelector = () => {
    if (open === true) {
      dispatch(toggleSelector());
    }
  };

  if (!permissionGranted) return <></>;
  return <div onClick={closeSelector}>{children}</div>;
}
