export const defaultData = [{
  type: 'welcome',
}, {
  type: 'picture',
}, {
  type: 'idScan',
}, {
  type: 'form',
  values: [
    { key: 'firstName', storable: true, required: true },
    { key: 'lastName', storable: true, required: true },
    { key: 'dob', storable: true, required: true },
    { key: 'documentNumber', storable: true, required: true },
    { key: 'email', storable: true, required: false },
    { key: 'org', storable: true, required: true },
    { key: 'visitingName', storable: true, required: true },
  ],
}, {
  type: 'print',
}];

export const stepTypes = {
  welcome: {
    key: 'welcome',
    label: 'Welcome',
    description: 'Greet the visitor and start the process',
    draggable: false,
    editable: false,
  },
  picture: {
    key: 'picture',
    description: 'Take a picture of the visitor using the device’s camera',
    label: 'Portrait Picture',
    draggable: true,
    editable: true,
  },
  idScan: {
    key: 'idScan',
    description: 'Scan visitor’s ID using Blink-ID',
    label: 'ID Scan',
    draggable: true,
    editable: true,
  },
  form: {
    key: 'form',
    description: 'Input visitor’s data form',
    label: 'Form',
    draggable: true,
    editable: true,
  },
  form2: {
    key: 'form2',
    description: 'Input additional visitor’s data form',
    label: 'Form 2',
    draggable: true,
    editable: true,
  },
  tempCheck: {
    key: 'tempCheck',
    description: 'Check visitor’s temperature using a peripheral',
    label: 'Temperature check',
    draggable: true,
    editable: true,
  },
  message: {
    key: 'message',
    description: 'Wash your hands, use ABHR and use a mask throughout your time in the building and not shake hands with, touch or hug individuals during your visit.',
    label: 'Message',
    draggable: true,
    editable: true,
  },
  question: {
    key: 'question',
    description: 'Did you wash your hands or use alcohol-based hand rub (ABHR) on entry?',
    label: 'Question',
    draggable: true,
    editable: true,
  },
  print: {
    key: 'print',
    description: 'Print the visitor’s badge using the badge printer',
    label: 'Print Badge',
    draggable: false,
    editable: false,
  },
};
