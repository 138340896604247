import React from 'react';

export const InputFilled = ({ label, className, ...props }) => (
  <div className={`${className ? `${className} ` : ''}InputFilled`}>
    <p>{label}</p>
    <div className="InputFilled-inputWrapper">
      <input {...props} />
    </div>
  </div>
);
