import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
// import rootReducer from "./reducers";
import commonReducer from './reducers/reducer';
import stepsConfigurationReducer from './reducers/stepsConfiguration';
import languagesConfigurationReducer from './reducers/languagesConfiguration';
import { mergeReducers } from "./reducers/utils";

// const initialState = {auth: { authenticated: localStorage.getItem('token') }};

const middleware = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  mergeReducers(commonReducer, stepsConfigurationReducer, languagesConfigurationReducer),
  // rootReducer,
  // initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
