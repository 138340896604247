export default function validatePreRegister(values) {
  let errors = {};

  var validator = require("email-validator");

  function telephoneCheck(str) {
    var regex = /^(1\s?)?(\(\d{3}\)|\d{3})[\s\-]?\d{3}[\s\-]?\d{4}$/;
    return regex.test(str);
  }
  console.log("com type:", values.commType);

  if (values.commType === "email") {
    if (!values.email) {
      errors.email = "Email is required";
    } else if (!validator.validate(values.email)) {
      errors.email = "Please enter a valid email";
    }
  } else if (values.commType === "sms") {
    if (!values.sms) {
      errors.sms = "SMS number required";
    } else if (
      !telephoneCheck(values.sms) &&
      !telephoneCheck("1" + values.sms)
    ) {
      errors.sms = "Please enter valid phone number";
    }
  } else if (values.commType) {
    errors.sms = "Please enter a phone number.";
    errors.email = "Please enter an email address.";
  }
  // if (!values.email) {
  //   errors.email = "Email is required";
  // } else if (!validator.validate(values.email)) {
  //   errors.email = "Please enter a valid email";
  // }

  // if (!values.phoneNumber) {
  //   errors.password = "Phone number is required";
  // }
  return errors;
}
