import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import axios from '../../axiosconfig'
import EmployeeListObj from './EmployeeListObj'
import CompanyListObj from './CompanyListObj'
import MultiCreateEmployeeModal from './MultiCreateEmployeeModal'
import CreateEmployeeModal from './CreateEmployeeModal'
import CreateCompanyModal from './CreateCompanyModal'
import EditEmployeeModal from './EditEmployeeModal'
import EditCompanyModal from './EditCompanyModal'
import DeleteEmployeeModal from './DeleteEmployeeModal'
import DeleteCompanyModal from './DeleteCompanyModal'
import PaginationTable from '../VisitorLog/TablePagination'
import { getCurrentSort, sortArrByColumn } from '../../utils'
import { Container, Input, Col, TabContent, TabPane, Button, Row, Card,
    CardHeader, CardBody, CardFooter, Nav, NavItem, NavLink } from 'reactstrap'
import { Table } from '../Table/Table';
import './styles.scss';

function ContactTables() {
    const perPage = 8
    const [contact, setContact] = useState({})
    const [multiCreateEmployee, setMultiCreateEmployee] = useState(false)
    const [showCreateEmployee, setShowCreateEmployee] = useState(false)
    const [showCreateCompany, setShowCreateCompany] = useState(false)
    const [showEditEmployee, setShowEditEmployee] = useState(false)
    const [showEditCompany, setShowEditCompany] = useState(false)
    const [showDeleteEmployee, setShowDeleteEmployee] = useState(false)
    const [showDeleteCompany, setShowDeleteCompany] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [activeTab, setActiveTab] = useState('1')
    const [showEmployees, setShowEmployees] = useState(true)
    const [currentPageEmployees, setCurrentPageEmployees] = useState(1)
    const [currentPageCompanies, setCurrentPageCompanies] = useState(1)
    const [employees, setEmployees] = useState([])
    const [displayedEmployees, setDisplayedEmployees] = useState([])
    const [companies, setCompanies] = useState([])
    const [displayedCompanies, setDisplayedCompanies] = useState([])
    const [sort, setSort] = useState({col: null, desc: false})
    const [hovered, setHovered] = useState(false)

    const showCreateModal = () => {
        if(showEmployees) {
            setShowCreateEmployee(true)
        } else {
            setShowCreateCompany(true)
        }
    }

    const showEditModal = (contact) => {
        if(showEmployees) {
            setContact(contact)
            setShowEditEmployee(true)
        } else {
            setContact(contact)
            setShowEditCompany(true)
        }
    }

    const showDeleteModal = (contact) => {
        if(showEmployees) {
            setContact(contact)
            setShowDeleteEmployee(true)
        } else {
            setContact(contact)
            setShowDeleteCompany(true)
        }
    }

    const getEmployees = async () => {
        const response = await axios({
            url: "/employee/getEmployees",
            method: "POST"
        })
        console.log(response.data)
        setEmployees(response.data.sort((a, b) => a.lastName > b.lastName ? 1 : -1))
    }

    const getCompanies = async () => {
        const response = await axios({
            url: "/company/getCompanies",
            method: "POST"
        })
        setCompanies(response.data.sort((a, b) => a.company > b.company ? 1 : -1))
    }

    const handleSaveNewEmployee = async (e, employee) => {
        e.preventDefault()
        employee.expirationDate = new Date()
        setShowCreateEmployee(false)
        const response = await axios({
            url: "/employee/createEmployee",
            method: "POST",
            data: employee
        })
        console.log('new employee', response.data)
        getEmployees()
    }

    const handleSaveNewCompany = async (e, company) => {
        e.preventDefault()
        setShowCreateCompany(false)
        const response = await axios({
            url: "/company/createCompany",
            method: "POST",
            data: company
        })
        getCompanies()
    }

    const handleEditEmployee = async (e, employee) => {
        e.preventDefault()
        setShowEditEmployee(false)
        const response = await axios({
            url: "/employee/updateEmployee",
            method: "PUT",
            data: employee
        })
        getEmployees()
    }

    const handleEditCompany = async (e, company) => {
        e.preventDefault()
        setShowEditCompany(false)
        const response = await axios({
            url: "/company/updateCompany",
            method: "PUT",
            data: company
        })
        getCompanies()
    }

    const handleDeleteEmployee = async (e, employee) => {
        e.preventDefault()
        setShowDeleteEmployee(false)
        const response = await axios({
            url: "/employee/deleteEmployee",
            method: "DELETE",
            data: employee
        })
        getEmployees()
    }

    const handleDeleteCompany = async (e, company) => {
        e.preventDefault()
        setShowDeleteCompany(false)
        const response = await axios({
            url: "/company/deleteCompany",
            method: "DELETE",
            data: company
        })
        getCompanies()
    }

    const handlePaginationClick = (number) => {
        if (showEmployees) {
            setCurrentPageEmployees(number)
            const lastIndex = number * perPage
            const firstIndex = lastIndex - perPage
            setDisplayedEmployees(employees.slice(firstIndex, lastIndex))
        } else {
            setCurrentPageCompanies(number)
            const lastIndex = number * perPage
            const firstIndex = lastIndex - perPage
            setDisplayedCompanies(companies.slice(firstIndex, lastIndex))
        }
    }

    const getJabDate = (employee) => {
        const date = employee.jab2Date ? employee.jab2Date : (employee.jab1Date ? employee.jab1Date : new Date())
        return Date.parse(date)
    }

    const getData = () => {
        console.log(activeTab)
        if(activeTab == '1') {
            return {array: employees, setter: setEmployees}
        } else if(activeTab == '2') {
            return {array: companies, setter: setCompanies}
        }
    }

    const handleSort = (e) => {
        const colSelected = e.target.dataset?.sort
        console.log(colSelected, sort.desc)
        if(colSelected == 'firstName' || colSelected == 'lastName' || colSelected == 'phone' || colSelected == 'email' || colSelected == 'vaccineName' || colSelected == 'company') {
            const data = getData()
            if(sort.desc) {
                data.setter(data.array.sort((a, b) => a[colSelected] > b[colSelected] ? 1 : -1))
            } else {
                data.setter(data.array.sort((a, b) => a[colSelected] < b[colSelected] ? 1 : -1))
            }
        } else if(colSelected == 'companyName') {
            if(sort.desc) {
                setEmployees(employees.sort((a, b) => a.company.company > b.company.company ? 1 : -1))
            } else {
                setEmployees(employees.sort((a, b) => a.company.company < b.company.company ? 1 : -1))
            }
        } else if(colSelected == 'jabDate') {
            if(sort.desc) {
                setEmployees(employees.sort((a, b) => getJabDate(a) > getJabDate(b) ? 1 : -1))
            } else {
                setEmployees(employees.sort((a, b) => getJabDate(a) < getJabDate(b) ? 1 : -1))
            }
        }
        setSort({col: colSelected, desc: !sort.desc})
    }

    useEffect(() => {
        getEmployees()
        getCompanies()
    }, [])

    useEffect(() => {
        const lastIndex = currentPageEmployees * perPage
        const firstIndex = lastIndex - perPage
        setDisplayedEmployees(employees.filter(employee => employee.firstName.toLowerCase().includes(searchValue.toLowerCase()) || employee.lastName.toLowerCase().includes(searchValue.toLowerCase()) || employee.vaccineName.toLowerCase().includes(searchValue.toLowerCase())).slice(firstIndex, lastIndex))
    }, [employees, currentPageEmployees, searchValue, sort.desc])

    useEffect(() => {
        const lastIndex = currentPageCompanies * perPage
        const firstIndex = lastIndex - perPage
        setDisplayedCompanies(companies.filter(company => company.company.toLowerCase().includes(searchValue.toLowerCase())).slice(firstIndex, lastIndex))
    }, [companies, currentPageCompanies, searchValue, sort.desc])

    const getTable1Data = () => ({
        columns: [
            { key: 'firstName', label: 'First Name', sortable: true },
            { key: 'lastName', label: 'Last Name', sortable: true },
            { key: 'phone', label: 'Phone number', sortable: true },
            { key: 'email', label: 'Email', sortable: true },
            { key: 'companyName', label: 'Company', sortable: true },
            { key: 'vaccineName', label: 'Vaccinated', sortable: true },
            { key: 'jabDate', label: 'Jab Date', sortable: true },
            { key: 'testDate', label: 'Test Date', sortable: true },
            { key: 'actions', label: 'Actions', alignCenter: true, noPadding: true, extraLarge: true },
        ],
        content: displayedEmployees.map((employee, index) => {
            return [
                { key: 'firstName', value: employee.firstName },
                { key: 'lastName', value: employee.lastName },
                { key: 'phone', value: employee.phone || '-' },
                { key: 'email', value: employee.email },
                { key: 'companyName', value: employee.company ? employee.company.company : '-' },
                { key: 'vaccinated', value: employee.vaccinated ? employee.vaccineName : (employee.completed ? 'No' : '-') },
                { key: 'jabDate', value: employee.jab2Date ? employee.jab2Date.split('T')[0] : (employee.jab1Date ? employee.jab1Date.split('T')[0] : '-') },
                { key: 'testDate', value: employee.testDate ? employee.testDate.split('T')[0] : '-' },
                { key: 'actions', value: (
                      <div onMouseEnter={() => setHovered(index)} onMouseLeave={() => setHovered(false)}>
                          {hovered === index ? (
                            <>
                                <Button size="md" color="light" className="button-default" onClick={() => showEditModal(employee)}>
                                    <i className="fa fa-pencil-square-o fa-1x mr-2" />
                                    Edit
                                </Button>

                                <Button size="md" color="danger" className="button-cancel" onClick={() => showDeleteModal(employee)}>
                                    <i className="fa fa-trash-o fa-1x mr-2" />
                                    Delete
                                </Button>
                            </>
                          ) : (
                            <>
                                <Button variant="outline-light" className="hiddenButton">
                                    <i className="fa fa-pencil-square-o fa-1x mr-2" />
                                    Edit
                                </Button>
                                <Button variant="outline-light" className="hiddenButton">
                                    <i className="fa fa-trash-o fa-1x mr-2 light" />
                                    Delete
                                </Button>
                            </>
                          )}
                      </div>
                    )},
            ];
        }),
    });

    const getTable2Data = () => ({
        columns: [
            { key: 'company', label: 'Company Name', sortable: true },
            { key: 'phone', label: 'Phone number', sortable: true },
            { key: 'email', label: 'Email', sortable: true },
            { key: 'actions', label: 'Actions', alignCenter: true, noPadding: true, extraLarge: true },
        ],
        content: displayedCompanies.map((company, index) => {
            return [
                { key: 'company', value: company.company },
                { key: 'phone', value: company.phone },
                { key: 'email', value: company.email },
                { key: 'actions', value: (
                      <div onMouseEnter={() => setHovered(index)} onMouseLeave={() => setHovered(false)}>
                          {hovered === index ? (
                            <>
                                <Button variant="outline-light" className="hiddenButton">
                                    <i className="fa fa-pencil-square-o fa-1x mr-2" />
                                    Edit
                                </Button>
                                <Button variant="outline-light" className="hiddenButton">
                                    <i className="fa fa-trash-o fa-1x mr-2 light" />
                                    Delete
                                </Button>
                            </>
                          ) : (
                            <>
                                <Button size="md" color="light" className="button-default" onClick={() => showEditModal(company)}>
                                    <i className="fa fa-pencil-square-o fa-1x mr-2" />
                                    Edit
                                </Button>

                                <Button size="md" color="danger" className="button-cancel" onClick={() => showDeleteModal(company)}>
                                    <i className="fa fa-trash-o fa-1x mr-2" />
                                    Delete
                                </Button>
                            </>
                          )}
                      </div>
                    )},
            ];
        }),
    });

    return (
        <>
            {multiCreateEmployee ?
                <MultiCreateEmployeeModal companies={companies} handleSave={handleSaveNewEmployee} setIsShowing={setMultiCreateEmployee} />
            : null}
            {showCreateEmployee ?
                <CreateEmployeeModal companies={companies} handleSave={handleSaveNewEmployee} setIsShowing={setShowCreateEmployee} setShowCreateCompany={setShowCreateCompany} />
            : null}
            {showEditEmployee ?
                <EditEmployeeModal employee={contact} companies={companies} handleSave={handleEditEmployee} setIsShowing={setShowEditEmployee} setShowCreateCompany={setShowCreateCompany} />
            : null}
            {showDeleteEmployee ?
                <DeleteEmployeeModal employee={contact} handleSave={handleDeleteEmployee} setIsShowing={setShowDeleteEmployee} />
            : null}
            {showCreateCompany ?
                <CreateCompanyModal handleSave={handleSaveNewCompany} setIsShowing={setShowCreateCompany} />
            : null}
            {showEditCompany ?
                <EditCompanyModal company={contact} handleSave={handleEditCompany} setIsShowing={setShowEditCompany} />
            : null}
            {showDeleteCompany ?
                <DeleteCompanyModal company={contact} handleSave={handleDeleteCompany} setIsShowing={setShowDeleteCompany} />
            : null}
            <Card>
                <Table
                  sourcePage="contactTables"
                  CustomHeaderTabs={() => (
                      <div style={{ flex: 1, display: "flex" }} className="pr-4">
                          <Nav tabs style={{ border: "none" }} className="mr-auto">
                              <NavItem>
                                  <Button
                                    className="bg-secondary"
                                    onClick={() => {
                                        setActiveTab("1")
                                        setShowEmployees(true)
                                    }}
                                    style={
                                        activeTab === '1'
                                          ? {
                                              padding: "0.5rem 1em",
                                              margin: 0,
                                              color: "#605FF3",
                                              fontWeight: "bold",
                                              borderColor: "rgb(94,114,228)",
                                          }
                                          : { padding: "0.5rem 1em", margin: 0 }
                                    }
                                  >
                                      <i
                                        className="fa fa-users mr-2"
                                        aria-hidden="true"
                                        style={
                                            activeTab === "1"
                                              ? { color: "#5e72e4" }
                                              : null
                                        }
                                      ></i>
                                      Staff
                                  </Button>
                              </NavItem>
                              <NavItem>
                                  <Button
                                    onClick={() => {
                                        setActiveTab("2")
                                        setShowEmployees(false)
                                    }}
                                    style={
                                        activeTab === '2'
                                          ? {
                                              padding: "0.5rem 1em",
                                              margin: "0 0 0 1rem",
                                              color: "#5e72e4",
                                              fontWeight: "bold",
                                              borderColor: "rgb(94,114,228)",
                                          }
                                          : { padding: "0.5rem 1em", margin: "0 0 0 1rem" }
                                    }
                                  >
                                      <i
                                        className="fa fa-qrcode mr-2"
                                        aria-hidden="true"
                                        style={
                                            activeTab === "2"
                                              ? { color: "#5e72e4" }
                                              : null
                                        }
                                      ></i>
                                      Companies
                                  </Button>
                              </NavItem>
                          </Nav>
                          <div style={{display: "flex", justifyContent: "flex-end"}}>
                              <Button color="primary" className="bg-primary shadow" onClick={() => showCreateModal()}>{showEmployees ? "New Contact" : "New Company"}</Button>
                              {showEmployees ? <Button color="primary" className="bg-primary shadow" onClick={() => setMultiCreateEmployee(true)}>Upload New Users</Button> : null}
                          </div>
                      </div>
                    )}
                    content={activeTab === '1' ? getTable1Data().content : getTable2Data().content}
                    columns={activeTab === '1' ? getTable1Data().columns : getTable2Data().columns}
                    Pagination={() => (
                      <nav aria-label="...">
                          <PaginationTable
                            per_page={perPage}
                            current_page={ showEmployees ? currentPageEmployees : currentPageCompanies }
                            total_data={ showEmployees ? employees.length : companies.length }
                            handleClick={handlePaginationClick}
                          />
                      </nav>
                    )}
                />
            </Card>
        </>
    )
}

export default ContactTables
