import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingIndicator from '../VisitorLog/LoaderSpinner'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { clearImages } from "../../actions/mediaActions";

function ViewImageModal({isOpen, toggle, title}) {
  const dispatch = useDispatch();
    const picturesLoading = useSelector(state => state.picturesLoading)
    const images = useSelector(state => state.images)
    const imageId = useSelector(state => state.imageId)

  useEffect(() => {
    return () => dispatch(clearImages());
  }, [])

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {title}
            </ModalHeader>
            <ModalBody>
                {picturesLoading ? (
                    <LoadingIndicator />
                    ) : !images ? (
                        <h5>There are no images to display</h5>
                    ) : (
                        <img className="userImages" src={imageId} alt="User ID" />
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={toggle}>OK</Button>
            </ModalFooter>
        </Modal>
    )
}

export default ViewImageModal
