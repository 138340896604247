import { toast } from "react-toastify";
import axios from "../../axiosconfig";
import {
  GET_LANGUAGES_CONFIGURATION,
  GET_LANGUAGES_CONFIGURATION_SUCCESS,
  GET_LANGUAGES_CONFIGURATION_FAILED,
  UPDATE_LANGUAGES_CONFIGURATION,
  UPDATE_LANGUAGES_CONFIGURATION_SUCCESS,
  UPDATE_LANGUAGES_CONFIGURATION_FAILED,
} from "./types";

export const getLanguages = (zoneName) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_LANGUAGES_CONFIGURATION });
    const token = getState().token;
    const res = await axios.get(`/languages/getLanguages/${zoneName}`, {
      headers: { authorization: `Bearer ${token}` },
    });
    const languagesList = JSON.parse(res?.data?.languages);
    dispatch({
      type: GET_LANGUAGES_CONFIGURATION_SUCCESS,
      payload: languagesList,
    });
    return languagesList;
  } catch (err) {
    dispatch({
      type: GET_LANGUAGES_CONFIGURATION_FAILED,
      payload: err.message,
    });
    toast.error("Fetch languages configuration list error", {
      position: "bottom-center",
      icon: false,
      theme: "dark",
    });
  }
};

export const updateLanguages = ({ zoneName, languages }) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_LANGUAGES_CONFIGURATION });
    const token = getState().token;
    const res = await axios.post("/languages/updateLanguages", { zoneName, languages }, {
      headers: { authorization: `Bearer ${token}` },
    });

    dispatch({
      type: UPDATE_LANGUAGES_CONFIGURATION_SUCCESS,
      payload: res.data,
    });

    toast.success("Languages config was updated", {
      position: "bottom-center",
      className: "ConfigureSteps-toast",
      icon: false,
      theme: "dark",
    });

    return res.data;
  } catch (err) {
    dispatch({
      type: UPDATE_LANGUAGES_CONFIGURATION_FAILED,
      payload: err.message,
    });

    toast.error("Save languages configuration error", {
      position: "bottom-center",
      icon: false,
      theme: "dark",
    });
  }
};
