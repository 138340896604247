import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from '../../axiosconfig'
import SimpleHeader from '../SimpleHeader/SimpleHeader'
import classnames from 'classnames'
import PaginationTable from '../VisitorLog/TablePagination'
import GroupsListObj from './GroupsListObj'
import EditGroupModal from './EditGroupModal'
import { Container, Form, FormGroup, Label, Input, Col, TabContent, TabPane, Button, Row,
    Table, Card, CardHeader, CardBody, CardFooter, Nav, NavItem, NavLink } from 'reactstrap'
import ToggleSwitch from '../sharedElements/ToggleSwitch'

function Brivo() {
    const token = useSelector((state) => state.token)
    const perPage = 10
    const [brivoStatus, setBrivoStatus] = useState('')
    const [brivoLogin, setBrivoLogin] = useState({})
    const [loginMessage, setLoginMessage] = useState('')
    const [brivoUsers, setBrivoUsers] = useState([])
    const [displayedUsers, setDisplayedUsers] = useState([])
    const [brivoGroups, setBrivoGroups] = useState([])
    const [activeTab, setActiveTab] = useState('1')
    const [showUsers, setShowUsers] = useState(true)
    const [currentPageUsers, setCurrentPageUsers] = useState(1)
    const [currentPageGroups, setCurrentPageGroups] = useState(1)
    const [selectAllUsers, setSelectAllUsers] = useState(true)
    const [searchValue, setSearchValue] = useState('')
    const [dropdownOpen, setDropdownOpen] = useState({})
    const [showEditGroup, setShowEditGroup] = useState(false)
    const [groupToEdit, setGroupToEdit] = useState({})

    const handleLogin = (name, value) => {
        setBrivoLogin({
            ...brivoLogin,
            [name]: value
        })
    }

    const submitLogin = async () => {
        console.log(brivoLogin)
        const response = await axios({
            url: "/brivo/getauth",
            method: "POST",
            headers: { authorization: `Bearer ${token}` },
            data: brivoLogin
        })
        console.log(response.data)
        if(response.data !== 'good') {
            setLoginMessage('Bad Credentials. Please try again.')
        }
        getBrivoStatus()
    }

    const getBrivoStatus = async () => {
        const response = await axios({
            url: "/brivo/status",
            method: "GET",
            headers: { authorization: `Bearer ${token}` }
        })
        setBrivoStatus(response.data)
    }

    const getBrivoUsersAndGroups = async () => {
        const response = await axios({
            url: "/brivo/getusers",
            method: "GET",
            headers: { authorization: `Bearer ${token}` }
        })
        console.log('getBrivoUsersAndGroups', response.data)
        setDropdownOpen(response.data.users.map(user => {
            return {
                ...dropdownOpen,
                [user.id]: false
            }
        }))
        const lastIndex = currentPageUsers * perPage
        const firstIndex = lastIndex - perPage
        setDisplayedUsers(response.data.users.slice(firstIndex, lastIndex))
        setBrivoUsers(response.data.users.sort((a, b) => a.lastName > b.lastName ? 1 : -1))
        setBrivoGroups(response.data.groups)
    }

    const getBrivoGroups = async () => {
        const response = await axios({
            url: "/brivo/getgroups",
            method: "GET",
            headers: { authorization: `Bearer ${token}` }
        })
        console.log('getBrivoGroups', response.data)
        setBrivoGroups(response.data.groups)
    }

    const changeUserTempCheck = (user) => {
        setBrivoUsers(brivoUsers.map(bu => {
            if(bu.brivoID === user.brivoID) {
                return {
                    ...bu,
                    tempCheck: !bu.tempCheck
                }
            } else {
                return bu
            }
        }))
    }

    const changeUserGroup = (user, groupID) => {
        console.log('user', user.firstName, 'group', groupID)
        setBrivoUsers(brivoUsers.map(bu => {
            if(bu.brivoID === user.brivoID) {
                return {
                    ...bu,
                    group: groupID
                }
            } else {
                return bu
            }
        }))
    }

    const toggleSelectUsers = () => {
        setBrivoUsers(brivoUsers.map(bu => {
            return {
                ...bu,
                tempCheck: selectAllUsers
            }
        }))
        setSelectAllUsers(!selectAllUsers)
    }

    const toggleUserStatus = async (user, status) => {
        setBrivoUsers(brivoUsers.map(bu => {
            if(bu.brivoID === user.brivoID) {
                return {
                    ...bu,
                    suspended: !bu.suspended
                }
            } else {
                return bu
            }
        }))
        await axios({
            url: "/brivo/setsuspend",
            method: "POST",
            headers: { authorization: `Bearer ${token}` },
            data: {userID: user.id, suspended: !user.suspended}
        })
        getBrivoUsersAndGroups()
    }

    const handleSaveUsers = async () => {
        await axios({
            url: "/brivo/saveusers",
            method: "POST",
            headers: { authorization: `Bearer ${token}` },
            data: {users: brivoUsers}
        })
        getBrivoUsersAndGroups()
    }

    const handlePaginationClick = (number) => {
        if (showUsers) {
            setCurrentPageUsers(number)
            const lastIndex = number * perPage
            const firstIndex = lastIndex - perPage
            setDisplayedUsers(brivoUsers.slice(firstIndex, lastIndex))
        } else {
            setCurrentPageGroups(number)
            // Format Data
        }
    }

    const showEditGroupModal = (group) => {
        console.log('editGroup', group)
        setGroupToEdit(group)
        setShowEditGroup(true)
    }

    const handleSaveGroup = async (group, resetTime) => {
        console.log('handleSaveGroup', group, resetTime)
        setShowEditGroup(false)
        await axios({
            url: "/brivo/savegroup",
            method: "POST",
            headers: { authorization: `Bearer ${token}` },
            data: {id: group._id, resetTime: resetTime}
        })
        getBrivoGroups()
    }

    useEffect(() => {
        getBrivoStatus()
    }, [])

    useEffect(() => {
        if(brivoStatus === 'good') {
            getBrivoUsersAndGroups()
        }
    }, [brivoStatus])

    useEffect(() => {
        const lastIndex = currentPageUsers * perPage
        const firstIndex = lastIndex - perPage
        setDisplayedUsers(brivoUsers.filter(user => user.firstName.toLowerCase().includes(searchValue.toLowerCase()) || user.lastName.toLowerCase().includes(searchValue.toLowerCase())).slice(firstIndex, lastIndex))
    }, [brivoUsers, currentPageUsers, searchValue])

    return(
        <>
            {showEditGroup ?
                  <EditGroupModal group={groupToEdit} handleSaveGroup={handleSaveGroup} setIsShowing={setShowEditGroup} />
              : null}
            {brivoStatus === "login" ?
                <div>
                    <Card>
                        <CardBody>
                            <Form>
                                <FormGroup row>
                                    <Label for="username" sm={2}>Administrator ID</Label>
                                    <Col sm={5}>
                                        <Input type="text" name="username" id="brivoUsername" onChange={(e) => handleLogin(e.target.name, e.target.value)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="password" sm={2}>Password</Label>
                                    <Col sm={5}>
                                        <Input type="password" name="password" id="brivoPassword" onChange={(e) => handleLogin(e.target.name, e.target.value)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="clientId" sm={2}>Application Client ID</Label>
                                    <Col sm={5}>
                                        <Input type="text" name="clientId" id="brivoClientId" onChange={(e) => handleLogin(e.target.name, e.target.value)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label for="clientSecret" sm={2}>Application Client Secret</Label>
                                    <Col sm={5}>
                                        <Input type="text" name="clientSecret" id="brivoClientSecret" onChange={(e) => handleLogin(e.target.name, e.target.value)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup check row>
                                    <Col sm={{ size: 10, offset: 2 }}>
                                        <p style={{color: 'red'}}>{loginMessage}</p>
                                        <Button onClick={() => submitLogin()}>Submit</Button>
                                    </Col>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            :
                <div>
                    <Card>
                        <CardHeader className="d-flex justify-content-between" style={{ border: "none", paddingBottom: "0" }}>
                            <Row style={{display: "flex", width: "104%", justifyContent: "space-between"}}>
                                <Col className="d-flex align-items-center">
                                    <h5 className="h3 mb-0"></h5>
                                </Col>
                                <Col>
                                    <Row className="d-flex justify-content-between">
                                        <div style={{ display: "flex", alignItems: "center" }}></div>
                                        <Input name="searchUsers" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder="Search here" className="search-input" style={{ marginRight: "1.5em" }}/>
                                    </Row>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="d-flex " style={{ flexDirection: "column", padding: "1em 1em 0 0" }}>
                            <div style={{ width: "100%", display: "flex" }}>
                                <Col xs={9}>
                                    <Nav tabs className="px-2" style={{ border: "none" }}>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({active: activeTab === "1"})}
                                                onClick={() => {
                                                    setActiveTab("1")
                                                    setShowUsers(true)
                                                }}
                                                style={
                                                activeTab === "1"
                                                    ? {
                                                        padding: ".75em",
                                                        color: "#5e72e4",
                                                        fontWeight: "bold",
                                                        borderColor: "rgb(94,114,228)",
                                                    }
                                                    : { padding: ".75em" }
                                                }
                                            >
                                                <i
                                                className="fa fa-users mr-2"
                                                aria-hidden="true"
                                                style={
                                                    activeTab === "1"
                                                    ? { color: "#5e72e4" }
                                                    : null
                                                }
                                                ></i>
                                                Users
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({active: activeTab === "2"})}
                                                onClick={() => {
                                                    setActiveTab("2")
                                                    setShowUsers(false)
                                                }}
                                                style={
                                                activeTab === "2"
                                                    ? {
                                                        padding: ".75em",
                                                        color: "#5e72e4",
                                                        fontWeight: "bold",
                                                        borderColor: "rgb(94,114,228)",
                                                    }
                                                    : { padding: ".75em" }
                                                }
                                            >
                                                <i
                                                className="fa fa-qrcode mr-2"
                                                aria-hidden="true"
                                                style={
                                                    activeTab === "2"
                                                    ? { color: "#5e72e4" }
                                                    : null
                                                }
                                                ></i>
                                                Groups
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Col>
                                <Col xs={3}>
                                    {activeTab == "1" ?
                                    <Button color="primary" className="bg-primary shadow" onClick={() => handleSaveUsers()}>Enable Temp Check</Button>
                                    : null}
                                </Col>
                            </div>
                            <Col>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <Row>
                                            <Col sm="12">
                                                <div className="table-responsive px-2">
                                                    <Table className="align-items-center table-flush bg-white">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th style={{width: '200px'}}>Select Users<p onClick={() => toggleSelectUsers()} style={{margin: '0px', fontSize: '0.7rem', textDecoration: 'underline', color: 'blue', cursor: 'default'}}>{selectAllUsers ? 'Select All' : 'Deselect All'}</p></th>
                                                                <th>First Name</th>
                                                                <th>Last Name</th>
                                                                <th>Group</th>
                                                                <th>Cleared</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="list">
                                                            {displayedUsers.map((user, index) => {
                                                                return (
                                                                    <tr key={index} bgcolor={index % 2 ? '#F4F7FA' : 'white'}>
                                                                        <td><input type="checkbox" checked={user.tempCheck} onClick={() => changeUserTempCheck(user)} /></td>
                                                                        <td className="name">{user.firstName}</td>
                                                                        <td className="name">{user.lastName}</td>
                                                                        <td className="name">
                                                                            <FormGroup>
                                                                                <Input type="select" name="group" id="groupSelect" value={user.group || user.groups[0].id} onChange={(e) => changeUserGroup(user, parseInt(e.target.value))}>
                                                                                    {user.groups.map((group, index) => <option key={index} value={group.id}>{group.name}</option>)}
                                                                                </Input>
                                                                            </FormGroup>
                                                                        </td>
                                                                        <td><ToggleSwitch id={`${user.id}`} checked={!user.suspended} value={!user.suspended} label="" cb={toggleUserStatus} cbReturn={user} /></td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Row>
                                            <Col sm="12">
                                                <div className="table-responsive px-2">
                                                    <Table className="align-items-center table-flush bg-white">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th>Group Name</th>
                                                                <th>Reset Time</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="list">
                                                            {brivoGroups.map((group, index) => {
                                                                return (
                                                                    <tr key={index} bgcolor={index % 2 ? '#F4F7FA' : 'white'}>
                                                                        <GroupsListObj group={group} showEditGroupModal={showEditGroupModal} />
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </CardBody>
                        <CardFooter className="mr-auto py-1">
                            <nav aria-label="...">
                                <PaginationTable
                                    per_page={perPage}
                                    current_page={ showUsers ? currentPageUsers : currentPageGroups }
                                    total_data={ showUsers ? brivoUsers.length : brivoGroups.length }
                                    handleClick={handlePaginationClick}
                                />
                            </nav>
                        </CardFooter>
                    </Card>
                </div>
            }
        </>
    )
}

export default Brivo
