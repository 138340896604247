import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Card, Button } from 'reactstrap'
import { Table } from '../Table/Table'
import PaginationTable from '../VisitorLog/TablePagination'
import EditZoneModal from './EditZoneModal'
import ViewImageModal from './ViewImageModal'
import { viewImages, getImagesFromS3, sendImageToS3 } from '../../actions/mediaActions'
import { editZones, findZones } from '../../actions/userActions'
import { sortArrByColumn } from "../../utils";

const Branding = ({zones}) => {
    const perPage = 10
    const token = useSelector(state => state.token)
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1)
    const [display, setDisplay] = useState([])
    const [showEditModal, setShowEditModal] = useState(false)
    const [showPictureModal, setShowPictureModal] = useState(false)
    const [editZone, setEditZone] = useState({})
    const [viewZoneTitle, setViewZoneTitle] = useState('')
    const [hovered, setHovered] = useState(false)
    const [sort, setSort] = useState({
        col: null,
        desc: false,
    });

    const onChangeSort = (e) => {
        const { newSort, newArr } = sortArrByColumn(e, sort, display);
        setSort(newSort);
        setDisplay(newArr);
    };

    const handlePaginationClick = (number) => {
        setCurrentPage(number)
        const lastIndex = number * perPage
        const firstIndex = lastIndex - perPage
        setDisplay(zones.slice(firstIndex, lastIndex))
    }

    const handleEditZoneModal = (zone) => {
        setEditZone(zone)
        setShowEditModal(true)
    }

    const handleViewImageModal = (title, zone) => {
        setViewZoneTitle(title)
        dispatch(viewImages())
        dispatch(getImagesFromS3(undefined, zone.split(':')[1], zone.split(':')[0]))
        setShowPictureModal(true)
    }

    const formatIdString = (image, typeId, imageId) => {
        if(image) {
            return `${typeId}:${imageId}`
        } else {
            return ''
        }
    }

    const handleSaveEditZone = async (e, updatedZone, logo, splash, optionSelected) => {
        e.preventDefault()
        setShowEditModal(false)
        const logoId = Math.floor(Math.random() * 1000000001).toString()
        const splashId = Math.floor(Math.random() * 1000000001).toString()
        const imageId = Math.floor(Math.random() * 1000000001).toString()
        const logoString = logo ? formatIdString(logo, logoId, imageId) : updatedZone.logo
        const splashString = splash ? formatIdString(splash, splashId, imageId) : updatedZone.splashScreen
        if(logo) dispatch(sendImageToS3(logo, logoId, imageId))
        if(splash) dispatch(sendImageToS3(splash, splashId, imageId))
        const selectedZone = [{...updatedZone, logo: logoString, splashScreen: splashString}]
        const otherZones = optionSelected.map(zone => {
            return {
                _id: zone.value,
                buildingName: updatedZone.buildingName || '',
                logo: logoString,
                splashScreen: splashString
            }
        })
        const data = selectedZone.concat(otherZones)
        const DBresp = await dispatch(editZones(data, token)).then((resp) => resp)
        dispatch(findZones())
    }

    useEffect(() => {
        handlePaginationClick(1)
    }, [zones])

    const getTableData = () => ({
        columns: [
            { key: 'index', label: '# Number', alignCenter: true, small: true },
            { key: 'zone', label: 'Zone Name', sortable: true },
            { key: 'building', label: 'Building Name', sortable: true },
            { key: 'logo', label: 'Logo', alignCenter: true, noPadding: true },
            { key: 'splash', label: 'Splash Screen', alignCenter: true, noPadding: true },
            { key: 'options', label: 'Actions', alignCenter: true, noPadding: true },
        ],
        content: display.map((zone, index) => {
            return [
                { key: 'index', value: index + 1 },
                { key: 'zone', value: zone.zoneName },
                { key: 'building', value: zone.buildingName || '-' },
                { key: 'logo', value: (
                  zone.logo ? (
                    <Button color="light" size="sm" type="button" onClick={() => handleViewImageModal(`${zone.zoneName} Logo Image`, zone.logo)}>
                        <i className="fa fa-id-badge fa-2x" />
                    </Button>
                  ) : '-'
                )},
                { key: 'splash', value: (
                  zone.splashScreen ? (
                    <Button color="light" size="sm" type="button" onClick={() => handleViewImageModal(`${zone.zoneName} Splash Screen Image`, zone.splashScreen)}>
                        <i className="fa fa-id-badge fa-2x"></i>
                    </Button>
                  ) : '-'
                )},
                { key: 'options', value: (
                    <Button
                    size="md"
                    color="light"
                    className="button-default"
                    onClick={() => handleEditZoneModal(zone)}
                    >
                    <i className="fa fa-pencil-square-o fa-1x mr-2" />
                    Edit
                    </Button>
                ),},
            ];
        }),
    });

    return (
        <>
            {showEditModal ?
                <EditZoneModal zone={editZone} zones={zones} setIsShowing={setShowEditModal} submitEdit={handleSaveEditZone} />
            : null}
            {showPictureModal ?
                <ViewImageModal isOpen={showPictureModal} toggle={() => setShowPictureModal(!showPictureModal)} title={viewZoneTitle} />
            : null}
            <div className="ConfigList">
                <Row>
                    <div className="col p-0">
                        <Card>
                            <Table
                              sourcePage="branding"
                              content={getTableData().content}
                              columns={getTableData().columns}
                              sort={sort}
                              onChangeSort={onChangeSort}
                              Pagination={() => (
                                <nav aria-label="...">
                                  <PaginationTable
                                    per_page={perPage}
                                    current_page={currentPage}
                                    total_data={zones.length}
                                    handleClick={handlePaginationClick}
                                  />
                                </nav>
                              )}
                            />
                        </Card>
                    </div>
                </Row>
            </div>
        </>
    )
}

export default Branding
