import React from "react";
import classnames from "classnames";
import { PropTypes } from "prop-types";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Media,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { onLogout } from "../../actions/userActions";
import logo from "../../assets/engrafis-logo-2020@512.png";
import { ReactComponent as ArrowLeftIcon } from "../../assets/sidemenu/arrow-left.svg";
import { ReactComponent as DashIcon } from "../../assets/sidemenu/dash.svg";
import { ReactComponent as VisitorOnboardingIcon } from "../../assets/sidemenu/visitor_onboarding.svg";
import { ReactComponent as VisitorLogIcon } from "../../assets/sidemenu/visitor_log.svg";
import { ReactComponent as UserManageIcon } from "../../assets/sidemenu/user_manage.svg";
import { ReactComponent as DeviceManageIcon } from "../../assets/sidemenu/device_manage.svg";
import { ReactComponent as ZoneManageIcon } from "../../assets/sidemenu/zone_manage.svg";
import { ReactComponent as IntegrationsIcon } from "../../assets/sidemenu/integrations.svg";
import { ReactComponent as LogoutIcon } from "../../assets/sidemenu/logout.svg";
import { ReactComponent as ConfigurationIcon } from "../../assets/sidemenu/configure.svg";
import "./navbar.css";
import PermissionWrapper from "../permissions/PermissionWrapper";
import { SCOPES } from "../permissions/permission-map";

class Sidenav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
    };
  }

  onMouseEnterSidenav = () => {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
    this.props.openSidenav();
  };

  onMouseLeaveSidenav = () => {
    if (this.state.collapseOpen) return;
    if (document.body.classList.contains("g-sidenav-show")) {
      document.body.classList.remove("g-sidenav-show");
      document.body.classList.add("g-sidenav-hidden");
    }
    this.props.closeSidenav();
  };

  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };

  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };

  closeSidenav = () => {
    if (window.innerWidth < 1200) {
      this.props.toggleSidenav();
    }
  };

  logout = () => {
    this.props.onLogout();
  };

  render() {
    const { location } = this.props;
    const scrollBarInner = (
      <div className="scrollbar-inner d-flex flex-column">
        <div className="sidenav-header d-flex align-items-center">
          <NavbarBrand className="SideNav-brand">
            <Media src={logo} className="" />
          </NavbarBrand>
          <div className="ml-auto">
            <div
              className="sidenav-toggler d-none d-md-block"
              onClick={this.toggleCollapse}
            >
              <div
                className={classnames("sidenav-toggler-inner", {
                  active: !this.state.collapseOpen,
                })}
              >
                <ArrowLeftIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner mb-auto">
          <Collapse navbar isOpen={true}>
            <Nav navbar>
              <hr />
              <PermissionWrapper scopes={[SCOPES.dashboard]}>
                <NavItem>
                  <NavLink
                    href="/dashboard"
                    active={
                      location?.pathname?.includes("/dashboard") ||
                      location?.pathname === "/"
                    }
                  >
                    <i className="menuitem">
                      <DashIcon />
                    </i>
                    <span className="nav-link-text">Dashboard</span>
                  </NavLink>
                </NavItem>
              </PermissionWrapper>
              <PermissionWrapper scopes={[SCOPES.manualOnboarding]}>
                <NavItem>
                  <NavLink
                    href="/manual-onboarding"
                    active={location?.pathname?.includes("/manual-onboarding")}
                  >
                    <i className="menuitem">
                      <VisitorOnboardingIcon />
                    </i>
                    <span className="nav-link-text">Manual onboarding</span>
                  </NavLink>
                </NavItem>
              </PermissionWrapper>
              <PermissionWrapper scopes={[SCOPES.visitorsLog]}>
                <NavItem>
                  <NavLink
                    href="/visitor-log"
                    active={location?.pathname?.includes("/visitor-log")}
                  >
                    <i className="menuitem">
                      <VisitorLogIcon />
                    </i>
                    <span className="nav-link-text">Visitors Log</span>
                  </NavLink>
                </NavItem>
              </PermissionWrapper>
              <hr />
              <PermissionWrapper scopes={[SCOPES.userManagement]}>
                <NavItem>
                  <NavLink
                    href="/createAdmin"
                    active={location?.pathname?.includes("/createAdmin")}
                  >
                    <i className="menuitem">
                      <UserManageIcon />
                    </i>
                    <span className="nav-link-text">User Management</span>
                  </NavLink>
                </NavItem>
              </PermissionWrapper>
              <PermissionWrapper scopes={[SCOPES.deviceManagment]}>
                <NavItem>
                  <NavLink
                    href="/addDevice"
                    active={location?.pathname?.includes("/addDevice")}
                  >
                    <i className="menuitem">
                      <DeviceManageIcon />
                    </i>
                    <span className="nav-link-text">Device Management</span>
                  </NavLink>
                </NavItem>
              </PermissionWrapper>
              <PermissionWrapper scopes={[SCOPES.zoneManagement]}>
                <NavItem>
                  <NavLink
                    href="/addZone"
                    active={location?.pathname?.includes("/addZone")}
                  >
                    <i className="menuitem">
                      <ZoneManageIcon />
                    </i>
                    <span className="nav-link-text">Zone Management</span>
                  </NavLink>
                </NavItem>
              </PermissionWrapper>
              <PermissionWrapper scopes={[SCOPES.integrations]}>
                <NavItem>
                  <NavLink
                    href="/brivo"
                    active={
                      location?.pathname?.includes("/brivo") ||
                      location?.pathname?.includes("/blinkId") ||
                      location?.pathname?.includes("/contacts")
                    }
                  >
                    <i className="menuitem">
                      <IntegrationsIcon />
                    </i>
                    <span className="nav-link-text">Integrations</span>
                  </NavLink>
                </NavItem>
              </PermissionWrapper>
              <PermissionWrapper scopes={[SCOPES.configuration]}>
                <NavItem>
                  <NavLink
                    href="/configureSteps"
                    active={location?.pathname?.includes("/configureSteps")}
                  >
                    <i className="menuitem">
                      <ConfigurationIcon />
                    </i>
                    <span className="nav-link-text">Configuration</span>
                  </NavLink>
                </NavItem>
              </PermissionWrapper>
              <hr />
              <NavItem>
                <NavLink href="/" onClick={this.logout}>
                  <i className="menuitem">
                    <LogoutIcon />
                  </i>
                  <span className="nav-link-text">Logout</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </div>
        <div className="mx-auto text-center SideNav-build">
          <p className="nav-link-text mb-0 text-xs">v. 0.9.55 (2022-09-19)</p>
          <p className="nav-link-text mb-0 text-xs">2022 © Timbergrove</p>
        </div>
      </div>
    );
    return (
      <>
        <Navbar
          className={
            "sidenav navbar-vertical navbar-expand-xs navbar-light bg-white " +
            (this.props.rtlActive ? "" : "fixed-left")
          }
          onMouseEnter={this.onMouseEnterSidenav}
          onMouseLeave={this.onMouseLeaveSidenav}
        >
          {navigator.platform.indexOf("Win") > -1 ? (
            <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
          ) : (
            scrollBarInner
          )}
        </Navbar>
      </>
    );
  }
}

Sidenav.defaultProps = {
  toggleSidenav: () => {
    console.log("** props toggle called **");
  },
  sidenavOpen: false,
  rtlActive: false,
};

Sidenav.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  rtlActive: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    admin: state.admin,
  };
};

export default connect(mapStateToProps, { onLogout })(withRouter(Sidenav));
