/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";

class TimelineHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-gray-700 pt-6 content__title">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4">
                <Col lg="6" xs="7">
                  <h6
                    className="fullcalendar-title h2 text-white d-inline-block mb-0"
                    style={{ textShadow: "2px 2px 8px #000000" }}
                  >
                    {this.props.name}
                  </h6>{" "}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
