import * as actions from "./types";
import axios from "../axiosconfig";

export const analyzeAudio = (formData) => async (dispatch) => {
  try {
    const response = await axios({
      url: "/detectAudio",
      method: "POST",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    dispatch({ type: actions.SPEECH_INPUT, payload: response.data });
    dispatch({ type: actions.CURRENT_SPEECH, payload: response.data });
  } catch (e) {
    console.log("error");
  }
};

export const updateImageArray = (array) => {
  return {
    type: actions.UPDATE_IMAGE_ARRAY,
    payload: array,
  };
};

export const updateSpeech = (speech) => {
  return {
    type: actions.CURRENT_SPEECH,
    payload: speech,
  };
};

export const recordAudio = (input) => {
  return {
    type: actions.RECORD_AUDIO,
    payload: input,
  };
};

export const getTwilioTokenClient = () => async (dispatch) => {
  try {
    const response = await axios({
      url: "/twilioToken",
      method: "GET",
    });

    console.log(response);
    const room = Math.floor(Math.random() * 1000000001).toString();

    dispatch({ type: actions.GET_TWILIO_TOKEN, payload: response.data });
    dispatch({ type: actions.CREATE_ROOM, payload: room });
  } catch (e) {
    console.log("twilio error");
  }
};

export const getTwilioTokenSecurity = () => async (dispatch) => {
  try {
    const response = await axios({
      url: "/twilioToken",
      method: "GET",
    });

    dispatch({ type: actions.GET_TWILIO_TOKEN, payload: response.data });
  } catch (e) {
    console.log("twilio error");
  }
};

export const createRoom = (room) => {
  return {
    type: actions.CREATE_ROOM,
    payload: room,
  };
};

export const sendRoomInfo = (data) => async (dispatch) => {
  try {
    const response = await axios({
      url: "/roomInfo",
      method: "POST",
      data: data,
    });

    console.log(response);
  } catch (e) {
    console.log("room info error");
  }
};

export const destroyRoom = (room) => async (dispatch) => {
  console.log(room);
  try {
    const response = await axios({
      url: "/destroyRoom",
      method: "POST",
      data: { room },
    });

    console.log(response);
    dispatch({ type: actions.CREATE_ROOM, payload: "" });
  } catch (e) {
    console.log("room destroy error");
  }
};

export const searchForCall = () => async (dispatch) => {
  try {
    const response = await axios({
      url: "/activeCall",
      method: "GET",
    });
    if (response && response.length > 0) {
      dispatch({ type: actions.INCOMING_CALL, payload: true });
    }
  } catch (e) {
    console.log("search for call error");
  }
};

export const incomingCall = (status) => {
  return {
    type: actions.INCOMING_CALL,
    payload: status,
  };
};

export const answerIncomingCall = (isAnswered) => async (dispatch) => {
  console.log('inside answerIncomingCall', isAnswered)
  dispatch({ type: actions.ANSWER_INCOMING_CALL, payload: false });
  if(isAnswered) {
    try {
      await axios({
        url: "/videocallanswered",
        method: "GET",
      });
    } catch (e) {
      console.log("answerIncomingCall error");
    }
  }
};

export const sendImageToS3 = (image, sessionId, imageId) => async (
  dispatch
) => {
  try {
    const response = await axios({
      url: "/s3Upload",
      method: "POST",
      data: {
        image: image,
        sessionId: sessionId,
        imageId: imageId,
      },
    });

    console.log('sendImageToS3', response.data);

    dispatch({ type: actions.IMAGE_TAKEN, payload: true });
  } catch (e) {
    console.log("S3 error", e);
  }
};

export const getImagesFromS3 = (image, imageId, sessionId) => async (
  dispatch
) => {
  let payload = { images: false };
  try {
    const response = await axios({
      url: "/s3Download",
      method: "POST",
      data: {
        imageId: image,
        sessionId: sessionId,
      },
    });
    payload = {
      ...payload,
      image: response.data,
      images: response.data.length > 0 ? true : false,
    };
  } catch (e) {
    console.log("S3 error");
  }
  try {
    const response = await axios({
      url: "/s3Download",
      method: "POST",
      data: {
        imageId: imageId,
        sessionId: sessionId,
      },
    });
    payload = {
      ...payload,
      imageId: response.data,
      images: payload.images || response.data.length > 0 ? true : false,
    };
  } catch (e) {
    console.log("S3 error");
  }

  dispatch({ type: actions.IMAGE_LOADED, payload: payload });
};

export const viewImages = () => (dispatch) => {
  dispatch({ type: actions.IMAGES_LOADING });
};

export const clearImages = () => (dispatch) => {
  dispatch({ type: actions.CLEAR_IMAGES });
};
