import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
} from "reactstrap";
import "./styles.scss";
import { InputFilled } from "./InputFilled";
import { ReactComponent as VisitorLogIcon } from "../../assets/sidemenu/visitor_log.svg";
import axios from "../../axiosconfig";
import { Selector } from "./Selector";
import { findZones } from "../../actions/userActions";
import { getSteps } from "../../actions/stepsConfiguration";
import { sendImageToS3 } from "../../actions/mediaActions";
import PermissionWrapper from "../permissions/PermissionWrapper";
import { SCOPES } from "../permissions/permission-map";

export const ManualOnboarding = () => {
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [documentNumber, setDocumentNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [state, setState] = useState("");
  const [temp, setTemp] = useState("");
  const [scanType, setScanType] = useState("license");
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState();
  const [imageId, setImageId] = useState();
  const [sessionId] = useState(Math.floor(Math.random() * 1000000001));

  const history = useHistory();
  const dispatch = useDispatch();
  const { zoneName: userZoneName } = useSelector((state) => state.admin);
  const zoneInSearch = new URLSearchParams(history.location.search)?.get(
    "zone"
  );
  const [currentZone, setCurrentZone] = useState(zoneInSearch || userZoneName);
  const [zonesLoading, setZonesLoading] = useState(false);
  const [zoneDropdownOpen, setZoneDropdownOpen] = useState(false);
  const toggleZoneDropdown = () => setZoneDropdownOpen((prevVal) => !prevVal);
  const zones = useSelector((state) => state.zones);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    history.replace({ search: `?zone=${currentZone}` });
  }, [currentZone]);

  const loadZonesData = async () => {
    setZonesLoading(true);
    await dispatch(findZones());
    setZonesLoading(false);
  };

  const loadStepsData = async () => {
    const steps = await dispatch(getSteps(currentZone));
    if (steps) setSteps(steps);
  };

  useEffect(() => {
    loadZonesData();
  }, []);

  useEffect(() => {
    loadStepsData();
  }, [currentZone]);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const onSelectImage = (e) => {
    const file = e.target.files?.[0];
    getBase64(file).then((result) => {
      setSelectedPhoto(result);
      try {
        const imageId = Math.floor(Math.random() * 1000000001);
        dispatch(sendImageToS3(result, sessionId, imageId));
        setImageId(imageId);
      } catch (e) {}
    });
  };

  const questions = steps.filter((step) => step.type === "question");
  const tempCheckStep = steps.find((step) => step.type === "tempCheck");

  console.log(questions);

  const onSubmit = async () => {
    const data = {
      firstName: firstName,
      lastName: lastName,
      DOB: dob,
      idType: scanType,
      number: documentNumber,
      email,
      imageId,
      sessionId,
      timestamp: new Date(),
    };

    if (
      selectedAnswers.some(
        (answer, index) =>
          questions[index]?.values?.selectType === "single" &&
          questions[index]?.values?.interruptOn === answer
      )
    ) {
      data.flag = "q";
    }

    if (tempCheckStep?.values) {
      const tempValue = Number(temp);
      const tempMinValue = Number(tempCheckStep.values.min);
      const tempMaxValue = Number(tempCheckStep.values.max);
      if (tempMinValue > tempValue || tempMaxValue < tempValue) {
        data.flag = "t";
      }
    }

    await axios({
      url: "/createUser",
      method: "POST",
      data,
    });
    window.location.reload();
  };

  const onSelectAnswer = (index, answer) => {
    const newAnswers = [...selectedAnswers];
    newAnswers[index] = answer;
    setSelectedAnswers(newAnswers);
  };

  return (
    <PermissionWrapper scopes={[SCOPES.manualOnboarding]}>
      <div className="ManualOnboarding">
        <div className="ManualOnboarding-header">
          <h1>Manual onboarding</h1>
          <div className="d-flex ml-4">
            <Dropdown isOpen={zoneDropdownOpen} toggle={toggleZoneDropdown}>
              <DropdownToggle caret>{currentZone}</DropdownToggle>
              <DropdownMenu>
                {zonesLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <Spinner
                      color="primary"
                      style={{ width: "3rem", height: "3rem" }}
                    />
                  </div>
                ) : (
                  zones.map((zone) => (
                    <DropdownItem
                      key={zone._id}
                      onClick={() => setCurrentZone(zone.zoneName)}
                    >
                      {zone.zoneName}
                    </DropdownItem>
                  ))
                )}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <div className="ManualOnboarding-form">
          <div className="flexRow">
            <div className="ManualOnboarding-form-avatar">
              {selectedPhoto ? (
                <img src={selectedPhoto} alt="" />
              ) : (
                <Button color="secondary" style={{ height: "43px" }}>
                  <i className="menuitem" style={{ marginRight: "6px" }}>
                    <VisitorLogIcon />
                  </i>
                  Add picture
                </Button>
              )}
              <input
                type="file"
                onChange={onSelectImage}
                accept="image/png, image/gif, image/jpeg"
              />
            </div>
            <div className="ManualOnboarding-form-inputs">
              <div className="flexRow">
                <InputFilled
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <InputFilled
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <InputFilled
                label="Date of birth"
                className="ManualOnboarding-form-inputs-fullWidth"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
              />
              <div className="ManualOnboarding-form-inputs-divider" />
              <Selector value={scanType} onChange={(val) => setScanType(val)} />
              <div className="flexRow">
                <InputFilled
                  label="ID number"
                  value={documentNumber}
                  onChange={(e) => setDocumentNumber(e.target.value)}
                />
                <InputFilled
                  label="State"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        {questions.length ? (
          <div className="ManualOnboarding-questionaire">
            <h1>Questionaire</h1>
            {questions.map((question, index) => (
              <div className="ManualOnboarding-questionaire-item">
                <p>{question.values?.question}</p>
                <div className="ManualOnboarding-questionaire-item-buttons">
                  {question.values?.selectType === "multi" ? (
                    <>
                      {question.values?.answers.map((answer) => (
                        <button
                          className={
                            selectedAnswers[index] === answer ? "active" : ""
                          }
                          onClick={() => onSelectAnswer(index, answer)}
                        >
                          {answer}
                        </button>
                      ))}
                    </>
                  ) : (
                    <>
                      <button
                        className={
                          selectedAnswers[index] === "yes" ? "active" : ""
                        }
                        onClick={() => onSelectAnswer(index, "yes")}
                      >
                        Yes
                      </button>
                      <button
                        className={
                          selectedAnswers[index] === "no" ? "active" : ""
                        }
                        onClick={() => onSelectAnswer(index, "no")}
                      >
                        No
                      </button>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : null}
        {tempCheckStep ? (
          <div className="ManualOnboarding-tempCheck">
            <h1>Temperature check</h1>
            <InputFilled
              label="Enter manual reading"
              value={temp}
              onChange={(e) => setTemp(e.target.value)}
              placeholder="0.00"
              type="number"
            />
          </div>
        ) : null}
        <div className="ManualOnboarding-print">
          <button onClick={onSubmit}>Submit entry</button>
        </div>
      </div>
    </PermissionWrapper>
  );
};
