import React, { useState } from 'react'
import '../addDevice/addDevice.scss'
import { formatPhoneNumber } from '../../utils'
import SelectSearch from 'react-select-search'
import { Button, Card, CardHeader, CardBody, FormGroup, Input, Row, Col, Label, Form } from 'reactstrap'
import { InputFilled } from "../ManualOnboarding/InputFilled";

function CreateEmployeeModal(props) {
    const [newEmployee, setNewEmployee] = useState({expirationDays: 60})

    const handleEmployeeInfo = (e) => {
        if(e.target.name == 'phone') {
            setNewEmployee({
                ...newEmployee,
                [e.target.name]: formatPhoneNumber(e.target.value)
            })
        } else {
            setNewEmployee({
                ...newEmployee,
                [e.target.name]: e.target.value
            })
        }
    }

    const handleAddNewCompany = () => {
        props.setShowCreateCompany(true)
    }

    const filterContact = (contacts) => {
        return (searchValue) => {
            return contacts.filter(c => c.name.toLowerCase().includes(searchValue.toLowerCase()))
        }
    }

    return (
        <div className="card-wrapper whiteModalOverlay ContactManagementModal">
            <Card className="bg-white" style={{width: '560px', height: '638px', overflow: 'auto', paddingBottom: '16px'}}>
            <CardHeader className="d-flex justify-content-between align-items-center bg-white border-bottom-0 pb-0">
                    <h3 className="mb-0 font-weight-400">Create staff contact</h3>
                    <div style={{ cursor: 'pointer' }} onClick={() => props.setIsShowing(false)}>x</div>
                </CardHeader>
                <CardBody className="bg-white">
                    <Form className="outline-dark" onSubmit={(e) => props.handleSave(e, newEmployee)}>
                        <Row>
                            <Col md="12">
                                <div className="row">
                                    <FormGroup className="col">
                                        <InputFilled className="InputFilledFullWidth" label="First name*" placeholder="First name" name="firstName" value={newEmployee.firstName} onChange={handleEmployeeInfo} />
                                    </FormGroup>
                                    <FormGroup className="col">
                                        <InputFilled className="InputFilledFullWidth" label="Last name*" placeholder="Last name" name="lastName" value={newEmployee.lastName} onChange={handleEmployeeInfo} />
                                    </FormGroup>
                                </div>
                                <div className="row">
                                    <FormGroup className="col">
                                        <Label className="font-weight-400">Company*</Label>
                                        <div className="companyRow">
                                            <SelectSearch className="searchselect-light searchselect-grey companySelect" placeholder="Select company" printOptions="on-focus" value={newEmployee.company} filterOptions={filterContact} onChange={(e) => handleEmployeeInfo({target: {name: 'company', value: e}})} search
                                                          options={props.companies.map(company => {
                                                              return {name: company.company, value: company._id}
                                                          })}
                                            />
                                            <p style={{cursor: "pointer", color: "blue", background: '#F4F7FA'}} onClick={handleAddNewCompany}>+ Add new</p>
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className="row">
                                    <FormGroup className="col">
                                        <InputFilled className="InputFilledFullWidth" label="Phone number*" placeholder="Phone number" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" required value={newEmployee.phone} name="phone" onChange={handleEmployeeInfo} />
                                    </FormGroup>
                                </div>
                                <div className="row">
                                    <FormGroup className="col">
                                        <InputFilled className="InputFilledFullWidth" label="Email*" placeholder="Email" value={newEmployee.email} required name="email" onChange={handleEmployeeInfo} />
                                    </FormGroup>
                                </div>
                                <div className="row">
                                    <FormGroup className="col">
                                        <InputFilled className="fieldRight" label="Days valid*" value={newEmployee.expirationDays} required type="number" name="expirationDays" min="1" max="365" onChange={handleEmployeeInfo} />
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-end my-3 mr-0 buttons pb-4">
                            <Button color="secondary" size="md" onClick={() => props.setIsShowing(false)}>Cancel</Button>
                            <Button color="primary" size="md" type="submit">Save</Button>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}

export default CreateEmployeeModal
