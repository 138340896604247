import React, { useState } from 'react'
import '../addDevice/addDevice.scss'
import { Button } from 'reactstrap'

function CompanyListObj(props) {
  const [hovered, setHovered] = useState(false)

  return (
    <>
      <td onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        {props.company.company}
      </td>
      <td onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        {props.company.phone}
      </td>
      <td onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        {props.company.email}
      </td>
      <td onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
        {!hovered ? (
          <>
            <Button variant="outline-light" className="hiddenButton">
              <i className="fa fa-pencil-square-o fa-1x mr-2" />
              Edit
            </Button>
            <Button variant="outline-light" className="hiddenButton">
              <i className="fa fa-trash-o fa-1x mr-2 light" />
              Delete
            </Button>
          </>
        ) : (
          <>
            <Button size="md" color="light" className="button-default" onClick={() => props.editCB(props.company)}>
              <i className="fa fa-pencil-square-o fa-1x mr-2" />
              Edit
            </Button>

            <Button size="md" color="danger" className="button-cancel" onClick={() => props.deleteCB(props.company)}>
              <i className="fa fa-trash-o fa-1x mr-2" />
              Delete
            </Button>
          </>
        )}
      </td>
    </>
  )
}

export default CompanyListObj