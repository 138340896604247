import { useState, useEffect } from "react";

const useFormStart = (callback, validateAddDevice) => {
  const [values, setValues] = useState({
    username: "",
    uPassword: "",
    email: "",
    password1: "",
    password2: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    //console.log(event);
    //console.log(name, value);
    // if (name === "thermal") {
    //   setValues({ ...values, thermal: !values.thermal });
    // } else if (name === "zoneDropdown") {
    //   setValues({ ...values, zoneName: value });
    // }
    // else if (name === "username" || name === "uPassword") {
    //   setIsLoggingIn(true);
    //   setValues({
    //     ...values,
    //     [name]: value,
    //   });
    // } else {

    setValues({
      ...values,
      [name]: value,
    });
    //   delete errors.zoneName
  };

  const handleSubmit = (e) => {
    //   console.log('inside handleSubmit in useFormZone')
    e.preventDefault();
    setErrors(validateAddDevice(values));
    setIsSubmitting(true);
    // setValues({...values, username: "", uPassword:""})
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback(values);
      if (!isSubmitting) {
        alert("Device added!");
      }
      setIsSubmitting(false);
    }
  }, [callback, errors, values, isSubmitting]);

  return {
    handleChange,
    handleSubmit,
    values,
    setErrors,
    setValues,
    errors,
  };
};

export default useFormStart;
