/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Row, Col, Media } from "reactstrap";
// import logo from "../../assets/tgs-logo.png";
import logo from "../../assets/engrafis-logo-2020.png";
import "./authHeader.scss";

class AuthHeader extends React.Component {
  render() {
    return (
      <>
        {/* <img src={logo} className="logo bg-white" alt="logo" /> */}
        <Media src={logo} className="p-4" style={{ maxHeight: 150 }} />
        <div
          className="bg-white"
          // style={{ backgroundColor: "#4CC3FA" }}
        >
          <div
            className="container bg-white"
            // style={{ backgroundColor: "#4CC3FA" }}
          >
            <div className="text-center bg-white mb-2">
              <Row className="justify-content-center">
                <Col className="px-5" lg="6" md="8" xl="5">
                  {this.props.title ? (
                    <h1 className="text-darker">{this.props.title}</h1>
                  ) : null}
                  {this.props.lead ? (
                    <p className="text-lead text-darker">{this.props.lead}</p>
                  ) : null}
                </Col>
              </Row>
            </div>
            {/* <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-success"
                  points="2560 0 2560 500 0 100"
                />
              </svg>
            </div> */}
          </div>
        </div>
      </>
    );
  }
}

AuthHeader.propTypes = {
  title: PropTypes.string,
  lead: PropTypes.string,
};

export default AuthHeader;
