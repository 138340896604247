import axiosIP from "axios";

const urlArray = window.location.href.split(".");
let url;

if (urlArray.length > 1) {
  url = "https://logbook-pwa-dev.timbergrove.com";
} else {
  url = "http://localhost:5000";
}
//FOR LOCAL DEV PURPOSES ONLY...
// url = "https://a156e36aeef8.ngrok.io";
// url = "http://localhost:5000";
// url = "https://logbook-pwa-dev.timbergrove.com";
console.log("axios base url:", url);

const axios = axiosIP.create({
  baseURL: url,
  timeout: 10000,
  headers: {},
});

export default axios;
