import React from 'react'
import {Button, Modal, ModalHeader, ModalBody, FormGroup, Input, InputGroup, Row, Col, Label} from 'reactstrap'

function AddZoneModal({isOpen, toggle, handleSubmit, values, handleChange, errors}) {

    return (
        <Modal isOpen={isOpen} toggle={toggle} centered>
            <ModalHeader toggle={toggle}>Add a New Zone</ModalHeader>
            <ModalBody className="addZoneModal">
                <form onSubmit={handleSubmit}>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <Label className="font-weight-bold">Zone Name</Label>
                                <InputGroup className="input-group-merge input-group-alternative">
                                    <Input type="text" name="zoneName" value={values.zoneName} onChange={handleChange} autoFocus />
                                </InputGroup>
                                {errors.zoneName && (<p className="error">{errors.zoneName}</p>)}
                            </FormGroup>
                            <div className="text-right">
                                <Button size="md" color="secondary" onClick={() => toggle(false)}>Cancel</Button>
                                <Button size="md" color="primary" type="submit">Save</Button>
                            </div>
                        </Col>
                    </Row>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default AddZoneModal