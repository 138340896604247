import React, { useState } from 'react'
import '../addDevice/addDevice.scss'
import { formatPhoneNumber } from '../../utils'
import { Button, Card, CardHeader, CardBody, FormGroup, Input, Row, Col, Label, Form } from 'reactstrap'
import { InputFilled } from "../ManualOnboarding/InputFilled";

function CreateCompanyModal(props) {
    const [newCompany, setNewCompany] = useState({})

    const handleCompanyInfo = (e) => {
        if(e.target.name == 'phone') {
            setNewCompany({
                ...newCompany,
                [e.target.name]: formatPhoneNumber(e.target.value)
            })
        } else {
            setNewCompany({
                ...newCompany,
                [e.target.name]: e.target.value
            })
        }
    }

    return (
        <div className="card-wrapper delModalOverlay ContactManagementModal">
            <Card className="bg-white" style={{margin: "7rem 16rem 7rem 16rem"}}>
                <CardHeader className="d-flex justify-content-between align-items-center bg-white border-bottom-0 pb-0">
                    <h3 className="mb-0 font-weight-400">Create company contact</h3>
                    <div style={{ cursor: 'pointer' }} onClick={() => props.setIsShowing(false)}>x</div>
                </CardHeader>
                <CardBody className="bg-white">
                    <Form className="outline-dark" onSubmit={(e) => props.handleSave(e, newCompany)}>
                        <Row>
                            <Col md="12">
                                <div className="row">
                                    <FormGroup className="col">
                                        <InputFilled className="InputFilledFullWidth" label="Company name" placeholder="Company name" type="text" name="company" value={newCompany.company} onChange={handleCompanyInfo} required />
                                    </FormGroup>
                                </div>
                                <div className="row">
                                    <FormGroup className="col">
                                        <InputFilled className="InputFilledFullWidth" label="Phone number" placeholder="Phone number" type="tel" name="phone" value={newCompany.phone} pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" onChange={handleCompanyInfo} required />
                                    </FormGroup>
                                </div>
                                <div className="row">
                                    <FormGroup className="col">
                                        <InputFilled className="InputFilledFullWidth" label="Email" placeholder="Email" type="email" name="email" value={newCompany.email} onChange={handleCompanyInfo} required />
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-end my-3 mr-0 buttons">
                            <Button color="secondary" size="md" onClick={() => props.setIsShowing(false)}>Cancel</Button>
                            <Button color="primary" size="md" type="submit">Save</Button>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}

export default CreateCompanyModal
