import React, { useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Button, Row } from "reactstrap";
import { ReactComponent as DraggableIcon } from './draggable.svg';
import { ReactComponent as EditIcon } from './icons/edit.svg';
import { ReactComponent as DeleteIcon } from './icons/delete.svg';
import { stepTypes } from "./data";

const ConfigItem = SortableElement(({ value }) => {
  const [hovered, setHovered] = useState(false);
  const selectVariants = value.stepInfo.key === 'question'
    ? (value.values.selectType === 'single' ? ['Yes', 'No'] : value.values.answers)
    : null;

  return (
    <li className={`ConfigItem${value.stepInfo.draggable ? ' draggable' : ''}`} onMouseOver={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <i><DraggableIcon /></i>
      <p className="ConfigItem-label">{value.stepInfo.label}</p>
      <p className="ConfigItem-description">
        {value.values?.question || value.values?.previewMessage || value.stepInfo.description}
      </p>
      <div className="ConfigItem-variants">
        {selectVariants ? selectVariants.map((variant) => <p className="ConfigItem-variants-item" key={variant}>{variant}</p>) : null}
      </div>
      {value.stepInfo.draggable && <Row className={`ConfigItem-controls${hovered ? ' visible' : ''}`}>
        {value.stepInfo.editable && <Button onClick={value.onEdit}>
          <EditIcon className="ConfigItem-controlIcon" />
          Edit
        </Button>}
        <Button color="danger" onClick={value.onDelete}>
          <DeleteIcon className="ConfigItem-controlIcon" />
          Delete
        </Button>
      </Row>}
    </li>
  );
});

const SortableConfig = SortableContainer(({ items, onEdit, onDelete }) => (
  <ul>
    {items.map((item, index) => {
      const stepInfo = stepTypes[item.type];
      return (
        <ConfigItem
          key={stepInfo.key + JSON.stringify(item.values)}
          index={index}
          value={{ stepInfo, values: item.values, onDelete: () => onDelete(index), onEdit: () => onEdit(item) }}
          disabled={!stepInfo.draggable}
        />
      );
    })}
  </ul>
));

const ConfigList = ({ steps, onSortEnd, onEdit, onDelete, changeMoveStatus, moveStatus }) => {
  return (
    <div className={`ConfigList${moveStatus ? ' dragging' : ''}`}>
      <SortableConfig
        items={steps}
        onEdit={onEdit}
        onDelete={onDelete}
        onSortStart={() => changeMoveStatus('grabbing')}
        onSortMove={() => changeMoveStatus('move')}
        onSortEnd={({ newIndex, oldIndex }) => {
          if (newIndex !== 0 && (newIndex !== steps.length - 1) && oldIndex !== newIndex) onSortEnd({ newIndex, oldIndex });
          changeMoveStatus(undefined);
        }}
      />
    </div>
  );
};

export default ConfigList;
