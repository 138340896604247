import { toast } from "react-toastify";
import axios from "../../axiosconfig";
import {
  GET_STEPS_CONFIGURATION,
  GET_STEPS_CONFIGURATION_SUCCESS,
  GET_STEPS_CONFIGURATION_FAILED,
  UPDATE_STEPS_CONFIGURATION,
  UPDATE_STEPS_CONFIGURATION_SUCCESS,
  UPDATE_STEPS_CONFIGURATION_FAILED,
} from "./types";

export const getSteps = (zoneName) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_STEPS_CONFIGURATION });
    const token = getState().token;
    const res = await axios.get(`/steps/getSteps/${zoneName}`, {
      headers: { authorization: `Bearer ${token}` },
    });
    const stepsList = JSON.parse(res?.data?.steps);
    dispatch({
      type: GET_STEPS_CONFIGURATION_SUCCESS,
      payload: stepsList,
    });
    return stepsList;
  } catch (err) {
    dispatch({
      type: GET_STEPS_CONFIGURATION_FAILED,
      payload: err.message,
    });
    toast.error("Fetch steps configuration list error", {
      position: "bottom-center",
      icon: false,
      theme: "dark",
    });
  }
};

export const updateSteps = ({ zoneName, steps }) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_STEPS_CONFIGURATION });
    const token = getState().token;
    const res = await axios.post("/steps/updateSteps", { zoneName, steps }, {
      headers: { authorization: `Bearer ${token}` },
    });

    dispatch({
      type: UPDATE_STEPS_CONFIGURATION_SUCCESS,
      payload: res.data,
    });

    return res.data;
  } catch (err) {
    dispatch({
      type: UPDATE_STEPS_CONFIGURATION_FAILED,
      payload: err.message,
    });

    toast.error("Save steps configuration error", {
      position: "bottom-center",
      icon: false,
      theme: "dark",
    });
  }
};
