import { useState, useEffect } from "react";

const useForm = (callback, validateAddDevice) => {
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    adminCode: "",
    authType: "manager",
    zoneName: "Unassigned",
    isAdmin: false,
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    // console.log(name, value);
    if (name === "isAdmin") {
      setValues({
        ...values,
        isAdmin: !values.isAdmin,
      });
    } else if (name === "zoneDropdown") {
      setValues({
        ...values,
        zoneName: value,
      });
    } else if (name.includes("dropdown")) {
      setValues({
        ...values,
        authType: value,
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    // console.log(
    //   "handle submit called for create admin, values: " + JSON.stringify(values)
    // );
    e.preventDefault();
    setErrors(validateAddDevice(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log("submitting");
      callback();
      // alert("Admin created!");
      setIsSubmitting(false);
    }
  }, [errors]);

  return {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
    setErrors,
  };
};

export default useForm;
