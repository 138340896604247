import React, { useState, useEffect } from "react";
import "../addDevice/addDevice.scss";
// import { removeDevice } from "../../actions/userActions";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
} from "reactstrap";

import validatePwReset from './validatePwReset'
import useForm from './useFormStart'

function ForgotPasswordForm(props) {
// console.log(props)

  var validator = require("email-validator");

  

    // const [emailAddress, setEmailAddress] = useState("");


  const submit = () => {
    console.log(
    values.email, errors
    );
    console.log(
      "Submit ",
      values.email,
      " to the back end for authentication"
    );
    if (validator.validate(values.email)) {
      props.passwordReset(values.email);
      props.setErrors({});
      console.log(errors)
      props.setValues({...props.values, username: "", uPassword: "", email: ""})
      console.log(values)
      props.setShowPWReset(false);
      alert("reset email sent");
     } 
  }

  const { handleChange, handleSubmit, values, setValues, errors, setErrors } = useForm(submit, validatePwReset);



  // const [myErrors, setMyErrors] = useState({errors})

  // useEffect(()=> {console.log('myErrors has changed')}, [myErrors])

  //   useEffect(() => {
  //     // console.log(validator.validate("test@email.com"));
  //     console.log(emailAddress, validator.validate(emailAddress));
  //   });
  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   console.log("forgot text field: ", name, value);
  //   setEmailAddress(value);
  // };





  return (
    <div className="delModalOverlay">
      <Card className="bg-white">
        <CardHeader>
          <h3 className="mb-2">Password Reset</h3>
        </CardHeader>
        <CardBody>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md="12">
              <FormGroup className="ml-5 mr-5">
          <Label className="font-weight-bold mt-3">Account Email</Label>
          <InputGroup className="input-group-merge input-group-alternative">
            <Input
              type="text"
              name="email"
              // value={emailAddress}
              // onChange={(e)=>setEmailAddress(e.target.value)}
              onChange={handleChange}
              autoFocus
            />
          </InputGroup>
          {errors.email && <p className="error">{errors.email}</p>}
        </FormGroup>
                <div className="text-center mt-3 buttons">
                  <Button
                    size="md"
                    color="medium"
                    // className="saveButton"
                    style={{
                      backgroundColor: "#2DCE89",
                      color: "white",
                    }}
                    onClick={() => {
                      // props.history.push("/");
                      props.setShowPWReset(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    size="md"
                    color="dark"
                    // onClick={handleSubmit}
                    //TODO: call the DB, make sure that user email is in the db, then create a reset token to email the user and store token in the user data. Append reset token to the end of the link that is emailed to the user with an endpoint to change password. Server side checks the req.params for the token and compares it with the token stored under user data in the db. Once user is verified, display change password form.
                    style={{
                      backgroundColor: "#EB3446",
                      color: "white",
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    </div>
  );
}

export default ForgotPasswordForm;
