import * as actions from "../actions/types";

const initialState = {
  speechToText: "",
  userStep: "welcome",
  statusMessage: "Please present your ID",
  imagesArray: [],
  currentSpeech: "",
  audioRecording: "",
  imageTaken: false,
  socket: null,
  socketURL: null,
  flag: "",
  flagId: "",
  user: {},
  isAuthenticated: false,
  token: null,
  loginMessage: "",
  device: {},
  deviceAdded: false,
  users: [],
  picturesLoading: false,
  isAdmin: false,
  admin: {},
  addDeviceErrorMessage: "",
  devices: [],
  admins: [],
  prescreens: [],
  zones: [],
  openedSelector: false,
  vaccines: [
    "Moderna",
    "Pfizer–BioNTech",
    "Janssen-Johnson&Johnson",
    "Oxford–AstraZeneca",
    "Sinopharm-BBIBP",
    "Sputnik V",
    "Serum Institute of India",
    "Sinovac",
  ],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.STORE_USER:
      return {
        ...state,
        user: action.payload,
      };

    case actions.TOGGLE_SELECTOR:
      return {
        ...state,
        openedSelector: !state.openedSelector,
      };

    case actions.SPEECH_INPUT:
      return {
        ...state,
        speechToText: action.payload,
      };

    case actions.USER_STEP:
      return {
        ...state,
        userStep: action.payload,
      };

    case actions.STATUS_MESSAGE:
      return {
        ...state,
        statusMessage: action.payload,
      };

    case actions.UPDATE_IMAGE_ARRAY:
      return {
        ...state,
        imagesArray: action.payload,
      };

    case actions.ID_INFO:
      return {
        ...state,
        idInfo: action.payload,
      };

    case actions.CLEAR_INFO:
      return {
        ...state,
        idInfo: {},
        userStep: "welcome",
        imagesArray: [],
      };

    case actions.CURRENT_SPEECH:
      return {
        ...state,
        currentSpeech: action.payload.userPhrase,
      };

    case actions.RECORD_AUDIO:
      return {
        ...state,
        audioRecording: action.payload,
      };

    case actions.DEFINE_USER:
      return {
        ...state,
        user: action.payload,
      };

    case actions.GET_TWILIO_TOKEN:
      return {
        ...state,
        twilioIdentity: action.payload.identity,
        twilioToken: action.payload.token,
      };

    case actions.CREATE_ROOM:
      return {
        ...state,
        roomName: action.payload,
      };

    case actions.INCOMING_CALL:
      return {
        ...state,
        incomingCall: action.payload.incomingCall,
        roomName: action.payload.room,
        flag: action.payload.flag,
        flagId: action.payload.regId,
      };
    case actions.ANSWER_INCOMING_CALL:
      return {
        ...state,
        incomingCall: action.payload,
      };

    case actions.SET_USERS:
      return {
        ...state,
        users: action.payload,
      };

    case actions.CREATE_SESSION_ID:
      return {
        ...state,
        sessionId: action.payload,
      };

    case actions.IMAGE_TAKEN:
      return {
        ...state,
        imageTaken: action.payload,
      };

    case actions.ON_CONNECT_SOCKET:
      return {
        ...state,
        socket: action.payload,
      };

    case actions.GET_SOCKET_URL:
      return {
        ...state,
        socketURL: action.payload,
      };

    case actions.IMAGES_LOADING:
      return {
        ...state,
        picturesLoading: true,
      };

    case actions.IMAGE_LOADED:
      return {
        ...state,
        images: action.payload.images,
        image:
          `${action.payload.image}`.length > 0
            ? `data:image/jpeg;base64,${action.payload.image}`
            : "",
        imageId:
          `${action.payload.imageId}`.length > 0
            ? `data:image/jpeg;base64,${action.payload.imageId}`
            : "",
        picturesLoading: false,
      };

    case actions.UPDATE_ADMIN_SETTINGS:
      return {
        ...state,
        admin: action.payload,
      };

    case actions.LOGIN_USER:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        admin: action.payload.admin,
      };

    case actions.AUTOLOGIN_USER:
      return {
        ...state,
        token: action.payload.token,
        admin: action.payload.admin,
      };

    case actions.LOGIN_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        loginMessage: "Incorrect username and/or password",
      };
    case actions.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        admin: null,
      };
    case actions.ADD_DEVICE:
      return {
        ...state,
        device: action.payload.device,
        devices: action.payload.devices,
        deviceAdded: true,
      };
    case actions.ADD_DEVICE_FAIL:
      return {
        ...state,
        addDeviceErrorMessage: "Error adding device",
      };
    // TODO: ADD EDIT DEVICE SUCCESS/FAIL CASE
    case actions.EDIT_DEVICE:
      return {
        ...state,
        devices: action.payload,
      };
    case actions.EDIT_DEVICE_FAIL:
      return {
        ...state,
        addDeviceErrorMessage: "Error modifying device",
      };
    // TODO: ADD DELETE DEVICE CASES... fuck this... I'm lazy, just use EDIT_DEVICE, same diff.
    case actions.CLEAR_IMAGES:
      return {
        ...state,
        images: "",
        imageId: undefined,
      };
    case actions.CREATE_ADMIN:
      return {
        ...state,
        admins: action.payload,
        isAdmin: true,
      };
    // TODO: ADD EDIT ADMIN SUCCESS/FAIL CASE
    case actions.EDIT_ADMIN:
      return {
        ...state,
        admins: action.payload,
      };
    case actions.EDIT_ADMIN_FAIL:
      return {
        ...state,
        addDeviceErrorMessage: action.payload,
      };
    case actions.GET_DEVICES:
      return {
        ...state,
        devices: action.payload,
      };
    case actions.GET_ADMINS:
      return {
        ...state,
        admins: action.payload,
      };
    case actions.SET_ZONES:
      return {
        ...state,
        zones: action.payload,
      };
    case actions.SET_PRESCREENS:
      return {
        ...state,
        prescreens: action.payload,
      };
    default:
      return state;
  }
}
