import React from "react";
import Loader from "react-loader-spinner";
import "./dashboard.scss";

function LoadingIndicator() {
  return (
    <div className="loader">
      <Loader type="ThreeDots" color="#4c5e7a" height="100" width="100" />
    </div>
  );
}

export default LoadingIndicator;
