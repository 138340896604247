// import { useCallback, useEffect, useRef } from 'react'

// export const useClickOutsideListenerRef = (onClose) => {
//   const ref = useRef(null)
//   const escapeListener = useCallback((e) => {
//     if (e.key === 'Escape') {
//       onClose()
//     }
//   }, [])
//   const clickListener = useCallback(
//     (e) => {
//       if (!(ref.current).contains(e.target)) {
//         onClose.()
//       }
//     },
//     [ref.current],
//   )
//   useEffect(() => {
//     document.addEventListener('click', clickListener)
//     document.addEventListener('keyup', escapeListener)
//     return () => {
//       document.removeEventListener('click', clickListener)
//       document.removeEventListener('keyup', escapeListener)
//     }
//   }, [])
//   return ref
// }


import { useEffect, useCallback } from "react";

const useOutsideClickListenerRef = (ref, callback) => {
  
    const escapeListener = useCallback((e) => {
    if (e.key === 'Escape') {
      callback()
    }
  }, [])
  
    const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document.addEventListener('keyup', escapeListener)

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener('keyup', escapeListener)
    };
  });
};

export default useOutsideClickListenerRef;

