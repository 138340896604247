// import { ValidationRequestInstance } from "twilio/lib/rest/api/v2010/account/validationRequest";

export default function validatePwReset(values) {

    var validator = require("email-validator");


    let errors = {};
    // let emailRegex = /[@a-zA-z0-9\.]/gm;
  
    if (!values.email) {
      errors.email = "please enter an email";
    }
    if (!validator.validate(values.email)) {
      errors.email = "please enter a valid email";
    }


  
    return errors;
  }
  