import React from "react";
import "./addDevice.scss";
// import { removeDevice } from "../../actions/userActions";
import { Button, Card, CardHeader, CardBody, Row, Col } from "reactstrap";

function ConfirmDeleteModal(props) {
  const device = props.device;
  const { setShowDeleteModal } = props;

  return (
    <div className="delModalOverlay">
      <Card className="bg-white delModalConfirm" >
        <CardHeader className="d-flex justify-content-between pb-0 border-bottom-0 align-items-center">
          <h3 className="mb-0">Delete Confirmation Page</h3>
          <Button
            onClick={() => {
              console.log("button clicked!");
              setShowDeleteModal(false)}}>
            X
          </Button>
        </CardHeader>
        <CardBody className="pb-0">
          <form>
            <Row>
              <Col md="12">
                <h3 className="bg-white pb-2 text-muted" >
                  Are you sure you want to delete {device.deviceName}?
                </h3>
                  <Row className="text-right mt-3 mr-2 mb-2 buttons d-flex justify-content-end">
                    <Button
                      size="md"
                      color="light"
                      type="submit"
                      onClick={() => setShowDeleteModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="md"
                      color="danger"
                      onClick={() => props.removeDevice(device, props.page)}
                    >
                      Delete
                    </Button>
                </Row>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    </div>
  );
}

export default ConfirmDeleteModal;
