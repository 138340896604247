import React, { useState } from 'react'
import '../addDevice/addDevice.scss'
import { Button } from 'reactstrap'

function GroupsListObj(props) {
    const [hovered, setHovered] = useState(false)

    const convertToTime = (cron) => {
        const cronArr = cron.split(' ')
        const minute = cronArr[0]
        const hour = cronArr[1]
        const time = new Date(0, 0, 0, hour, minute, 0, 0)
        return time.toLocaleTimeString()
    }

    return (
        <>
            <td onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                {props.group.groupName}
            </td>
            <td onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                {convertToTime(props.group.resetTime)}
            </td>
            <td onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                {!hovered ?
                    <>
                        <Button variant="outline-light" className="hiddenButton">
                        <i className="fa fa-pencil-square-o fa-1x mr-2"/>
                        Edit
                        </Button>
                    </>
                :
                    <>
                        <Button size="md" color="light" className="button-default" onClick={() => {props.showEditGroupModal(props.group)}}>
                        <i className="fa fa-pencil-square-o fa-1x mr-2" />
                        Edit
                        </Button>
                    </>
                }
            </td>
        </>
    )
}

export default GroupsListObj