export const STORE_USER = "STORE_USER";
export const DEFINE_USER = "DEFINE_USER";
export const SPEECH_INPUT = "SPEECH_INPUT";
export const USER_STEP = "USER_STEP";
export const STATUS_MESSAGE = "STATUS_MESSAGE";
export const UPDATE_IMAGE_ARRAY = "UPDATE_IMAGE_ARRAY";
export const ID_INFO = "ID_INFO";
export const SUBMIT_INFO = "SUBMIT_INFO";
export const CLEAR_INFO = "CLEAR_INFO";
export const CURRENT_SPEECH = "CURRENT_SPEECH";
export const RECORD_AUDIO = "RECORD_AUDIO";
export const GET_TWILIO_TOKEN = "GET_TWILIO_TOKEN";
export const CREATE_ROOM = "CREATE_ROOM";
export const INCOMING_CALL = "INCOMING_CALL";
export const ANSWER_INCOMING_CALL = "ANSWER_INCOMING_CALL";
export const SET_USERS = "SET_USERS";
export const CREATE_SESSION_ID = "CREATE_SESSION_ID";
export const IMAGE_TAKEN = "IMAGE_TAKEN";
export const ON_CONNECT_SOCKET = "ON_CONNECT_SOCKET";
export const IMAGE_LOADED = "IMAGE_LOADED";
export const LOGIN_USER = "LOGIN_USER";
export const AUTOLOGIN_USER = "AUTOLOGIN_USER";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const ADD_DEVICE = "ADD_DEVICE";
export const EDIT_DEVICE = "EDIT_DEVICE";
export const EDIT_DEVICE_FAIL = "EDIT_DEVICE_FAIL";
export const ADD_DEVICE_FAIL = "ADD_DEVICE_FAIL";
export const CLEAR_IMAGES = "CLEAR_IMAGES";
export const IMAGES_LOADING = "IMAGE_LOADING";
export const CREATE_ADMIN = "CREATE_ADMIN";
export const EDIT_ADMIN = "EDIT_ADMIN";
export const EDIT_ADMIN_FAIL = "EDIT_ADMIN_FAIL";
export const GET_DEVICES = "GET_DEVICES";
export const GET_ADMINS = "GET_ADMINS";
export const GET_SOCKET_URL = "GET_SOCKET_URL";
export const SET_ZONES = "SET_ZONES";
export const UPDATE_ADMIN_SETTINGS = "UPDATE_ADMIN_SETTINGS";
export const SET_PRESCREENS = "SET_PRESCREENS";
export const TOGGLE_SELECTOR = "TOGGLE_SELECTOR";
