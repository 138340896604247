import React from 'react'
import '../addDevice/addDevice.scss'
import { Button, Card, CardHeader, CardBody, Row, Col, Form } from 'reactstrap'

function DeleteEmployeeModal(props) {

    return (
        <div className="card-wrapper delModalOverlay ContactManagementModal">
            <Card className="bg-white" style={{margin: "7rem 16rem 7rem 16rem"}}>
                <CardHeader className="d-flex justify-content-between align-items-center bg-white border-bottom-0 pb-0">
                    <h3 className="mb-0 font-weight-400">Delete company contact</h3>
                    <div style={{ cursor: 'pointer' }} onClick={() => props.setIsShowing(false)}>x</div>
                </CardHeader>
                <CardBody className="bg-white">
                    <Form className="outline-dark" onSubmit={(e) => props.handleSave(e, props.company)}>
                        <Row>
                            <Col md="12" className="d-flex justify-content-center">
                                <h3>{`Are you sure you want to delete ${props.company.company}`}?</h3>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-end my-3 mr-3 buttons">
                            <Button size="md" color="light" onClick={() => props.setIsShowing(false)}>Cancel</Button>
                            <Button size="md" color="danger" type="submit">Delete</Button>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}

export default DeleteEmployeeModal
