import React, { useState } from "react";
import "../addDevice/addDevice.scss";
// import { removeDevice } from "../../actions/userActions";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroup,
  Row,
  Container,
  Col,
  Table,
  CardFooter,
  Label,
  Form,
} from "reactstrap";
// import { remove } from "../../../../models/deviceModel";

function ZoneConfirmDeleteModal(props) {
  const [zone, setZone] = useState(props.zoneToEdit);
  const pg = props.pg;
  const { setZoneModal, remZone } = props;
  console.log(props);
  console.log("zone", zone);

  return (
    <div className="delModalOverlay">
      <Card className="bg-white ">
        <CardHeader style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                          <h3 className="mb-0 bg-white">
                            Confirm Delete Action
                          </h3>
          <Button
                          onClick={() => {
                            console.log("button clicked!");
                            setZoneModal(false)
                          }}
                        >
                          X
                        </Button>

        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md="12">
                <h3>Are you sure you want to delete {zone.zoneName}?</h3>
                
                <div></div>
                <div className="text-right mt-3 buttons">
                  <Button
                    size="md"
                    color="light"
                    type="submit"
                    // className="saveButton"
                    // style={{
                    //   backgroundColor: "#2DCE89",
                    //   color: "white",
                    // }}
                    onClick={() => setZoneModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="md"
                    color="primary"
                    // onClick={remZone}
                    onClick={() => {
                      props.removeZone(zone);
                    }}
                    className="deleteButton"
                    style={{
                      backgroundColor: "#EB3446",
                      color: "white",
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}

export default ZoneConfirmDeleteModal;
