import { useState, useEffect } from "react";

const useForm = (callback, validateAddDevice) => {
  const [values, setValues] = useState({
    deviceName: "",
    password: "",
    thermal: true,
    zoneName: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  // console.log(values.);

  const handleChange = (event) => {
    const { name, value } = event.target;
    // console.log(event);
    // console.log(name, value);
    if (name === "thermal") {
      setValues({ ...values, thermal: !values.thermal });
    } else if (name === "zoneDropdown") {
      setValues({ ...values, zoneName: value });
    } 
    else if (name === "username" || name === "uPassword") {
      setIsLoggingIn(true);
      setValues({
        ...values,
        [name]: value,
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validateAddDevice(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting ) {
      callback(values);
      if (!isSubmitting) {alert("Device added!")};
      setIsSubmitting(false);
    }
  }, [callback, errors, isSubmitting]);

  return {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
  };
};

export default useForm;
