import React from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
} from "reactstrap";
import { FaExternalLinkAlt } from "react-icons/fa";
import { IoIosRefresh } from "react-icons/io";
import CardsHeader from "../CardsHeader/CardsHeader";
import LoadingIndicator from "./LoaderSpinner";
import notificationSound from "../../sounds/notification.mp3";
import {
  createRoom,
  searchForCall,
  incomingCall,
  getImagesFromS3,
  clearImages,
  viewImages,
} from "../../actions/mediaActions";
import {
  defineUser,
  findUsers,
  findPrescreens,
  onLogout,
} from "../../actions/userActions";
import { sortArrByColumn } from "../../utils";
// import { getSocketEnvironment } from "../../actions/environmentActions";
import "./dashboard.scss";
import PreRegisterFormModal from "../PreRegister/PreRegisterFormModal";
import PrintBadgeModal from "../PrintBadge/PrintBadgeModal";
import { Table } from "../Table/Table";
import { ReactComponent as PictureIcon } from "../../assets/tabs/picture.svg";
import PermissionWrapper from "../permissions/PermissionWrapper";
import { SCOPES } from "../permissions/permission-map";

const audioElem = new Audio(notificationSound);
audioElem.loop = true;

const mapColumnToDataName = {
  time: "timestamp",
  firstName: "firstName",
  lastName: "lastName",
  state: "state",
  dob: "DOB",
  type: "idType",
  number: "number",
  flag: "flag",
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      incomingCall: false,
      modal: false,
      image: "",
      imageId: "",
      latestUsers: [],
      latestUsersTable: true,
      preRegisterModal: false,
      latestPrescreens: [],
      latestPrescreensTable: false,
      activeTab: 1,
      sort: {
        col: null,
        desc: false,
      },
      showPrintBadgeModal: false,
      userToPrint: {},
    };

    this.toggle = this.toggle.bind(this);
    this.sendPreSchedule = this.sendPreSchedule.bind(this);
    this.togglePreRegisterModal = this.togglePreRegisterModal.bind(this);
    this.toggleImageModal = this.toggleImageModal.bind(this);
    this.toggleTableView = this.toggleTableView.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.getStatusIcon = this.getStatusIcon.bind(this);
    this.displayPrintModal = this.displayPrintModal.bind(this);
    this.printVisitorBadge = this.printVisitorBadge.bind(this);
  }

  async componentDidMount() {
    // console.log("Dashboard admin: ", this.props.admin);
    // console.log("Dashboard token", this.props.token);
    this.setUsers();
    this.setPrescreens();
    this.toggleTab("1");
  }

  async handleNewEntry() {
    await this.props.findUsers(this.props.token);
  }

  componentDidUpdate(prevProps) {
    if (this.props.users.length !== prevProps.users.length) {
      this.setUsers();
    }
  }

  async setUsers() {
    let answer = await this.props.findUsers(this.props.token);
    setTimeout(() => {
      // this forces logout for user with bad credentials.
      // it's intended to respond to the login pushing to the dashboard
      if (!answer) {
        console.log("un-authorized user- call logout");
        this.props.onLogout();
        window.location.href = "/";
      }
    }, 250);
    // console.log("After query", this.props.users);
    let latestUsers = this.props.users
      .filter((user) => user.isArchived != true)
      .slice(0, 10);
    this.setState({ latestUsers });
  }

  onChangeSort = (e) => {
    const { users, prescreens } = this.props;
    const { sort, activeTab } = this.state;

    let initArr;
    let newArrStateName;
    if (activeTab === "1") {
      initArr = users;
      newArrStateName = "latestUsers";
    } else {
      initArr = prescreens;
      newArrStateName = "latestPrescreens";
    }

    const { newSort, newArr } = sortArrByColumn(
      e,
      sort,
      initArr,
      mapColumnToDataName
    );
    this.setState({
      sort: newSort,
      [newArrStateName]: newArr.slice(0, 10),
    });
  };

  async setPrescreens() {
    await this.props.findPrescreens(this.props.token);
    let latestPrescreens = this.props.prescreens.slice(0, 10);
    //console.log("prescreens:", this.props.prescreens);

    this.setState({ latestPrescreens });
  }

  handleImages = (user) => {
    this.props.viewImages();
    this.props.getImagesFromS3(user.image, user.imageId, user.sessionId);
    this.setState({ firstName: user.firstName });
    this.clear();
    this.toggleImageModal();
    //this.toggle();
  };

  sendPreSchedule() {
    //this.setPrescreens();

    this.setState((prevState) => ({
      preRegisterModal: !prevState.preRegisterModal,
      // latestPrescreensTable: !prevState.latestPrescreensTable,
      // latestUsersTable: !prevState.latestUsersTable,
    }));
  }

  toggleImageModal() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  togglePreRegisterModal() {
    // console.log("inside togglePreRegisterModal");
    this.setState((prevState) => ({
      preRegisterModal: !prevState.preRegisterModal,
    }));
  }

  toggleTableView() {
    this.setState((prevState) => ({
      latestPrescreensTable: !prevState.latestPrescreensTable,
    }));
  }

  toggle() {
    this.setState((prevState) => ({
      preRegisterModal: !prevState.preRegisterModal,
      latestPrescreensTable: !prevState.latestPrescreensTable,
      latestUsersTable: !prevState.latestUsersTable,
    }));
  }

  toggleTab = (tab) => {
    if (this.activeTab !== tab) {
      this.setState({ activeTab: tab, sort: { col: null, desc: false } });
    }
  };

  printVisitorBadge(user) {
    this.setState({
      userToPrint: user,
    });
    this.displayPrintModal(true);
  }

  displayPrintModal(displayed) {
    this.setState({
      showPrintBadgeModal: displayed,
    });
  }

  getStatusIcon = (code) => {
    //console.log("code:", code);
    if (code.length === 0) {
      return <i className="fa fa-circle" style={{ color: "gray" }}></i>;
    }
    if (code.indexOf("q") !== -1 || code.indexOf("t") !== -1) {
      if (code.indexOf("p") !== -1) {
        return <i className="fa fa-circle" style={{ color: "green" }}></i>;
      }
      if (code.indexOf("f") !== -1) {
        return <i className="fa fa-circle" style={{ color: "red" }}></i>;
      }
      return <i className="fa fa-circle" style={{ color: "orange" }}></i>;
    }
  };

  clear = () => this.props.clearImages();

  get1TabData = () => ({
    columns: [
      { key: "index", label: "# Number", alignCenter: true, small: true },
      {
        key: "time",
        label: "Timestamp",
        sortable: true,
        large: true,
        smallFontSize: true,
      },
      { key: "firstName", label: "First Name", sortable: true },
      { key: "lastName", label: "Last Name", sortable: true },
      { key: "state", label: "State", sortable: true, smallFontSize: true },
      {
        key: "dob",
        label: "Date of Birth",
        sortable: true,
        smallFontSize: true,
      },
      { key: "type", label: "ID Type", sortable: true, smallFontSize: true },
      { key: "number", label: "ID Number", sortable: true },
      {
        key: "flag",
        label: "Flag",
        sortable: true,
        alignCenter: true,
        noPadding: true,
      },
      { key: "photos", label: "Photos", alignCenter: true, noPadding: true },
      { key: "email", label: "Email", alignCenter: true, noPadding: true },
      {
        key: "organization",
        label: "Organization",
        alignCenter: true,
        noPadding: true,
      },
      {
        key: "vcn",
        label: "Visiting Contact Name",
        alignCenter: true,
        noPadding: true,
      },
      {
        key: "printBadge",
        label: "Print badge",
        alignCenter: true,
        noPadding: true,
      },
    ],
    content: this.state.latestUsers.map((user, index) => {
      const date = new Date(user.timestamp);
      const visitorDate = date.toLocaleString("en-US", {
        timeZone: "America/Chicago",
      });

      return [
        { key: "index", value: index + 1 },
        { key: "time", value: visitorDate },
        { key: "firstName", value: user.firstName },
        { key: "lastName", value: user.lastName },
        { key: "state", value: user.state },
        { key: "dob", value: user.DOB },
        { key: "type", value: user.idType },
        { key: "number", value: user.number },
        { key: "email", value: user.email },
        { key: "organization", value: user.organization },
        { key: "visitingContactName", value: user.visitingContactName },
        { key: "flag", value: this.getStatusIcon(user.flag) },
        {
          key: "photos",
          value: (
            <Button
              onClick={() => this.handleImages(user)}
              color="grey"
              size="md p-0"
              type="button"
            >
              <PictureIcon />
            </Button>
          ),
        },
        {
          key: "printBadge",
          value: (
            <Button
              onClick={() => this.printVisitorBadge(user)}
              color="light"
              size="sm"
              type="button"
            >
              Print
            </Button>
          ),
        },
      ];
    }),
  });

  get2TabData = () => ({
    columns: [
      { key: "index", label: "# Number", alignCenter: true, small: true },
      {
        key: "time",
        label: "Timestamp",
        sortable: true,
        large: true,
        smallFontSize: true,
      },
      { key: "firstName", label: "First Name", sortable: true },
      { key: "lastName", label: "Last Name", sortable: true },
      { key: "state", label: "State", sortable: true, smallFontSize: true },
      {
        key: "dob",
        label: "Date of Birth",
        sortable: true,
        smallFontSize: true,
      },
      { key: "type", label: "ID Type", sortable: true, smallFontSize: true },
      { key: "number", label: "ID Number", sortable: true },
      { key: "photos", label: "Photos", alignCenter: true, noPadding: true },
      { key: "email", label: "Email", alignCenter: true, noPadding: true },
      {
        key: "organization",
        label: "Organization",
        alignCenter: true,
        noPadding: true,
      },
      {
        key: "vcn",
        label: "Visiting Contact Name",
        alignCenter: true,
        noPadding: true,
      },
      {
        key: "printBadge",
        label: "Print badge",
        alignCenter: true,
        noPadding: true,
      },
    ],
    content: this.state.latestPrescreens.map((user, index) => {
      const date = new Date(user.timestamp);
      const visitorDate = date.toLocaleString("en-US", {
        timeZone: "America/Chicago",
      });

      return [
        { key: "index", value: index + 1 },
        { key: "time", value: visitorDate },
        { key: "firstName", value: user.firstName },
        { key: "lastName", value: user.lastName },
        { key: "state", value: user.state },
        { key: "dob", value: user.DOB },
        { key: "type", value: user.idType },
        { key: "number", value: user.number },
        { key: "email", value: user.email },
        { key: "organization", value: user.organization },
        { key: "visitingContactName", value: user.visitingContactName },
        {
          key: "photos",
          value: (
            <Button
              onClick={() => this.handleImages(user)}
              color="grey"
              size="md p-0"
              type="button"
            >
              <PictureIcon />
            </Button>
          ),
        },
        {
          key: "printBadge",
          value: (
            <Button
              onClick={() => this.printVisitorBadge(user)}
              color="light"
              size="sm"
              type="button"
            >
              Print
            </Button>
          ),
        },
      ];
    }),
  });

  render() {
    const { latestPrescreensTable, modal } = this.state;

    return (
      <PermissionWrapper scopes={[SCOPES.dashboard]}>
        {this.state.preRegisterModal ? (
          <PreRegisterFormModal
            togglePreRegisterModal={this.togglePreRegisterModal}
          />
        ) : null}
        {this.state.showPrintBadgeModal ? (
          <PrintBadgeModal
            setIsShowing={this.displayPrintModal}
            user={this.state.userToPrint}
          />
        ) : null}
        <CardsHeader
          name="Engrafis Dashboard"
          parentName="Dashboards"
          users={this.props.users}
        >
          {/* <img className="navbar-brand-img" src={logo} alt="logo" /> */}
        </CardsHeader>
        <Container className="mainWrapper">
          <Row>
            <div>
              <Modal
                isOpen={modal}
                toggle={this.toggleImageModal}
                className={this.props.className}
              >
                <ModalHeader toggle={this.toggleImageModal}>
                  {this.state.firstName}
                </ModalHeader>
                <ModalBody>
                  {this.props.picturesLoading ? (
                    <LoadingIndicator />
                  ) : !this.props.images ? (
                    <h5>There are no images to display</h5>
                  ) : (
                    <>
                      <img className="userImages" src={this.props.image}></img>
                      <img
                        className="userImages"
                        src={this.props.imageId}
                      ></img>
                    </>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={this.toggleImageModal}>
                    OK
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
            <div style={{ width: "100%" }}>
              <Card className="latest-entry-card pb-4">
                <CardHeader className="d-flex justify-content-between mb-1 border-0 px-0">
                  <Row>
                    <Col className="d-flex align-items-center pl-0">
                      <h5 className="h3 mb-0 wrapperTitle">
                        {latestPrescreensTable
                          ? "Latest Prescreen entries"
                          : "Latest Visitor Entries"}
                      </h5>
                    </Col>
                  </Row>
                  <Button
                    className="bg-secondary ml-auto mr-0"
                    onClick={() => {
                      this.props.history.push("/visitor-log");
                    }}
                  >
                    <FaExternalLinkAlt className="mr-2" />
                    View All Entries
                  </Button>
                  <Row className="mr-0">
                    <Col className="d-flex justify-content-end pr-0">
                      <Button
                        color="primary"
                        onClick={this.sendPreSchedule}
                        style={{ backgroundColor: "#605FF3" }}
                      >
                        <IoIosRefresh className="mr-2" size={22} />
                        Pre-register Entry
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>

                <Table
                  CustomHeaderTabs={() => (
                    <Nav tabs style={{ border: "none" }}>
                      <NavItem>
                        <Button
                          className="bg-secondary"
                          onClick={() => {
                            this.toggleTab("1");
                          }}
                          style={
                            this.state.activeTab === "1"
                              ? {
                                  padding: "0.5rem 1em",
                                  margin: 0,
                                  color: "#605FF3",
                                  fontWeight: "bold",
                                  borderColor: "rgb(94,114,228)",
                                }
                              : { padding: "0.5rem 1em", margin: 0 }
                          }
                        >
                          <i
                            className="fa fa-users mr-2"
                            aria-hidden="true"
                            style={
                              this.state.activeTab === "1"
                                ? { color: "#605FF3" }
                                : null
                            }
                          ></i>
                          Visitor Entries
                        </Button>
                      </NavItem>
                      <NavItem>
                        <Button
                          onClick={() => {
                            this.toggleTab("2");
                          }}
                          style={
                            this.state.activeTab === "2"
                              ? {
                                  padding: "0.5rem 1em",
                                  margin: "0 0 0 1rem",
                                  color: "#5e72e4",
                                  fontWeight: "bold",
                                  borderColor: "rgb(94,114,228)",
                                }
                              : { padding: "0.5rem 1em", margin: "0 0 0 1rem" }
                          }
                        >
                          <i
                            className="fa fa-qrcode mr-2"
                            aria-hidden="true"
                            style={
                              this.state.activeTab === "2"
                                ? { color: "#5e72e4" }
                                : null
                            }
                          ></i>
                          Pre-Screen Log
                        </Button>
                      </NavItem>
                    </Nav>
                  )}
                  sourcePage="dashboard"
                  content={
                    this.state.activeTab === "1"
                      ? this.get1TabData().content
                      : this.get2TabData().content
                  }
                  columns={
                    this.state.activeTab === "1"
                      ? this.get1TabData().columns
                      : this.get2TabData().columns
                  }
                  sort={this.state.sort}
                  onChangeSort={this.onChangeSort}
                />
              </Card>
            </div>
          </Row>
        </Container>
      </PermissionWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    images: state.images,
    image: state.image,
    imageId: state.imageId,
    picturesLoading: state.picturesLoading,
    incomingCallBoolean: state.incomingCall,
    users: state.users,
    prescreens: state.prescreens,
    user: state.user,
    admin: state.admin,
    socket: state.socket,
    isAuthenticated: state.isAuthenticated,
    token: state.token,
    socketURL: state.socketURL,
  };
};

export default connect(mapStateToProps, {
  createRoom,
  defineUser,
  searchForCall,
  incomingCall,
  findUsers,
  findPrescreens,
  viewImages,
  getImagesFromS3,
  clearImages,
  onLogout,
})(Dashboard);
