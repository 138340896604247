import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import '../addDevice/addDevice.scss'
import axios from '../../axiosconfig'
import { Button, Input } from 'reactstrap'
import { requestPWChange, prescreenDataInput } from '../../actions/userActions'
import { sendImageToS3 } from '../../actions/mediaActions'
import ToggleSwitch from '../sharedElements/ToggleSwitch'
import FileUploader from './FileUploader'
import validatePresScreenForm from './validatePreScreenForm'
import { formatPhoneNumber } from '../../utils'
import { PrescreenBackground, TimbergroveLogo, GreenCheck, InvalidTokenDiv, HeaderText, LargeText, RegularText, 
  CenterItemsDiv, FormDiv, MasterDiv, FormLabel, FormRow, FormField, CompleteDiv, 
  HorizontalSeparator } from './EntryFormComponents'
const url = require('url')

function EmployeeEntryForm(props) {
    const vaccines = useSelector((state) => state.vaccines)
    const id = url.parse(props.location.search, true).query.id
    const sessionId = Math.floor(Math.random() * 1000000001).toString()
    const imageId = Math.floor(Math.random() * 1000000001).toString()
    const [tokenValid, setTokenValid] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [errors, setErrors] = useState({})
    const [employee, setEmployee] = useState({sessionId: sessionId, imageId: imageId})
    const [otherVaccine, setOtherVaccine] = useState('')

    useEffect(() => {
        getEmployee(id)
    }, [])

    function parseEmployee(data) {
        if(vaccines.includes(data.vaccineName) || data.vaccineName == '') {
            return {...data, ...employee}
        } else {
            setOtherVaccine(data.vaccineName)
            return {...data, ...employee, vaccineName: 'other'}
        } 
    }

    async function getEmployee(id) {
        try {
            const response = await axios({
                url: "/employee/getEmployees",
                method: "POST",
                data: [id],
            })
            if(response.status === 200) {
                console.log(response.data[0])
                setEmployee(parseEmployee(response.data[0]))
                setTokenValid(!response.data[0].completed)
            } else {
                setTokenValid(false)
            }
        } catch (e) {
            console.log(e)
            setTokenValid(false)
        }
    }

    const handleEmployeeInfo = (e) => {
        if(e.target.name == 'phone') {
            setEmployee({
                ...employee,
                [e.target.name]: formatPhoneNumber(e.target.value)
            })
        } else {
            setEmployee({
                ...employee,
                [e.target.name]: e.target.value
            })
        }
    }

    const toggleVaccinated = (vaccinated, checked) => {
        handleEmployeeInfo({target: {name: vaccinated, value: checked}})
    }

    async function handleImageUpload(file) {
        getBase64(file).then((result) => {
            setSelectedFile(result);
        })
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })
    }

    async function submitUpdates(e) {
        e.preventDefault()
        let data = { ...employee, completed: true}
        if(employee.vaccineName == 'other') data = { ...employee, vaccineName: otherVaccine, completed: true}
        console.log("submit Updates", data)
        setErrors(validatePresScreenForm(employee))
        if (Object.keys(errors).length === 0) {
            const response = await axios({
                url: "/employee/updateEmployee",
                method: "PUT",
                data: data,
            })
            console.log('Response', response.data)
            setTokenValid(!response.data.completed)
            setEmployee(response.data)
            if(response.data.vaccinated && selectedFile) props.sendImageToS3(selectedFile, response.data.sessionId, response.data.imageId)
        }
    }

  return (
    <PrescreenBackground>
      <TimbergroveLogo src={require('../../assets/tgs-logo.png')} />
      {tokenValid ? (
        <>
          <CenterItemsDiv>
            <HeaderText>Employee Info</HeaderText>
            <MasterDiv>
              <FormDiv onSubmit={submitUpdates}>

                <FormRow>
                  <FormField>
                    <FormLabel>First Name*</FormLabel>
                      <Input
                        style={{background: "#F4F7FA"}}
                        placeholder="Enter First Name"
                        type="text"
                        name="firstName"
                        value={employee.firstName}
                        onChange={handleEmployeeInfo}
                        autoFocus
                        required
                      />
                    {errors.firstName && <p className="error">{errors.firstName}</p>}
                  </FormField>

                  <FormField>
                    <FormLabel>Last Name*</FormLabel>
                      <Input
                        style={{background: "#F4F7FA"}}
                        placeholder="Enter Last Name"
                        type="text"
                        name="lastName"
                        value={employee.lastName}
                        onChange={handleEmployeeInfo}
                        required
                      />
                    {errors.lastName && <p className="error">{errors.lastName}</p>}
                  </FormField>

                  <FormField>
                    <FormLabel>Date of Birth*</FormLabel>
                      <Input
                        style={{background: "#F4F7FA"}}
                        placeholder="Enter Date of Birth"
                        type="text"
                        name="dob"
                        value={employee.dob}
                        onChange={handleEmployeeInfo}
                        required
                      />
                    {errors.dob && <p className="error">{errors.dob}</p>}
                  </FormField>
                </FormRow>

                <FormRow>
                    <FormField>
                      <FormLabel>Email*</FormLabel>
                        <Input
                          style={{background: "#F4F7FA"}}
                          placeholder="Enter Email"
                          type="text"
                          name="email"
                          value={employee.email}
                          onChange={handleEmployeeInfo}
                          required
                        />
                    </FormField>
                    <FormField>
                      <FormLabel>Phone Number*</FormLabel>
                        <Input
                          style={{background: "#F4F7FA"}}
                          placeholder="Enter Phone Number"
                          type="text"
                          name="phone"
                          value={employee.phone}
                          onChange={handleEmployeeInfo}
                          required
                        />
                    </FormField>
                </FormRow>

                <HorizontalSeparator />

                <FormField>
                    <FormLabel>*Have you been vaccinated?</FormLabel>
                    <ToggleSwitch id={employee._id} checked={employee.vaccinated} value={employee.vaccinated} label="" cb={toggleVaccinated} cbReturn="vaccinated" />
                </FormField>

                {employee.vaccinated ?
                    <>
                        <FormField>
                            <FormLabel>Vaccine Name*</FormLabel>
                            <Input type="select" name="vaccineName" id="vaccineName" style={{background: "rgb(244, 247, 250)"}} value={employee.vaccineName} onChange={handleEmployeeInfo} required> 
                                <option value="">Select Vaccine</option> 
                                {vaccines.map((vaccine, index) => <option key={index} value={vaccine}>{vaccine}</option>)}
                                <option value="other">Other</option> 
                            </Input>
                        </FormField>

                        {employee.vaccineName == "other" ?
                            <FormField>
                                <Input 
                                    style={{background: "#F4F7FA"}} 
                                    placeholder="Enter Vaccine Name"
                                    type="text"
                                    value={otherVaccine}
                                    onChange={(e) => setOtherVaccine(e.target.value)}
                                    required
                                />
                            </FormField>
                        : null}
                        <FormRow>
                            <FormField>
                                <FormLabel>First Jab Date*</FormLabel>
                                <Input 
                                    style={{background: "#F4F7FA"}} 
                                    type="date"
                                    name="jab1Date"
                                    value={employee.jab1Date ? employee.jab1Date.split('T')[0] : ''}
                                    onChange={handleEmployeeInfo}
                                    required
                                />
                            </FormField>

                            <FormField>
                                <FormLabel>Second Jab Date</FormLabel>
                                <Input 
                                    style={{background: "#F4F7FA"}}
                                    type="date"
                                    name="jab2Date"
                                    value={employee.jab2Date ? employee.jab2Date.split('T')[0] : ''}
                                    onChange={handleEmployeeInfo}
                                />
                            </FormField>
                        </FormRow>

                        <FormField>
                            <FormLabel>Picture of Vaccination Card*</FormLabel>
                            <FileUploader onFileSelectSuccess={handleImageUpload} onFileSelectError={({ error }) => alert(error)} required={true} />
                        </FormField>
                    </>
                : null}

                <Button size="md" color="success" style={{color: "white", margin: "6px"}} type="submit">Submit</Button>
                <FormLabel>* Indicates a Required Field</FormLabel>
              </FormDiv>

            </MasterDiv>
          </CenterItemsDiv>
        </>
      ) : (
        <>
          {employee.completed ? (
            <CenterItemsDiv>
              <HeaderText>Attention</HeaderText>
              <MasterDiv>
                <CompleteDiv>
                  <GreenCheck src={require('../../assets/greenCheck.png')} />
                  <LargeText>Survey Completed</LargeText>
                  <RegularText>Check your email or text messages for entry code</RegularText>
                </CompleteDiv>
              </MasterDiv>
            </CenterItemsDiv>
          ) : (
            <InvalidTokenDiv>
              <HeaderText>Token not valid</HeaderText>
            </InvalidTokenDiv>
          )}
        </>
      )}
      </PrescreenBackground>
  )
}

export default connect(null, {
  requestPWChange,
  prescreenDataInput,
  sendImageToS3,
})(EmployeeEntryForm)