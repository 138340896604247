import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "../addDevice/addDevice.scss";
// import axios from "../../axiosconfig";
// import { removeDevice } from "../../actions/userActions";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  InputGroup,
  Input,
} from "reactstrap";
import { requestPWChange } from "../../actions/userActions";
import useForm from '../addDevice/useForm'
import validateResetPwForm from "./validateResetPwForm";
const url = require("url");
// var validator = require("email-validator");

function ForgotPasswordForm(props) {
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const token = url.parse(props.location.search, true).query.token;

  const callReset = (pass, tok) => {
    props.requestPWChange(pass, tok);
    setTimeout(() => {
      props.history.push("/");
    }, 200);
  };

  const { handleChange, handleSubmit, values, errors } = useForm(callReset, validateResetPwForm);



  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   if (name === "password1") {
  //     setPass1(value);
  //   } else {
  //     setPass2(value);
  //   }
  // };

  

  // NOTE: SEE line 89.
  return (
    <div className="delModalOverlay">
      <Card className="bg-white">
        <CardHeader>
          <h3 className="mb-2">Password Reset</h3>
        </CardHeader>
        <CardBody>
          <FormGroup className="ml-5 mr-5">
            <Label className="font-weight-bold mt-3">*New Password</Label>
            <InputGroup className="input-group-merge input-group-alternative">
              <Input
                type="password"
                name="password1"
                // value={pass1}
                onChange={handleChange}
                autoFocus
              />
            </InputGroup>
            {errors.password1 && (
                            <p className="error">{errors.password1}</p>
                          )}
          </FormGroup>
          <FormGroup className="ml-5 mr-5">
            <Label className="font-weight-bold mt-3">
              *Repeat New Password
            </Label>
            <InputGroup className="input-group-merge input-group-alternative">
              <Input
                type="password"
                name="password2"
                // value={pass2}
                onChange={handleChange}
                
              />
            </InputGroup>
            {errors.password2 && (
                            <p className="error">{errors.password2}</p>
                          )}
          </FormGroup>
          <form>
            <Row>
              <Col md="12">
                <div className="text-center mt-3 buttons">
                  <Button
                    size="md"
                    color="success"
                    // onClick={() => {
                    //   if (pass1 === pass2) {
                    //     // NOTE: send the password to the unauth'd endpoint with the token passed through line 22.
                    //     // console.log("passwords DO match");
                    //     callReset(pass1, token);
                    //   } else {
                    //     alert("passwords must match");
                    //   }
                    // }}
                    onClick={handleSubmit}
                    style={{
                      // backgroundColor: "#EB3446",
                      color: "white",
                    }}
                    type="submit"

                  >
                    Submit
                  </Button>
                {errors.password3 && (
                            <p className="error">{errors.password3}</p>
                          )}
                </div>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    </div>
  );
}

export default connect(null, { requestPWChange })(ForgotPasswordForm);
