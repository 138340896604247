import React, { useState } from "react";
import "./createAdmin.scss";
// import { removeDevice } from "../../actions/userActions";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroup,
  Row,
  Col,
  Label,
} from "reactstrap";
// import { remove } from "../../../../models/deviceModel";

function NewZoneModal(props) {
  const [value, setValue] = useState("");
  const { setShowModal, submit } = props;
  const [addZoneErrors, setAddZoneErrors] = useState({});
let errors = {}

  const handleChange = (event) => {
    const { name, value } = event.target;
    // console.log(name, value);
    setValue(value);
  };

  function validateAddZoneForm(values) {
    console.log("inside validateAddZoneForm")
    console.log('values: ', values)

    if(!values) {
      errors.zoneName = "please enter a zone name"
    }
   

    return errors
  }

  return (
    <div className="delModalOverlayZone">
      <Card className="bg-white delModalZone">
        <CardHeader className="d-flex justify-content-between align-items-center">
          <h3 className="mb-0">Create New Zone</h3>
          <Button
            onClick={() => {
              console.log("X button clicked!");
              props.setShowModal(false);
            }}
          >
            X
          </Button>
        </CardHeader>
        <CardBody>
          <form>
            <Row>
              <Col md="12">
                {/* <h3 style={{ color: "darkgrey" }}>
                  {value}
                </h3> */}
                <FormGroup>
                  <Label className="font-weight-bold">*Name of New Zone</Label>
                  <InputGroup className="input-group-merge input-group-alternative">
                    <Input
                      type="text"
                      name="zoneName"
                      value={value}
                      onChange={handleChange}
                    />
                  </InputGroup>
                  {addZoneErrors.zoneName && (
                      <p className="error">{addZoneErrors.zoneName}</p>
                    )}
                </FormGroup>
                <div></div>
                <div className="text-right mt-3 buttons">
                  <Button
                    size="md"
                    color="light"
                    type="submit"
                    // className="saveButton"
                    // style={{
                    //   backgroundColor: "#2DCE89",
                    //   color: "white",
                    // }}
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="md"
                    color="primary"
                    onClick={() => {
                      setAddZoneErrors(validateAddZoneForm(value))
                      if(value){
                        console.log("zone value entered!")
                        submit({ zoneName: value });
                        setTimeout(() => {
                          setShowModal(false);
                        }, 150);
                      }

                    }}
                    // className="deleteButton"
                    // style={{
                    //   backgroundColor: "#EB3446",
                    //   color: "white",
                    // }}
                  >
                    Create
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    </div>
  );
}

export default NewZoneModal;
