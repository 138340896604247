import React, { useState, useRef, useEffect } from "react";
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroup,
  Row,
  Container,
  Col,
  Table,
  CardFooter,
  Label,
  CustomInput,
  Form,
} from "reactstrap";
import { registerPrescreen } from "../../actions/userActions";
import validatePreRegister from "./validatePreRegister";
import useFormPreRegistration from "./useFormPreRegistration";
import "./PreRegister.scss";
import { InputFilled } from "../ManualOnboarding/InputFilled";

function PreRegisterFormModal(props) {
  const { togglePreRegisterModal } = props;
  const [commType, setCommType] = useState("email");
  const [isActive, setActive] = useState(false);

  function toggleComm(e) {
    //console.log("event:", e);
    e.preventDefault();
    setActive(!isActive);
    let val = { ...values, email: "", sms: "" };
    if (commType === "email") {
      val = { ...val, commType: "sms" };
      setCommType("sms");
    } else {
      val = { ...val, commType: "email" };
      setCommType("email");
    }
    setValues(val);
  }

  async function submit() {
    console.log(" do the submit ");
    let number = values.sms.replace("(", "").replace(")", "").replace("-", "");
    let daysValidMS = values.daysValid * 24 * 60 * 60 * 1000
    if (number.length === 10) {
      number = "1" + number;
      console.log("changing number:", number);
    }
    console.log("submitting:", number);
    let res = await props.registerPrescreen(
      { email: values.email, sms: number, daysValid: daysValidMS },
      props.token
    );
    if (res) {
      toast.success("Message sent!");
      setValues({ email: "", sms: "", commType: "email" });
      togglePreRegisterModal();
    } else {
      toast.error("Failed sending message!");
    }
  }

  const {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
    setErrors,
  } = useFormPreRegistration(submit, validatePreRegister);

  const backToDashboard = () => {
    props.history.push("/dashboard");
  };

  return (
    <Col md="12">
      <div className="card-wrapper whiteModalOverlay">
        <Card className="bg-white" style={{width: '480px', overflow: 'auto', paddingBottom: '16px'}}>
          <CardHeader className="border-0 d-flex pb-0"  style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
            <h3 className="mb-0 font-weight-400">Pre-register entry</h3>
            <div
              style={{
                cursor: 'pointer',
                lineHeight: '25.5px',
                fontSize: '24px',
              }}
              onClick={togglePreRegisterModal}
            >𐄂</div>
          </CardHeader>
          <CardBody className="pt-4">
            <Col md="12">
              <Form onSubmit={handleSubmit}>
                <Row>
                  <div className="btn-group">
                    <button
                      className={isActive ? "btn-email" : "btn-active"}
                      onClick={toggleComm}
                    >
                      By e-mail
                    </button>
                    <button
                      className={isActive ? "btn-active" : "btn-sms"}
                      onClick={toggleComm}
                    >
                      By SMS
                    </button>
                  </div>
                </Row>
                <div className="pt-4">
                  <FormGroup>
                    <div className="row">
                      <FormGroup className="col">
                        <InputFilled
                          className="InputFilledFullWidth"
                          label={`${commType === "email" ? "E-mail" : "Phone number"}*`}
                          autoFocus
                          placeholder={`${commType === "email" ? "Enter e-mail address" : "Enter phone number"}*`}
                          value={commType === "email" ? values.email : values.sms}
                          required
                          name={commType}
                          onChange={handleChange}
                        />
                      </FormGroup>
                      <FormGroup className="pr-3">
                        <InputFilled
                          className="InputFilled"
                          label="Days valid"
                          value={values.daysValid}
                          type="number"
                          name="daysValid"
                          min="0"
                          max="999"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </div>
                    {errors.email && <p className="error">{errors.email}</p>}
                    {errors.sms && <p className="error">{errors.sms}</p>}
                    <Row>
                      <Container className="text-right bg-white">
                        <Button color="secondary" size="md" onClick={togglePreRegisterModal}>Cancel</Button>
                        <Button color="primary" size="md" type="submit">Send</Button>
                      </Container>
                    </Row>
                  </FormGroup>
                </div>
              </Form>
            </Col>
          </CardBody>
        </Card>
      </div>
    </Col>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, { registerPrescreen })(
  PreRegisterFormModal
);
