import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ConfirmDeleteStepModal = ({ isOpen, toggle, onDelete, label }) => (
  <Modal isOpen={!!isOpen} toggle={toggle} centered className="ConfirmDeleteStepModal">
    <ModalHeader toggle={toggle}>Delete step</ModalHeader>
    <ModalBody className="ConfirmDeleteStepModal-content">
      <span>{`Are you sure you want to delete ${label} step?`}</span>
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={toggle}>Cancel</Button>
      <Button color="danger" onClick={onDelete}>Delete</Button>
    </ModalFooter>
  </Modal>
);

export default ConfirmDeleteStepModal;
