import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Node } from 'slate';
import { SlateEditor } from './SlateEditor';

export const LANG_CODE_TO_LABEL = {
  'ru': 'Russian',
};

const initialValue = [
  {
    type: 'paragraph',
    children: [
      { text: 'This is editable ' },
      { text: 'rich', bold: true },
      { text: ' text, ' },
      { text: 'much', italic: true },
      { text: ' better than a ' },
      { text: 'text' },
      { text: '!' },
    ],
  },
  {
    type: 'paragraph',
    children: [
      {
        text:
          "Since it's rich text, you can do things like turn a selection of text ",
      },
      { text: 'bold', bold: true },
      {
        text:
          ', or add a semantically rendered block quote in the middle of the page, like this:',
      },
    ],
  },
  {
    type: 'image',
    url: 'https://source.unsplash.com/kFrdX5IeQzI',
    children: [{ text: '' }],
  },
  {
    type: 'paragraph',
    children: [{ text: 'Try it out for yourself!' }],
  },
];


const MessageModal = ({ isOpen, toggle, onAddMessage, defaultValues }) => {
  const [message, setMessage] = useState(initialValue);
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    if (defaultValues?.message) setMessage(defaultValues?.message);
    if (defaultValues?.title) setTitle(defaultValues?.title);
    if (defaultValues?.subtitle) setSubtitle(defaultValues?.subtitle);
    if (defaultValues?.translations) setTranslations(defaultValues?.translations || {});
  }, [isOpen]);

  const onAdd = () => {
    onAddMessage({
      message,
      title,
      subtitle,
      previewMessage: message.map(node => Node.string(node)).join('\n'),
      translations,
    });
  };

  const addLang = (lang) => {
    setTranslations({
      ...translations,
      [lang]: {
        message: initialValue,
        title: '',
        subtitle: '',
      },
    })
  };

  const updateTranslation = (lang, key, value) => {
    setTranslations({
      ...translations,
      [lang]: {
        ...translations[lang],
        [key]: value,
      },
    })
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="MessageModal">
      <ModalHeader toggle={toggle}>Add message</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="question">Page title</Label>
            <Input name="title" id="title" placeholder="Enter title" value={title} onChange={e => setTitle(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <Label for="question">Page subtitle</Label>
            <Input name="subtitle" id="subtitle" placeholder="Enter subtitle" value={subtitle} onChange={e => setSubtitle(e.target.value)} />
          </FormGroup>
          <FormGroup>
            <SlateEditor
              message={message}
              setMessage={setMessage}
              placeholder="Write down a message for the visitor"
            />
          </FormGroup>
          {Object.entries(translations).map(([lang, { message, title, subtitle }]) => (
            <div className="MessageModal-translationBlock">
              <h2>{LANG_CODE_TO_LABEL[lang]}</h2>
              <FormGroup>
                <Label for="question">Page title</Label>
                <Input name="title" id="title" placeholder="Enter title" value={title} onChange={e => updateTranslation(lang, 'title', e.target.value)} />
              </FormGroup>
              <FormGroup>
                <Label for="question">Page subtitle</Label>
                <Input name="subtitle" id="subtitle" placeholder="Enter subtitle" value={subtitle} onChange={e => updateTranslation(lang, 'subtitle', e.target.value)} />
              </FormGroup>
              <FormGroup>
                <SlateEditor
                  message={message}
                  setMessage={(val) => updateTranslation(lang, 'message', val)}
                  placeholder="Write down a message for the visitor"
                />
              </FormGroup>
            </div>
          ))}
          {Object.entries(LANG_CODE_TO_LABEL).filter(([lang]) => !Object.keys(translations).includes(lang)).map(([lang, label]) => (
            <Button color="secondary" onClick={() => addLang(lang)}>Add version in {label}</Button>
          ))}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
        <Button color="primary" onClick={onAdd}>Add</Button>
      </ModalFooter>
    </Modal>
  );
};

export default MessageModal;
