import * as actions from "./types";
import axios from "../axiosconfig";
import Axios from "axios";
// import { InstalledAddOnExtensionList } from "twilio/lib/rest/preview/marketplace/installedAddOn/installedAddOnExtension";

export const networkUpdate = (data) => {
  localStorage.setItem("admin", JSON.stringify(data));
  return {
    type: actions.UPDATE_ADMIN_SETTINGS,
    payload: data,
  };
};

export const advanceProgress = (step) => {
  return {
    type: actions.USER_STEP,
    payload: step,
  };
};

export const statusMessageUpdate = (message) => {
  return {
    type: actions.STATUS_MESSAGE,
    payload: message,
  };
};

export const submitInfo = (info) => async (dispatch) => {
  try {
    const response = await axios({
      url: "/submitInfo",
      method: "POST",
      data: { info: info },
    });
    console.log(response.data);
    if (response.data === "Submitted") {
      this.props.history.push("/completed");
    }
  } catch (e) {
    console.log("error");
  }
};

export const updateIdInfo = (image) => async (dispatch) => {
  try {
    const response = await axios({
      url: "/detectText",
      method: "POST",
      data: { image: image },
    });
    dispatch({ type: actions.ID_INFO, payload: response.data });
    dispatch({ type: actions.USER_STEP, payload: "idInfoReceived" });
    dispatch({
      type: actions.STATUS_MESSAGE,
      payload: "Please fix incorrect information and submit.",
    });
  } catch (e) {
    console.log("error");
  }
};

export const clearInfo = () => (dispatch) => {
  dispatch({ type: actions.CLEAR_INFO });
};

export const defineUser = (user, sessionId) => (dispatch) => {
  dispatch({
    type: actions.DEFINE_USER,
    payload: user,
  });
  dispatch({
    type: actions.CREATE_SESSION_ID,
    payload: sessionId,
  });
};

export const createUser = (sessionId) => async (dispatch) => {
  try {
    const response = await axios({
      url: "/createUser",
      method: "POST",
      data: { sessionId: sessionId },
    });
    console.log(response.data);
  } catch (e) {
    console.log("error");
  }
};

export const findUsers = (token) => async (dispatch) => {
  try {
    const response = await axios({
      url: "/findUsers",
      method: "GET",
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({
      type: actions.SET_USERS,
      payload: response.data,
    });
    return "success";
  } catch (e) {
    console.log("error", e);
  }
};

export const addZone = (zone, token) => async (dispatch) => {
  try {
    const response = await axios({
      url: "/admin/add-zone",
      method: "POST",
      headers: { authorization: `Bearer ${token}` },
      data: zone,
    });
    if (response.data) {
      dispatch({ type: actions.SET_ZONES, payload: response.data });
    }
  } catch (e) {
    console.log("error: " + JSON.stringify(e));
  }
};

export const editZones = (zones, token) => async (dispatch) => {
  try {
    const response = await Axios.all(
      zones.map((zone) => {
        return axios({
          url: "/admin/editZone",
          method: "POST",
          headers: { authorization: `Bearer ${token}` },
          data: zone,
        });
      })
    )
      .then(Axios.spread((...resps) => resps))
      .catch((err) => err);
    return response;
  } catch (e) {
    return e;
  }
};

export const findZones = () => async (dispatch, getState) => {
  try {
    const token = getState().token;
    const response = await axios({
      url: "/admin/findZones",
      method: "GET",
      headers: { authorization: `Bearer ${token}` },
    });
    if (response.data) {
      dispatch({
        type: actions.SET_ZONES,
        payload: response.data,
      });
    } else {
      console.log("error");
    }
  } catch (e) {
    console.log("error", e);
  }
};

export const removeZone = (zone, token) => async (dispatch) => {
  console.log("remove-zone: " + JSON.stringify(zone));
  try {
    const response = await axios({
      url: "/admin/remove-zone",
      method: "POST",
      headers: { authorization: `Bearer ${token}` },
      data: {
        zoneName: zone.zoneName,
      },
    });
    if (response.data) {
      console.log("remove zone response: " + JSON.stringify(response.data));
      await findDevices(token); // update the devices
      await findUsers(token); // update the admins
      dispatch({ type: actions.SET_ZONES, payload: response.data });
    }
  } catch (e) {
    console.log("error: " + JSON.stringify(e));
  }
};

export const cancelSession = (sessionId, imageTaken) => async (dispatch) => {
  try {
    const response = await axios({
      url: "/cancelSession",
      method: "POST",
      data: {
        sessionId: sessionId,
        imageTaken: imageTaken,
      },
    });
    console.log(response);
    // dispatch ({
    //     type: actions.SET_USERS,
    //     payload: response.data
    // })
  } catch (e) {
    console.log("Cancel error");
  }
};

export const onConnect = (socket) => (dispatch) => {
  dispatch({ type: actions.ON_CONNECT_SOCKET, payload: socket });
};

export const onLogin = (user) => async (dispatch) => {
  // send username and password to server
  // add jwt to payload

  try {
    const response = await axios({
      url: "/admin",
      method: "POST",
      data: { username: user.username, password: user.password },
    });
    if (response.data.token != null) {
      //console.log("login token: " + response.data.token);
      //console.log("login returned: " + response.data.admin);
      //console.log("storing admin type: " + typeof response.data.admin);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("admin", response.data.admin);
      dispatch({
        type: actions.LOGIN_USER,
        payload: {
          token: response.data.token,
          admin: JSON.parse(response.data.admin),
        },
      });
    } else {
      //console.log("loginfail:", response);
      dispatch({ type: actions.LOGIN_FAIL });
      // return response.data.message;
      return "the user could not be found.";
    }
  } catch (e) {
    console.log("error invalid password");
    dispatch({ type: actions.LOGIN_FAIL });
    return "the password you entered is invalid.";
  }
};

export const autoLogin = () => async (dispatch) => {
  var token = localStorage.getItem("token");
  var admin = localStorage.getItem("admin");
  // console.log("auto-login token: " + token);
  //console.log("auto-login user: " + admin);
  try {
    if (token) {
      dispatch({
        type: actions.LOGIN_USER,
        payload: { token: token, admin: JSON.parse(admin) },
      });
    }
  } catch (e) {
    console.log("error", e);
    dispatch({ type: actions.LOGIN_FAIL });
  }
};

export const onLogout = () => async (dispatch) => {
  var token = localStorage.removeItem("token");
  var admin = localStorage.removeItem("admin");
  try {
    if (!token && !admin) {
      dispatch({ type: actions.LOGOUT_SUCCESS });
    }
  } catch (e) {
    console.log("error");
  }
};

export const passwordReset = (email) => async (dispatch) => {
  console.log("email reset: " + email);
  try {
    const response = await axios({
      url: "/admin/password-reset",
      method: "POST",
      data: {
        email: email,
      },
    });
  } catch (e) {
    console.log("error" + e);
  }
};

export const addDevice = (device, token) => async (dispatch) => {
  try {
    const response = await axios({
      url: "/admin/add-device",
      method: "POST",
      headers: { authorization: `Bearer ${token}` },
      data: {
        deviceName: device.deviceName,
        password: device.password,
        zoneName: device.zoneName,
        thermal: device.thermal,
      },
    });
    if (response.data) {
      dispatch({ type: actions.ADD_DEVICE, payload: response.data });
    }
  } catch (e) {
    console.log("error");
    dispatch({ type: actions.ADD_DEVICE_FAIL });
  }
};

export const findDevices = (token) => async (dispatch) => {
  try {
    const response = await axios({
      url: "/admin/devices",
      method: "GET",
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({
      type: actions.GET_DEVICES,
      payload: response.data,
    });
  } catch (e) {
    console.log("error", e);
  }
};

export const updateDevice = (device, token) => async (dispatch) => {
  //this should send the new console settings to be updated in the reducer.
  // console.log("rig me up please!!!");
  try {
    const response = await axios({
      url: "/admin/update-device",
      method: "POST",
      headers: { authorization: `Bearer ${token}` },
      data: {
        deviceName: device.deviceName,
        thermal: device.thermal,
        zoneName: device.zoneName,
        qrTitle: device.qrTitle || "",
        qrURL: device.qrURL || "",
        qrSignin: device.qrSignin || false,
        daysValid: device.daysValid || 1,
      },
    });
    if (response.data) {
      // console.log("Action response: " + JSON.stringify(response.data));
      // console.log("SEND THE RESPONSE FOR EDITING TO THE REDUCER!!!!");
      dispatch({ type: actions.EDIT_DEVICE, payload: response.data });
    }
  } catch (e) {
    // console.log("error: " + JSON.stringify(e));
    // console.log("add EDIT_DEVICE_FAILED to reducer!!");
    dispatch({ type: actions.EDIT_DEVICE_FAIL, payload: e.data });
  }
};

export const changeDevicePassword = (device, newPassword, token) => async (
  dispatch
) => {
  try {
    const response = await axios({
      url: "/admin/change-device-password",
      method: "POST",
      headers: { authorization: `Bearer ${token}` },
      data: {
        deviceName: device.deviceName,
        password: newPassword,
      },
    });
  } catch (e) {}
};

export const removeDevice = (device, token) => async (dispatch) => {
  console.log("remove-device: " + JSON.stringify(device));
  try {
    const response = await axios({
      url: "/admin/remove-device",
      method: "POST",
      headers: { authorization: `Bearer ${token}` },
      data: {
        deviceName: device.deviceName,
      },
    });
    if (response.data) {
      console.log("remove response: " + JSON.stringify(response.data));
      dispatch({ type: actions.EDIT_DEVICE, payload: response.data });
    }
  } catch (e) {
    console.log("error: " + JSON.stringify(e));
  }
};

export const createAdmin = (admin, token) => async (dispatch) => {
  try {
    const response = await axios({
      url: "/admin/create-admin",
      method: "POST",
      headers: { authorization: `Bearer ${token}` },
      data: {
        ...admin,
        username: admin.username,
        password: admin.password,
        adminCode: admin.adminCode,
      },
    });
    if (response.data) {
      dispatch({ type: actions.CREATE_ADMIN, payload: response.data });
    }
  } catch (e) {
    console.log("error");
  }
};

export const findAdmins = (token) => async (dispatch) => {
  try {
    const response = await axios({
      url: "/admin/all-admin",
      method: "GET",
      headers: { authorization: `Bearer ${token}` },
    });
    console.log("findAdmins: " + response.data);
    dispatch({
      type: actions.GET_ADMINS,
      payload: response.data,
    });
  } catch (e) {
    console.log("error", e);
  }
};

export const updateAdmin = (admin, token) => async (dispatch) => {
  try {
    const response = await axios({
      url: "/admin/update-admin",
      method: "POST",
      headers: { authorization: `Bearer ${token}` },
      data: {
        ...admin,
      },
    });
    if (response.data) {
      console.log("Action response: " + JSON.stringify(response.data));
      dispatch({ type: actions.EDIT_ADMIN, payload: response.data });
      // setTimeout(() => {
      //   dispatch({ type: actions.EDIT_ADMIN, payload: response.data });
      // }, 15000);
    }
  } catch (e) {
    console.log("error: " + JSON.stringify(e));
    // console.log("add EDIT_DEVICE_FAILED to reducer!!");
    setTimeout(() => {
      dispatch({ type: actions.EDIT_ADMIN_FAIL, payload: e.data });
    }, 15000);
  }
};

export const removeAdmin = (admin, token) => async (dispatch) => {
  console.log("user action called");
  try {
    const response = await axios({
      url: "/admin/remove-admin",
      method: "POST",
      headers: { authorization: `Bearer ${token}` },
      data: {
        ...admin,
      },
    });
    if (response.data) {
      console.log("success:", JSON.stringify(response.data));
      dispatch({ type: actions.EDIT_ADMIN, payload: response.data });
      return response.data;
    } else {
      console.log("no response from server");
    }
  } catch (e) {
    console.log("error: " + JSON.stringify(e));
  }
};

export const requestPWChange = (pass, tok) => async (dispatch) => {
  console.log("P:", pass, "T:", tok);

  try {
    const response = await axios({
      url: "/admin/requestPWReset",
      method: "POST",
      data: { token: tok, pw: pass },
    });
    if (response.data.token != null) {
      console.log("response: " + JSON.stringify(response.data));
      dispatch({
        type: actions.LOGIN_USER,
        payload: {
          token: response.data.token,
          admin: JSON.parse(response.data.admin),
        },
      });
    }
  } catch (e) {
    console.log(JSON.stringify(e));
  }
};

export const registerPrescreen = (data, tok) => async (dispatch) => {
  console.log("Email:", data.email, "sms:", data.sms, "token:", tok);
  try {
    const response = await axios({
      url: "/admin/create-prescreen",
      method: "POST",
      headers: { authorization: `Bearer ${tok}` },
      data: data,
    });
    if (response) {
      console.log("response:", response.data);
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log(JSON.stringify(e));
    return false;
  }
};

export const QRPrescreenDataInput = (data) => async (dispatch) => {
  console.log("QR prescreen inputting data:", data);
  try {
    const response = await axios({
      url: "/createUser",
      method: "POST",
      data: data,
    });
    if (response.data) {
      console.log("response", response.data);
    } else {
      console.log("failed:" + response);
    }
  } catch (e) {
    console.log(JSON.stringify(e));
  }
};

export const prescreenDataInput = (data) => async (dispatch) => {
  console.log("prescreen inputting data:", data);
  try {
    const response = await axios({
      url: "/admin/prescreen-update",
      method: "POST",
      data: data,
    });
    if (response.data) {
      console.log("response", response.data);
    } else {
      console.log("failed:" + response);
    }
  } catch (e) {
    console.log(JSON.stringify(e));
  }
};

export const qrSigninDataInput = (data) => async (dispatch) => {
  console.log("qrsignin inputting data:", data);
  try {
    const response = await axios({
      url: "/admin/create-qrsignin",
      method: "POST",
      data: data,
    });
    if (response.data) {
      console.log("response", response.data);
    } else {
      console.log("failed:" + response);
    }
  } catch (e) {
    console.log(JSON.stringify(e));
  }
};

export const findPrescreens = (token) => async (dispatch) => {
  // console.log("find prescreen token: " + token);
  try {
    const response = await axios({
      url: "/admin/findPrescreens",
      method: "GET",
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({
      type: actions.SET_PRESCREENS,
      payload: response.data,
    });
  } catch (e) {
    console.log("error", e);
  }
};

export const passFlagged = (flagged, token) => async (dispatch) => {
  console.log("pass flagged", flagged);
  try {
    const response = await axios({
      url: "/admin/passFlagged",
      method: "POST",
      headers: { authorization: `Bearer ${token}` },
      data: flagged,
    });
    if (response.data) {
      console.log("response:", response.data);
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log("error:", e);
    return false;
  }
};

export const rejectFlagged = (flagged, token) => async (dispatch) => {
  console.log("reject flagged:", flagged);
  try {
    const response = await axios({
      url: "/admin/rejectFlagged",
      method: "POST",
      headers: { authorization: `Bearer ${token}` },
      data: flagged,
    });
    if (response.data) {
      console.log("response:", response.data);
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log("error:", e);
    return false;
  }
};

export const toggleUserArchive = (user, token) => async (dispatch) => {
  const updated = await axios({
    url: "/toggleUserArchive",
    method: "POST",
    headers: { authorization: `Bearer ${token}` },
    data: user,
  });
  try {
    const response = await axios({
      url: "/findUsers",
      method: "GET",
      headers: { authorization: `Bearer ${token}` },
    });
    dispatch({
      type: actions.SET_USERS,
      payload: response.data,
    });
  } catch (e) {
    console.log("error", e);
  }
  return await new Promise((resolve) => resolve(updated));
};
