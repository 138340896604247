import React from "react";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Row,
  Alert,
  Container,
  Label,
} from "reactstrap";
import { onLogin, passwordReset } from "../../actions/userActions";
import AuthHeader from "../AuthHeader/AuthHeader";
import "./login.scss";
import validateLogin from "./validateLogin";
//import LoginFailAlert from "./LoginFailAlert";
import useForm from "./useFormStart";
import ForgotPasswordForm from "./ForgotPasswordForm";

function Login(props) {
  const [user, setUser] = useState({});
  const [showPWReset, setShowPWReset] = useState(false);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [loginAlertText, setAlertText] = useState("");

  const handleLoginClick = async () => {
    // console.log("login clicked:", props);
    let res = await props.onLogin({
      username: values.username,
      password: values.uPassword,
    });
    // console.log("response from action", res);
    if (res) {
      setAlertText(res);
      setShowLoginAlert(true);
      // alert(res);
      setValues({ ...values, uPassword: "" });
    }
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setValues,
    setErrors,
  } = useForm(handleLoginClick, validateLogin);

  useEffect(() => {
    if (props.isAuthenticated) {
      props.history.push("/dashboard");
      //console.log(values);
    }
  }, [props.isAuthenticated, errors, values]);

  return (
    <>
      <div className="bg-white" style={{height: "100vh"}}>
        <AuthHeader
          title="Welcome"
          lead="Please login using your credentials"
          className="bg-white"
        />
        <div className="login-container bg-white">
          {showPWReset ? (
            <ForgotPasswordForm
              // email={props.email}
              passwordReset={props.passwordReset}
              setShowPWReset={setShowPWReset}
              values={values}
              setValues={setValues}
              errors={errors}
              setErrors={setErrors}
            />
          ) : null}
          <Row className="justify-content-center bg-white">
            <Card className="bg-white login-card">
              <CardBody className="px-lg-5 py-lg-7">
                {/* <div className="text-center text-muted mb-4">
                <small>Sign in with credentials</small>
              </div> */}
                <Form role="form">
                  <FormGroup>
                    <Label className="ml-2">Admin User</Label>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <Input
                        placeholder="Username"
                        type="text"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                        autoFocus
                      />
                    </InputGroup>
                    {errors.username && (
                      <p className="error">{errors.username}</p>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label className="ml-2">Password</Label>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <Input
                        placeholder="Password"
                        type="password"
                        name="uPassword"
                        value={values.uPassword}
                        onChange={handleChange}
                      />
                    </InputGroup>
                    {errors.password && (
                      <p className="error">{errors.password}</p>
                    )}
                  </FormGroup>
                  <Row>
                    <Button
                      onClick={() => {
                        //props.history.push("/forgotPassword");
                        setShowPWReset(true);
                      }}
                      className="text-primary ml-3"
                      color="link"
                      style={{
                        float: "right",
                        color: "slategrey",
                        margin: 8,
                      }}
                    >
                      Did you forget your password?
                    </Button>
                  </Row>
                  <hr className="mx-3 my-4" />
                  <Row className="align-items-center">
                    {/* <div className="text-center"> */}
                    <Button
                      className="bg-primary text-center mx-3 flex-fill"
                      color="light"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Sign in
                    </Button>
                    {/* </div> */}
                  </Row>
                  <Row className="align-items-center">
                    {showLoginAlert ? (
                      // <LoginFailAlert callback={setShowLoginAlert} />
                      // <Container className="mt-4 bg-white">
                      <Alert
                        color="danger"
                        onClose={() => setShowLoginAlert(false)}
                        toggle={() => setShowLoginAlert(false)}
                        fade
                        className="mt-4 shadow-lg flex-fill"
                      >
                        <p>Login failed because {loginAlertText}</p>
                      </Alert>
                    ) : // </Container>
                    null}
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Row>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    isAuthenticated: state.isAuthenticated,
    isAdmin: state.isAdmin,
    loginMessage: state.loginMessage,
  };
};

export default connect(mapStateToProps, { onLogin, passwordReset })(Login);
