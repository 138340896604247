import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, CustomInput, FormGroup, Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { getLanguages, updateLanguages } from "../../actions/languagesConfiguration";

const LANGUAGES = [{
  key: 'en',
  label: 'English',
}, {
  key: 'ru',
  label: 'Русский',
}];

export const Languages = ({ currentZone }) => {
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const dispatch = useDispatch();
  const { isLoadingLanguagesConfigList, languagesConfigList, languagesConfigListError } = useSelector(state => state);

  useEffect(() => {
    dispatch(getLanguages(currentZone));
  }, [currentZone]);

  useEffect(() => {
    if (languagesConfigList) {
      setSelectedLanguages(languagesConfigList || []);
    }
  }, [languagesConfigList]);

  const onChangeLanguages = (languageKey) => {
    if (selectedLanguages.includes(languageKey)) {
      setSelectedLanguages(selectedLanguages.filter(key => key !== languageKey));
    } else {
      setSelectedLanguages([...selectedLanguages, languageKey]);
    }
  };

  const onSave = () => {
    if (selectedLanguages < 1) {
      return toast.error("At least one language should be selected", {
        position: "bottom-center",
        icon: false,
      });
    } else {
      dispatch(updateLanguages({ zoneName: currentZone, languages: selectedLanguages }));
    }
  };

  if (isLoadingLanguagesConfigList) return (
    <div style={{ textAlign: 'center' }}><Spinner color='primary' style={{ width: '3rem', height: '3rem' }} /></div>
  );

  return (languagesConfigListError ? (
    <p className="ConfigureSteps-error">
      Error while fetching list: {languagesConfigListError}. Try to Reload the page
    </p>
    ) : (
      <div className="LanguagesList">
        <h1>Select zone languages</h1>
        <div className="LanguagesList-wrapper">
          {LANGUAGES.map((lang) => (
            <FormGroup check>
              <CustomInput
                onChange={() => onChangeLanguages(lang.key)}
                checked={!!selectedLanguages.includes(lang.key)}
                type="switch"
                id={`lang-${lang.key}`}
                name={`lang-${lang.key}`}
                label={lang.label}
              />
            </FormGroup>
          ))}
        </div>
        <Button color="primary" onClick={onSave}>Save</Button>
      </div>
    )
  );
};
