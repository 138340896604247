import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";

const TempModal = ({ isOpen, toggle, onAddTempCheck, defaultValues }) => {
  const [min, setMin] = useState(defaultValues?.min || '90');
  const [max, setMax] = useState(defaultValues?.max || '100');

  useEffect(() => {
    if (defaultValues?.min) setMin(defaultValues?.min);
    if (defaultValues?.max) setMax(defaultValues?.max);
  }, [isOpen, defaultValues]);

  const onAdd = () => {
    onAddTempCheck({
      min,
      max,
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="QuestionModal">
      <ModalHeader toggle={toggle}>Temp check</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="question">Enter threshold</Label>
            <Row form style={{ alignItems: 'baseline', justifyContent: 'space-between' }}>
              <Col md={5}>
                <FormGroup>
                  <Input type="number" name="min" id="min" placeholder="Enter minimum" value={min} onChange={e => setMin(e.target.value)} />
                </FormGroup>
              </Col>
              <Col md={2} className='text-center'>
                to
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Input type="number" name="max" id="max" placeholder="Enter maximum" value={max} onChange={e => setMax(e.target.value)} />
                </FormGroup>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
        <Button color="primary" onClick={onAdd}>Add</Button>
      </ModalFooter>
    </Modal>
  );
};

export default TempModal;
