import React  from 'react'
import Loader from 'react-loader-spinner'
import './Security.scss'

function LoadingIndicator() {

    return (
        <div className='loader'>
            <Loader type='ThreeDots' color='#2BAD60' height='100' width='100'/>
        </div>
    )
}

export default LoadingIndicator