export default function validateAddDevice(values) {
  let errors = {};

  if (!values.deviceName) {
    errors.deviceName = "Device name is required";
  } else if (values.deviceName.length > 0 && values.deviceName.replaceAll(" ","").length === 0) {
    errors.deviceName = "Name must contain characters other than spaces";
  }

  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 5) {
    errors.password = "Password must be at least 6 characters long";
  } else if (values.password.includes(' ')) {
    errors.password = "Passwords cannot include blank spaces"
  }
  

  return errors;
}
