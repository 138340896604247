import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";

const fieldsMap = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Date of Birth', key: 'dob' },
  { label: 'Document number', key: 'documentNumber' },
  { label: 'Email', key: 'email' },
  { label: 'Organization', key: 'org' },
  { label: 'Visiting Contact Name', key: 'visitingName' },
];

const FormModal = ({ isOpen, toggle, onAddForm, defaultValues }) => {
  const [storableFields, setStorableFields] = useState([]);
  const [requiredFields, setRequiredFields] = useState([]);

  useEffect(() => {
    if (defaultValues?.fields) {
      setStorableFields(defaultValues.fields.filter(field => field.storable).map(field => field.key));
      setRequiredFields(defaultValues.fields.filter(field => field.required).map(field => field.key));
    }
  }, [isOpen, defaultValues]);

  const onChangeStorable = (fieldName) => {
    if (storableFields.includes(fieldName)) {
      setStorableFields(storableFields.filter(field => field !== fieldName));
    } else {
      setStorableFields([...storableFields, fieldName]);
    }
  };

  const onChangeRequired = (fieldName) => {
    if (requiredFields.includes(fieldName)) {
      setRequiredFields(requiredFields.filter(field => field !== fieldName));
    } else {
      setRequiredFields([...requiredFields, fieldName]);
    }
  };

  const onAdd = () => {
    onAddForm({
      fields: fieldsMap.map(field => ({
        key: field.key,
        storable: storableFields.includes(field.key),
        required: requiredFields.includes(field.key),
      })),
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="QuestionModal">
      <ModalHeader toggle={toggle}>Form</ModalHeader>
      <ModalBody>
        <Form>
          {fieldsMap.map(field => (
            <FormGroup key={field.key}>
              <Label for={field.key}>{field.label}</Label>
              <Row form style={{ alignItems: 'baseline', justifyContent: 'space-between' }}>
                <Col md={5}>
                  <FormGroup check>
                    <CustomInput onChange={() => onChangeStorable(field.key)} checked={!!storableFields.includes(field.key)} type="switch" id={`storable-${field.key}`} name={`storable-${field.key}`} label="Storable" />
                  </FormGroup>
                </Col>
                <Col md={5}>
                  <FormGroup check>
                    <CustomInput onChange={() => onChangeRequired(field.key)} checked={!!requiredFields.includes(field.key)} type="switch" id={`required-${field.key}`} name={`required-${field.key}`} label="Required" />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
          ))}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
        <Button color="primary" onClick={onAdd}>Add</Button>
      </ModalFooter>
    </Modal>
  );
};

export default FormModal;
