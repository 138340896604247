import React from 'react';
import { ReactComponent as SearchIcon } from '../../assets/tabs/search.svg';

export const Search = ({ value, onChangeText }) => {
  return (
    <div className="Search">
      <SearchIcon />
      <input value={value} onChange={(e) => onChangeText(e.target.value)} placeholder="Search..." />
    </div>
  );
};
