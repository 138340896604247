import React, { useState, useEffect, useRef } from "react";
import axios from "../../axiosconfig";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroup,
  Row,
  Container,
  Col,
  Label,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  updateDevice,
  addDevice,
  removeDevice,
  changeDevicePassword,
} from "../../actions/userActions";
import SimpleHeader from "../SimpleHeader/SimpleHeader";
import PaginationTable from "../VisitorLog/TablePagination";
import useForm from "./useForm";
import validateAddDevice from "./validateAddDevice";
import "./addDevice.scss";
import EditDeviceModal from "./EditDeviceModal";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

import useClickOutsideListenerRef from "../CreateAdmin/useClickOutsideListenerRef";
import { sortArrByColumn } from "../../utils";
import { Table } from "../Table/Table";
import PermissionWrapper from "../permissions/PermissionWrapper";
import { SCOPES } from "../permissions/permission-map";

const mapColumnToDataName = {
  name: "deviceName",
  zone: "zoneName",
  thermal: "thermal",
};

function AddDevice(props) {
  const { handleChange, handleSubmit, values, setValues, errors } = useForm(
    submit,
    validateAddDevice
  );

  const [adminDevices, setAdminDevices] = useState(props.devices);
  const [device, setDevice] = useState({});
  const [zones, setZones] = useState(props.zones);
  const [currentPage, setCurrentPage] = useState(1);
  const [devicesPerPage] = useState(5);
  const [deviceToEdit, setDeviceToEdit] = useState({});
  const [deviceToDelete, setDeviceToDelete] = useState({});
  const [zoneDropDownOpen, setZoneDropdown] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddDevModal, setShowAddDevModal] = useState(false);

  const [sort, setSort] = useState({
    col: null,
    desc: false,
  });

  const toggleZone = () => setZoneDropdown((prevState) => !prevState);
  function submit(devi) {
    if (isDeviceNameTaken(devi)) {
      return;
    }
    if (devi) {
      props.addDevice(devi, props.token);
      setValues({
        ...values,
        deviceName: "",
        password: "",
        zoneName: "Unassigned",
        thermal: true,
      });
      alert(`added ${values.deviceName}`);
      setTimeout(() => {
        getDevices();
        setShowAddDevModal(false);
      }, 250);
    } else {
      alert("no device to add");
    }
  }

  function isDeviceNameTaken(devi) {
    for (let i = 0; i < adminDevices.length; i++) {
      if (adminDevices[i].deviceName === devi.deviceName) {
        return true;
      } else {
      }
    }
    return false;
  }

  const getDevices = async () => {
    try {
      const response = await axios({
        url: "admin/devices",
        method: "GET",
        headers: { authorization: `Bearer ${props.token}` },
      });
      if (response.data) {
        setAdminDevices(response.data);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const getZones = async () => {
    try {
      const response = await axios({
        url: "admin/findZones",
        method: "GET",
        headers: { authorization: `Bearer ${props.token}` },
      });
      if (response.data) {
        setZones(response.data);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const editDevice = async (eDevice, oldDevice, e, newPassword) => {
    e.preventDefault();
    if (eDevice === oldDevice) {
      return null;
    }
    await props.updateDevice(eDevice, props.token).then(() => {
      getDevices();
      setIsShowing(false);
    });
    if (newPassword) {
      props.changeDevicePassword(eDevice, newPassword, props.token).then(() => {
        console.log("device pass updated");
      });
    }
    setTimeout(() => {
      setIsShowing(false);
    }, 50);
    setIsShowing(false);
  };

  const setEditDevice = (device) => {
    setDeviceToEdit(device);
    setTimeout(() => {
      setIsShowing(true);
    }, 100);
    setIsShowing(false);
  };

  const sendDeleteConfirmation = (device) => {
    setDeviceToDelete(device);
    setTimeout(() => {
      setShowDeleteModal(true);
    }, 100);
    setShowDeleteModal(false);
  };

  const remDevice = async (device, pg) => {
    await props.removeDevice(device, props.token);
    getDevices();
    setShowDeleteModal(false);
    setTimeout(() => {
      setCurrentPage(pg);
    }, 100);
    setCurrentPage(1);
  };

  const ref = useRef(false);

  useClickOutsideListenerRef(ref, () => {
    setIsShowing(false);
  });

  useEffect(() => {
    getDevices();
  }, [device, props.devices]);

  useEffect(() => {
    getZones();
  }, [props.zones]);

  const onChangeSort = (e) => {
    const { newSort, newArr } = sortArrByColumn(
      e,
      sort,
      adminDevices,
      mapColumnToDataName
    );
    setSort(newSort);
    setAdminDevices(newArr);
  };

  const indexOfLastDevice = currentPage * devicesPerPage;
  const indexOfFirstDevice = indexOfLastDevice - devicesPerPage;
  let currentDevices = adminDevices.slice(
    indexOfFirstDevice,
    indexOfLastDevice
  );
  useEffect(() => {
    currentDevices = adminDevices.slice(indexOfFirstDevice, indexOfLastDevice);
  }, [adminDevices]); // this use effect ensures that devices update in UI after admin devices are updated

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getTableData = () => ({
    columns: [
      { key: "index", label: "# Number", alignCenter: true, small: true },
      { key: "name", label: "Name", sortable: true },
      { key: "zone", label: "Zone", sortable: true },
      { key: "thermal", label: "Thermal", sortable: true },
      {
        key: "actions",
        label: "Actions",
        alignCenter: true,
        noPadding: true,
        extraLarge: true,
      },
    ],
    content: currentDevices.map((device, index) => {
      return [
        { key: "index", value: index + 1 + devicesPerPage * (currentPage - 1) },
        { key: "name", value: device.deviceName },
        { key: "zone", value: device.zoneName },
        {
          key: "thermal",
          value: device.thermal ? (
            <i className="fa fa-thermometer-full fa-2x ml-2 thermoOn" />
          ) : (
            <i className="fa fa-thermometer-empty fa-2x ml-2 thermoOff" />
          ),
        },
        {
          key: "actions",
          value: (
            <>
              <Button
                size="md"
                color="light"
                className="button-default"
                onClick={() => {
                  setEditDevice(device);
                }}
              >
                <i className="fa fa-pencil-square-o fa-1x mr-2" />
                Edit
              </Button>

              <Button
                size="md"
                color="danger"
                onClick={() => {
                  sendDeleteConfirmation(device);
                }}
                className="button-cancel"
              >
                <i className="fa fa-trash-o fa-1x mr-2" />
                Delete
              </Button>
            </>
          ),
        },
      ];
    }),
  });

  return (
    <PermissionWrapper scopes={[SCOPES.deviceManagment]}>
      <SimpleHeader name="Manage Devices" parentName="Device" />
      <Container id="modal-container" className="mt-4" fluid>
        <Row className="justify-content-start">
          <Col md="12">
            <Card>
              <Row className="table-responsive pb-3 ml-0">
                <Table
                  CustomHeaderTabs={() => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                      className="pr-4"
                    >
                      <h3 className="mb-0">Current Devices</h3>
                      <Button
                        className="bg-primary shadow "
                        color="primary"
                        onClick={(prevState) =>
                          setShowAddDevModal(!showDeleteModal)
                        }
                      >
                        <i className="fa fa-plus fa-1x mr-2" />
                        Add New Device
                      </Button>
                    </div>
                  )}
                  content={getTableData().content}
                  columns={getTableData().columns}
                  sort={sort}
                  onChangeSort={onChangeSort}
                  Pagination={() => (
                    <nav aria-label="...">
                      <PaginationTable
                        per_page={devicesPerPage}
                        current_page={currentPage}
                        total_data={adminDevices.length}
                        handleClick={paginate}
                      />
                    </nav>
                  )}
                />
              </Row>

              {showDeleteModal ? (
                <ConfirmDeleteModal
                  device={deviceToDelete}
                  page={currentPage}
                  removeDevice={remDevice}
                  setShowDeleteModal={setShowDeleteModal}
                />
              ) : null}

              {isShowing ? (
                <EditDeviceModal
                  device={deviceToEdit}
                  submitEditDevice={editDevice}
                  page={currentPage}
                  zones={zones}
                  setIsShowing={setIsShowing}
                />
              ) : null}

              {showAddDevModal ? (
                <Row className="card-wrapper delModalOverlay">
                  <Card className="bg-white ml-5">
                    <CardHeader className="d-flex justify-content-between border-bottom-0">
                      <h3 className="mb-0 bg-white">Add a New Device</h3>
                      <Button
                        onClick={() => {
                          console.log("button clicked!");
                          setShowAddDevModal(false);
                        }}
                      >
                        X
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <form onSubmit={handleSubmit}>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <Label className="font-weight-bold">
                                Device Name
                              </Label>
                              <InputGroup className="input-group-merge input-group-alternative">
                                <Input
                                  type="text"
                                  name="deviceName"
                                  value={values.deviceName}
                                  onChange={handleChange}
                                />
                              </InputGroup>
                              {errors.deviceName && (
                                <p className="error">{errors.deviceName}</p>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label className="font-weight-bold">
                                Device Password
                              </Label>
                              <InputGroup className="input-group-merge input-group-alternative">
                                <Input
                                  type="password"
                                  name="password"
                                  value={values.password}
                                  onChange={handleChange}
                                />
                              </InputGroup>
                              {errors.password && (
                                <p className="error">{errors.password}</p>
                              )}
                            </FormGroup>
                            <Row className="d-flex align-items-center justify-content-around">
                              <FormGroup>
                                <Label className="font-weight-bold">
                                  Zone Assignment
                                </Label>
                                <InputGroup>
                                  <Dropdown
                                    isOpen={zoneDropDownOpen}
                                    toggle={toggleZone}
                                  >
                                    <DropdownToggle caret>
                                      {values.zoneName === ""
                                        ? "Unassigned"
                                        : values.zoneName
                                            .charAt(0)
                                            .toUpperCase() +
                                          values.zoneName.substring(1)}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        name="zoneDropdown"
                                        onClick={handleChange}
                                        value={"Unassigned"}
                                      >
                                        Unassigned
                                      </DropdownItem>
                                      {zones
                                        ? zones.map((value, index) => {
                                            return (
                                              <DropdownItem
                                                key={index}
                                                name="zoneDropdown"
                                                onClick={handleChange}
                                                value={value.zoneName}
                                              >
                                                {value.zoneName}
                                              </DropdownItem>
                                            );
                                          })
                                        : null}
                                    </DropdownMenu>
                                  </Dropdown>
                                </InputGroup>
                              </FormGroup>
                              <FormGroup>
                                <InputGroup className="input-group-merge ml-4">
                                  <div className="toggle-switch">
                                    <input
                                      type="checkbox"
                                      className="toggle-switch-checkbox"
                                      name="toggleSwitch"
                                      id="toggleSwitch"
                                    />
                                    <label
                                      className="toggle-switch-label"
                                      htmlFor="toggleSwitch"
                                    >
                                      <span className="toggle-switch-inner" />
                                      <span className="toggle-switch-switch" />
                                    </label>
                                  </div>
                                  <Label className="font-weight-bold ml-2">
                                    {" "}
                                    Thermal device
                                  </Label>
                                </InputGroup>
                              </FormGroup>
                            </Row>
                            <Row className="text-right mt-2 mb-2 mr-1 justify-content-end">
                              <Button
                                size="md"
                                color="secondary"
                                onClick={() => setShowAddDevModal(false)}
                              >
                                Cancel
                              </Button>
                              <Button size="md" color="primary" type="submit">
                                Add Device
                              </Button>
                            </Row>
                          </Col>
                        </Row>
                      </form>
                    </CardBody>
                  </Card>
                </Row>
              ) : null}
            </Card>
          </Col>
        </Row>
      </Container>
    </PermissionWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    devices: state.devices,
    zones: state.zones,
    token: state.token,
    admin: state.admin,
  };
};

export default connect(mapStateToProps, {
  updateDevice,
  changeDevicePassword,
  addDevice,
  removeDevice,
})(AddDevice);
