import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { getSocketEnvironment } from "../../actions/environmentActions";
import { findUsers } from "../../actions/userActions";
import "./cardsHeader.scss";
import { FaRegBuilding, FaRegClock } from "react-icons/all";

class CardsHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUsers: this.props.users,
      flaggedPct: 0,
    };

    if (!this.props.socketURL) {
      this.props.getSocketEnvironment();
    }
  }

  async componentDidMount() {
    await this.props.findUsers(this.props.token);
    this.props.socket.on("newEntry", () => {
      this.handleNewEntry();
    });
    this.setUsers();
    this.setFlaggedPct();
  }

  async handleNewEntry() {
    await this.props.findUsers(this.props.token);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.users.length !== prevProps.users.length) {
      this.setUsers();
    }
  }

  setUsers() {
    this.setState({
      currentUsers: this.props.users,
    });
  }

  setFlaggedPct() {
    let flagged = 0;
    let unflagged = 0;
    this.state.currentUsers.forEach((element) => {
      if (
        element.flag.indexOf("q") === -1 &&
        element.flag.indexOf("t") === -1
      ) {
        unflagged = unflagged + 1;
      } else {
        flagged = flagged + 1;
      }
    });
    let pct = (flagged / this.state.currentUsers.length) * 100;
    let rounded = Math.round(pct * 10) / 10;
    this.setState({ flaggedPct: rounded });
  }

  render() {
    const { currentUsers } = this.state;
    return (
      <>
        <div className="header pb-2">
          <Container fluid>
            <div className="header-body">
              <Row className="py-3" />
              <Row className="align-items-center py-3">
                <Col lg="6" xs="7">
                  {/* <img
                                        className="navbar-brand-img shadow-sm mr-2 mb-3"
                                        height={90}
                                        src={logo}
                                        alt="logo"
                                        style={{
                                            textShadow: "2px 2px 8px #000000",
                                        }}
                                    /> */}
                  <h6 className="h1 text-white d-inline-block shadow-sm mb-0">
                    {this.props.name}
                  </h6>{" "}
                </Col>
                <Col className="text-right" lg="6" xs="5"></Col>
              </Row>

              <Row>
                <Col md="6" xl="3">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <Col className="col-auto">
                          <div className="icon icon-shape text-grey rounded-circle shadow">
                            <i className="fa fa-tablet fa-lg" />
                          </div>
                        </Col>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase mb-0 font-weight-400"
                          >
                            Visitors Registered to Device
                          </CardTitle>
                          <span className="h1 font-weight-400 mb-0">
                            {currentUsers.length}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="3">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <Col className="col-auto">
                          <div className="icon icon-shape text-grey rounded-circle shadow">
                            <FaRegBuilding />
                          </div>
                        </Col>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase mb-0 font-weight-400"
                          >
                            Visitors Registered for Organization
                          </CardTitle>
                          <span className="h1 font-weight-400 mb-0">
                            {currentUsers.filter((user) => !user.flag).length}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="3">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <Col className="col-auto">
                          <div className="icon icon-shape text-grey rounded-circle shadow">
                            <FaRegClock />
                          </div>
                        </Col>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase mb-0 font-weight-400"
                          >
                            Visitors Registered in Past Hour
                          </CardTitle>
                          <span className="h1 font-weight-400 mb-0">
                            {
                              currentUsers.filter(
                                (user) =>
                                  user.timestamp &&
                                  moment().diff(
                                    moment(user.timestamp),
                                    "hours"
                                  ) === 0
                              ).length
                            }
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6" xl="3">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <Col className="col-auto">
                          <div className="icon icon-shape text-grey rounded-circle shadow">
                            <i className="ni ni-sound-wave" />
                          </div>
                        </Col>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase mb-0 font-weight-400"
                          >
                            Visitors Flagged for Further Screening
                          </CardTitle>
                          <span className="h1 font-weight-400 mb-0">
                            {this.state.flaggedPct}%
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    token: state.token,
    socket: state.socket,
  };
};

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default connect(mapStateToProps, { findUsers, getSocketEnvironment })(
  CardsHeader
);
