import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import arrayMove from "array-move";
import { toast } from "react-toastify";
import "./ConfigureSteps.scss";
import SimpleHeader from "../SimpleHeader/SimpleHeader";
import ConfigureTabs from "./ConfigureTabs";
import AddStepModal from "./AddStepModal";
import ConfirmDeleteStepModal from "./ConfirmDeleteStepModal";
import ScanIdModal from "./ScanIdModal";
import FormModal from "./FormModal";
import QuestionModal from "./QuestionModal";
import TempModal from "./TempModal";
import MessageModal from "./MessageModal";
import AddZoneModal from "../AddZone/AddZoneModal";
import useForm from "../AddZone/useFormZone";
import validateZone from "../AddZone/validateZone";
import { addZone, removeZone, findZones } from "../../actions/userActions";
import { getSocketEnvironment } from "../../actions/environmentActions";
import { stepTypes } from "./data";
import { getSteps, updateSteps } from "../../actions/stepsConfiguration";
import PictureModal from "./PictureModal";
import PermissionWrapper from "../permissions/PermissionWrapper";
import { SCOPES } from "../permissions/permission-map";

const operationsToast = ({ label }) => ({
  add: (
    <>
      <b>{label}</b> step added
    </>
  ),
  edit: (
    <>
      <b>{label}</b> step updated
    </>
  ),
  delete: (
    <>
      <b>{label}</b> step was deleted
    </>
  ),
  reorder: <>Steps order changed</>,
});

const ConfigureSteps = () => {
  const [addStepModal, setAddStepModal] = useState(null);
  const [showAddZoneModal, setShowAddZoneModal] = useState(false);
  const [stepIndexToDelete, setStepIndexToDelete] = useState(null);
  const [stepLabelToDelete, setStepLabelToDelete] = useState("");
  const [editValues, setEditValues] = useState(undefined);
  const [moveStatus, setMoveStatus] = useState(undefined);
  const [steps, setSteps] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoadingStepsConfigList, configListError } = useSelector(
    (state) => state
  );

  const { zoneName: userZoneName } = useSelector((state) => state.admin);
  const zoneInSearchParams = new URLSearchParams(history.location.search)?.get(
    "zone"
  );
  const [currentZone, setCurrentZone] = useState(
    zoneInSearchParams || userZoneName
  );
  const [zonesLoading, setZonesLoading] = useState(false);
  const [zoneDropdownOpen, setZoneDropdownOpen] = useState(false);
  const toggleZoneDropdown = () => setZoneDropdownOpen((prevVal) => !prevVal);
  const zones = useSelector((state) => state.zones);
  const token = useSelector((state) => state.token);

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set("zone", currentZone);
    history.replace({ search: searchParams.toString() });
  }, [currentZone]);

  const submit = (zone) => {
    if (zone) {
      console.log("submit", zone, token);
      dispatch(addZone(zone, token));
      setErrors({});
      alert(`zone ${values.zoneName} added`);
      setValues({ ...values, zoneName: "" });
      setTimeout(() => {
        setShowAddZoneModal(false);
      }, 250);
    } else {
      alert("no zone to add");
    }
  };

  const {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
    setErrors,
  } = useForm(submit, validateZone);

  const loadZonesData = async () => {
    setZonesLoading(true);
    await dispatch(findZones());
    setZonesLoading(false);
  };

  const loadStepsData = async () => {
    const steps = await dispatch(getSteps(currentZone));
    if (steps) setSteps(steps);
  };

  useEffect(() => {
    loadZonesData();
  }, []);

  useEffect(() => {
    loadStepsData();
  }, [currentZone]);

  useEffect(() => {
    if (stepIndexToDelete) {
      setStepLabelToDelete(stepTypes[steps[stepIndexToDelete].type].label);
    } else {
      setStepLabelToDelete("");
    }
  }, [stepIndexToDelete]);

  const saveData = async (steps, operationType, label) => {
    const res = await dispatch(updateSteps({ zoneName: currentZone, steps }));
    if (res) {
      toast.dark(operationsToast({ label })[operationType], {
        position: "bottom-center",
        className: "ConfigureSteps-toast",
        icon: false,
      });
    }
  };

  const hideModal = () => {
    setAddStepModal(null);
    setEditValues(undefined);
  };

  const onEdit = (item) => {
    item.values && setEditValues({ ...item.values });
    setAddStepModal(item.type);
  };

  const onDelete = () => {
    const deletingStep = steps.find(
      (step, index) => index === stepIndexToDelete
    );
    const newSteps = steps.filter((step, index) => index !== stepIndexToDelete);
    saveData(newSteps, "delete", stepTypes[deletingStep.type].label);
    setStepIndexToDelete(null);
    setSteps(newSteps);
  };

  const addStep = () => {
    setAddStepModal("select");
  };

  const onSelectStepType = (type) => {
    if (
      [
        "question",
        "tempCheck",
        "idScan",
        "picture",
        "form",
        "form2",
        "message",
      ].includes(type)
    ) {
      setAddStepModal(type);
    } else {
      const lastStepIndex = steps.length - 1;
      const stepsWithoutLast = steps.slice(0, lastStepIndex);
      let newSteps = [...stepsWithoutLast, { type }, steps[lastStepIndex]];
      if (type === "idScan") {
        const formStepIndex = steps.findIndex((step) => step.type === "form");
        if (formStepIndex > -1) {
          const stepsBeforeForm = steps.slice(0, formStepIndex);
          const stepsAfterForm = steps.slice(formStepIndex);
          newSteps = [...stepsBeforeForm, { type }, ...stepsAfterForm];
        }
      }
      setSteps(newSteps);
      saveData(newSteps, "add", stepTypes[type].label);
      hideModal();
    }
  };

  const onAddIdScan = (info) => {
    const existingStepIndex = steps.findIndex((step) => step.type === "idScan");
    if (existingStepIndex > -1) {
      const newSteps = [...steps];
      newSteps[existingStepIndex] = { type: "idScan", values: { ...info } };
      setSteps(newSteps);
      saveData(newSteps, "edit", stepTypes.idScan.label);
    } else {
      const lastStepIndex = steps.length - 1;
      const stepsWithoutLast = steps.slice(0, lastStepIndex);
      const newSteps = [
        ...stepsWithoutLast,
        { type: "idScan", values: { ...info } },
        steps[lastStepIndex],
      ];
      setSteps(newSteps);
      saveData(newSteps, "add", stepTypes.idScan.label);
    }
    hideModal();
  };

  const onAddForm = (info) => {
    const existingStepIndex = steps.findIndex((step) => step.type === "form");
    if (existingStepIndex > -1) {
      const newSteps = [...steps];
      newSteps[existingStepIndex] = { type: "form", values: { ...info } };
      setSteps(newSteps);
      saveData(newSteps, "edit", stepTypes.form.label);
    } else {
      const lastStepIndex = steps.length - 1;
      const stepsWithoutLast = steps.slice(0, lastStepIndex);
      const newSteps = [
        ...stepsWithoutLast,
        { type: "form", values: { ...info } },
        steps[lastStepIndex],
      ];
      setSteps(newSteps);
      saveData(newSteps, "add", stepTypes.form.label);
    }
    hideModal();
  };

  const onAddQuestion = (info) => {
    const existingStepIndex = steps.findIndex(
      (step) => step.values?.question === editValues?.question
    );
    if (existingStepIndex) {
      const newSteps = [...steps];
      newSteps[existingStepIndex] = { type: "question", values: { ...info } };
      setSteps(newSteps);
      saveData(newSteps, "edit", stepTypes.question.label);
    } else {
      const lastStepIndex = steps.length - 1;
      const stepsWithoutLast = steps.slice(0, lastStepIndex);
      const newSteps = [
        ...stepsWithoutLast,
        { type: "question", values: { ...info } },
        steps[lastStepIndex],
      ];
      setSteps(newSteps);
      saveData(newSteps, "add", stepTypes.question.label);
    }
    hideModal();
  };

  const onAddTempCheck = (info) => {
    const existingStepIndex = steps.findIndex(
      (step) => step.values?.min === editValues?.min
    );
    if (existingStepIndex) {
      const newSteps = [...steps];
      newSteps[existingStepIndex] = { type: "tempCheck", values: { ...info } };
      setSteps(newSteps);
      saveData(newSteps, "edit", stepTypes.tempCheck.label);
    } else {
      const lastStepIndex = steps.length - 1;
      const stepsWithoutLast = steps.slice(0, lastStepIndex);
      const newSteps = [
        ...stepsWithoutLast,
        { type: "tempCheck", values: { ...info } },
        steps[lastStepIndex],
      ];
      setSteps(newSteps);
      saveData(newSteps, "add", stepTypes.tempCheck.label);
    }
    hideModal();
  };

  const onAddPicture = (info) => {
    const existingStepIndex = steps.findIndex(
      (step) => step.type === "picture"
    );
    if (existingStepIndex) {
      const newSteps = [...steps];
      newSteps[existingStepIndex] = { type: "picture", values: { ...info } };
      setSteps(newSteps);
      saveData(newSteps, "edit", stepTypes.picture.label);
    } else {
      const lastStepIndex = steps.length - 1;
      const stepsWithoutLast = steps.slice(0, lastStepIndex);
      const newSteps = [
        ...stepsWithoutLast,
        { type: "picture", values: { ...info } },
        steps[lastStepIndex],
      ];
      setSteps(newSteps);
      saveData(newSteps, "add", stepTypes.picture.label);
    }
    hideModal();
  };

  const onAddMessage = (info) => {
    const existingStepIndex = steps.findIndex(
      (step) => step.values?.message === editValues?.message
    );
    if (existingStepIndex) {
      const newSteps = [...steps];
      newSteps[existingStepIndex] = { type: "message", values: { ...info } };
      setSteps(newSteps);
      saveData(newSteps, "edit", stepTypes.message.label);
    } else {
      const lastStepIndex = steps.length - 1;
      const stepsWithoutLast = steps.slice(0, lastStepIndex);
      const newSteps = [
        ...stepsWithoutLast,
        { type: "message", values: { ...info } },
        steps[lastStepIndex],
      ];
      setSteps(newSteps);
      saveData(newSteps, "add", stepTypes.message.label);
    }
    hideModal();
  };

  const updateStepsOrder = ({ oldIndex, newIndex }) => {
    const newSteps = arrayMove(steps, oldIndex, newIndex);

    const formStepIndex = newSteps.findIndex((step) => step.type === "form");
    const idScanStepIndex = newSteps.findIndex(
      (step) => step.type === "idScan"
    );
    if (
      formStepIndex > -1 &&
      idScanStepIndex > -1 &&
      formStepIndex < idScanStepIndex
    ) {
      return toast.error("Form step should go after the ID Scan step", {
        position: "bottom-center",
        icon: false,
        theme: "dark",
      });
    }

    setSteps(newSteps);
    saveData(newSteps, "reorder");
  };

  const changeMoveStatus = (status) => {
    setMoveStatus(status);
  };

  return (
    <PermissionWrapper scopes={[SCOPES.configuration]}>
      <div
        className="ConfigureSteps"
        style={{ cursor: moveStatus || "default" }}
      >
        <SimpleHeader name="Configuration" parentName="Configuration" />
        <div className="ConfigureSteps-content">
          <ConfigureTabs
            isLoadingStepsConfigList={isLoadingStepsConfigList}
            configListError={configListError}
            steps={steps}
            onSortEnd={updateStepsOrder}
            changeMoveStatus={changeMoveStatus}
            moveStatus={moveStatus}
            onEdit={onEdit}
            onDelete={(index) => setStepIndexToDelete(index)}
            zones={zones}
            zoneDropdownOpen={zoneDropdownOpen}
            toggleZoneDropdown={toggleZoneDropdown}
            currentZone={currentZone}
            zonesLoading={zonesLoading}
            setCurrentZone={setCurrentZone}
            addStep={addStep}
            addZone={setShowAddZoneModal}
          />

          <AddStepModal
            isOpen={addStepModal === "select"}
            steps={steps}
            toggle={hideModal}
            onSelect={onSelectStepType}
            defaultValues={editValues}
          />
          <ConfirmDeleteStepModal
            isOpen={stepIndexToDelete}
            toggle={() => setStepIndexToDelete(null)}
            onDelete={onDelete}
            label={stepLabelToDelete}
          />
          <ScanIdModal
            isOpen={addStepModal === "idScan"}
            steps={steps}
            toggle={hideModal}
            onAddIdScan={onAddIdScan}
            defaultValues={editValues}
          />
          <PictureModal
            isOpen={addStepModal === "picture"}
            steps={steps}
            toggle={hideModal}
            onAddPicture={onAddPicture}
            defaultValues={editValues}
          />
          <FormModal
            isOpen={addStepModal === "form"}
            steps={steps}
            toggle={hideModal}
            onAddForm={onAddForm}
            defaultValues={editValues}
          />
          <QuestionModal
            isOpen={addStepModal === "question"}
            steps={steps}
            toggle={hideModal}
            onAddQuestion={onAddQuestion}
            defaultValues={editValues}
          />
          <TempModal
            isOpen={addStepModal === "tempCheck"}
            steps={steps}
            toggle={hideModal}
            onAddTempCheck={onAddTempCheck}
            defaultValues={editValues}
          />
          <MessageModal
            isOpen={addStepModal === "message"}
            steps={steps}
            toggle={hideModal}
            onAddMessage={onAddMessage}
            defaultValues={editValues}
          />
          <AddZoneModal
            isOpen={showAddZoneModal}
            toggle={() => setShowAddZoneModal(false)}
            handleSubmit={handleSubmit}
            values={values}
            handleChange={handleChange}
            errors={errors}
          />
        </div>
      </div>
    </PermissionWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    devices: state.devices,
    zones: state.zones,
    token: state.token,
    admin: state.admin,
    socket: state.socket,
    socketURL: state.socketURL,
  };
};

export default connect(mapStateToProps, {
  addZone,
  removeZone,
  findZones,
  getSocketEnvironment,
})(ConfigureSteps);
