import React from "react";
import classnames from "classnames";
import "./styles.scss";

export const TabButton = ({ name = '', Icon, onClick, active, className }) => (
  <button onClick={onClick} className={classnames(className, "TabButton", { active })}>
    {Icon ? <Icon /> : null}
    <span>{name}</span>
  </button>
);
