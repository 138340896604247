import React, { useState } from "react";
import { connect } from "react-redux";
import "../addDevice/addDevice.scss";
import { Button, ButtonGroup, Input } from "reactstrap";
import { qrSigninDataInput } from "../../actions/userActions";
import { sendImageToS3 } from "../../actions/mediaActions";
import FileUploader from "./FileUploader";
import validatePresScreenForm from "./validatePreScreenForm";
import { PrescreenBackground, TimbergroveLogo, GreenCheck, HeaderText, LargeText, RegularText, 
    CenterItemsDiv, FormDiv, MasterDiv, FormLabel, FormRow, FormField, CompleteDiv, 
    HorizontalSeparator } from "./EntryFormComponents"

function SigninEntryForm(props) {
  const [sessionId, setSessionId] = useState(Math.floor(Math.random() * 1000000001));
  const [imageId, setImageId] = useState(Math.floor(Math.random() * 1000000001));
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [state, setuState] = useState("");
  const [dob, setDob] = useState("");
  const [idType, setIdType] = useState("License");
  const [idNumber, setIdNumber] = useState();
  const [ts, setTs] = useState(Date.now());
  const [image, setImage] = useState(Math.floor(Math.random() * 1000000001));
  const [selectedFile, setSelectedFile] = useState(null);

  const [completed, setCompleted] = useState(false);
  const [errors, setErrors] = useState({});
  const [sms, setSms] = useState('')
  const [pointOfContact, setPointOfContact] = useState('')
  const [companyName, setCompanyName] = useState('')

  async function handleImageUpload(file) {
    getBase64(file).then((result) => {
      setSelectedFile(result);
    });
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async function submitUpdates(e) {
    console.log("submit Updates");
    console.log("image:", image);
    const data = {
      firstName: fName,
      lastName: lName,
      state: state,
      DOB: dob,
      idType: idType,
      number: idNumber,
      timestamp: ts,
      image: imageId,
      imageId: imageId,
      sessionId: sessionId,
      pointOfContact: pointOfContact,
      companyName: companyName,
      sms: `1${sms}`,
      deviceID: props.match.params.deviceID
    };

    setErrors(validatePresScreenForm(data));

    if (Object.keys(errors).length === 0) {
      props.qrSigninDataInput(data)
      setCompleted(true);
      props.sendImageToS3(selectedFile, sessionId, imageId);
    }
  }

  return (
    <PrescreenBackground>
      <TimbergroveLogo src={require('../../assets/tgs-logo.png')} />
      {!completed ? (
        <>
          <CenterItemsDiv>
            <HeaderText>Prescreen Entry</HeaderText>
            <MasterDiv>
              <FormDiv onSubmit={submitUpdates}>

                <FormRow>
                  <FormField>
                    <FormLabel>First Name*</FormLabel>
                      <Input
                        style={{background: "#F4F7FA"}}
                        placeholder="Enter First Name"
                        type="text"
                        name="firstName"
                        value={fName}
                        onChange={(e) => setFName(e.target.value)}
                        autoFocus
                        required
                      />
                    {errors.firstName && <p className="error">{errors.firstName}</p>}
                  </FormField>

                  <FormField>
                    <FormLabel>Last Name*</FormLabel>
                      <Input
                        style={{background: "#F4F7FA"}}
                        placeholder="Enter Last Name"
                        type="text"
                        name="lastName"
                        value={lName}
                        onChange={(e) => setLName(e.target.value)}
                        required
                      />
                    {errors.lastName && <p className="error">{errors.lastName}</p>}
                  </FormField>
                </FormRow>

                <FormRow>
                  <FormField>
                    <FormLabel>Date of Birth*</FormLabel>
                      <Input
                        style={{background: "#F4F7FA"}}
                        placeholder="Enter Date of Birth"
                        type="text"
                        name="dob"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        required
                      />
                    {errors.dob && <p className="error">{errors.dob}</p>}
                  </FormField>

                    <FormField>
                        <FormLabel>Phone Number*</FormLabel>
                        <Input
                            style={{background: "#F4F7FA"}}
                            placeholder="Enter Phone Number"
                            type="text"
                            name="sms"
                            value={sms}
                            onChange={(e) => setSms(e.target.value)}
                            required
                        />
                    </FormField> 
                </FormRow>

                <FormRow>
                  <FormField>
                    <FormLabel>Contact Name*</FormLabel>
                      <Input
                        style={{background: "#F4F7FA"}}
                        placeholder="Name of Person Visiting"
                        type="text"
                        name="pointOfContact"
                        value={pointOfContact}
                        onChange={(e) => setPointOfContact(e.target.value)}
                        autoFocus
                        required
                      />
                  </FormField>

                  <FormField>
                    <FormLabel>Company Name*</FormLabel>
                      <Input
                        style={{background: "#F4F7FA"}}
                        placeholder="Name of Company Visiting"
                        type="text"
                        name="companyName"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        required
                      />
                  </FormField>
                </FormRow>

                <HorizontalSeparator />

                <ButtonGroup style={{margin: "6px"}}>
                  <Button color="secondary" onClick={() => setIdType('License')} active={idType === 'License'}>License</Button>
                  <Button color="secondary" onClick={() => setIdType('Passport')} active={idType === 'Passport'}>Passport</Button>
                </ButtonGroup>

                <FormRow>
                  <FormField>
                    <FormLabel>Id Number*</FormLabel>
                      <Input
                        style={{background: "#F4F7FA"}}
                        placeholder="Enter ID Number"
                        type="password"
                        name="idNumber"
                        value={idNumber}
                        onChange={(e) => setIdNumber(e.target.value)}
                        required
                      />
                    {errors.idType && <p className="error">{errors.idType}</p>}
                  </FormField>

                  <FormField style={{width: "160px", flexGrow: 0}}>
                    <FormLabel>State*</FormLabel>
                      <Input
                        style={{background: "#F4F7FA"}}
                        placeholder="Enter State"
                        type="text"
                        name="state"
                        value={state}
                        onChange={(e) => setuState(e.target.value)}
                        required
                      />
                    {errors.state && <p className="error">{errors.state}</p>}
                  </FormField>
                </FormRow>

                <HorizontalSeparator />

                <FormField>
                  <FormLabel>Picture*</FormLabel>
                    <FileUploader
                      onFileSelectSuccess={handleImageUpload}
                      onFileSelectError={({ error }) => alert(error)}
                      required={true}
                    />
                </FormField>

                <Button size="md" color="success" style={{color: "white", margin: "6px"}} type="submit">Submit</Button>
                <FormLabel>* Indicates a Required Field</FormLabel>
              </FormDiv>

            </MasterDiv>
          </CenterItemsDiv>
        </>
      ) : (
        <CenterItemsDiv>
            <HeaderText>Attention</HeaderText>
            <MasterDiv>
            <CompleteDiv>
                <GreenCheck src={require('../../assets/greenCheck.png')} />
                <LargeText>Survey Completed</LargeText>
                <RegularText>Check text messages for entry code</RegularText>
            </CompleteDiv>
            </MasterDiv>
        </CenterItemsDiv>
      )}
      </PrescreenBackground>
  );
}

export default connect(null, {
  qrSigninDataInput,
  sendImageToS3,
})(SigninEntryForm);
