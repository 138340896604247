// import { ValidationRequestInstance } from "twilio/lib/rest/api/v2010/account/validationRequest";

export default function validatePreScreenForm(values) {

  // var validator = require("email-validator");
  const moment = require('moment')
  const today = moment()
  const DOB = values.DOB || values.dob
  console.log('inside validatePrescreenForm', today.format('MM-DD-YYYY'))
  console.log('date validation', moment(DOB, 'MM-DD-YYYY').isValid())

    let errors = {};
    // let emailRegex = /[@a-zA-z0-9\.]/gm;
  
    // if (!values.email) {
    //   errors.email = "please enter an email";
    // }
    // if (!validator.validate(values.email)) {
    //   errors.email = "please enter a valid email";
    // }
    if (!values.firstName) {
      errors.firstName = "please enter a first name";
    }
    if (!values.lastName) {
      errors.lastName = "please enter a last name";
    }
    if (!values.state) {
      errors.state = "please enter a state";
    }
    if (!DOB) {
      errors.dob = "please enter a date of birth";
    } else 
    {
      if(!moment(DOB, 'MM-DD-YYYY', true).isValid()){
        errors.dob="please enter a valid date of birth"
      }
    }
    if (!values.idType) {
      errors.idType = "please enter id type";
    }
    if (!values.number) {
      errors.number = "please enter a number";
    }
    if (!values.timestamp) {
      errors.timestamp = "please enter the time";
    }
    if (!values.image) {
      errors.email = "please upload an image";
    }
    // if (!values.imageId) {
    //   errors.imageId = "please enter an email";
    // }
    // if (!values.sessionId) {
    //   errors.sessionId = "please enter an email";
    // }

  
    return errors;
  }
  