import React, { useCallback, useMemo, useRef, useState } from 'react';
import { createEditor, Editor, Element as SlateElement, Transforms } from 'slate';
import { Editable, Slate, useFocused, useSelected, useSlate, useSlateStatic, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import isUrl from 'is-url';
import imageExtensions from 'image-extensions';
import isHotkey from 'is-hotkey';
import { Spinner } from 'reactstrap';
import axios from "../../axiosconfig";
import { ReactComponent as ImageIcon } from "./icons/toolbar/image.svg";
import { ReactComponent as H1Icon } from "./icons/toolbar/h1.svg";
import { ReactComponent as H2Icon } from "./icons/toolbar/h2.svg";
import { ReactComponent as BoldIcon } from "./icons/toolbar/bold.svg";
import { ReactComponent as ItalicIcon } from "./icons/toolbar/italic.svg";
import { ReactComponent as UnderlineIcon } from "./icons/toolbar/underline.svg";
import { ReactComponent as OrderedListIcon } from "./icons/toolbar/ordered-list.svg";
import { ReactComponent as UnorderedListIcon } from "./icons/toolbar/unordered-list.svg";

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

export const SlateEditor = ({message, setMessage, placeholder}) => {
  const [uploadingFile, setUploadingFile] = useState(false);
  const fileInputRef = useRef(null);

  const withImages = editor => {
    const { insertData, isVoid } = editor;

    editor.isVoid = element => {
      return element.type === 'image' ? true : isVoid(element);
    }

    editor.insertData = data => {
      const text = data.getData('text/plain');
      const { files } = data;

      if (files && files.length > 0) {
        for (const file of files) {
          const reader = new FileReader();
          const [mime] = file.type.split('/');

          if (mime === 'image') {
            reader.addEventListener('load', () => {
              const url = reader.result;
              insertImage(editor, url);
            });

            reader.readAsDataURL(file);
          }
        }
      } else if (isImageUrl(text)) {
        insertImage(editor, text);
      } else {
        insertData(data);
      }
    };

    return editor;
  };

  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withImages(withHistory(withReact(createEditor()))), []);

  const insertImage = (editor, url) => {
    const text = { text: '' };
    const image = { type: 'image', url, children: [text] };
    Transforms.insertNodes(editor, image);
  };

  const isImageUrl = url => {
    if (!url || !isUrl(url)) return false;
    const ext = new URL(url).pathname.split('.').pop();
    return imageExtensions.includes(ext);
  };

  const Image = ({ attributes, children, element }) => {
    const selected = useSelected();
    const focused = useFocused();
    return (
      <div {...attributes}>
        <div contentEditable={false}>
          <img
            src={element.url}
            className={`MessageModal-editor-image${(selected && focused) ? ' active' : ''}`}
            alt=""
          />
        </div>
        {children}
      </div>
    );
  };

  const uploadImage = async (data) => axios.post('/steps/uploadMessageImg', data);

  const InsertImageButton = () => {
    const editor = useSlateStatic();
    return (
      <form className="MessageModal-toolbar-item-fileInput">
        <input type="file" name="file_image" disabled={uploadingFile} ref={fileInputRef} onChange={async e => {
          if (e.target?.files?.length) {
            setUploadingFile(true);
            const formData = new FormData();
            formData.append('file_image', e.target.files[0]);
            try {
              const res = await uploadImage(formData);
              const imgUrl = res?.data?.imgUrl;
              insertImage(editor, imgUrl)
            } catch (e) {
              console.error(e);
            }
            finally {
              setUploadingFile(false);
              fileInputRef.current.value = '';
            }
          }
        }} />
        <button
          className="MessageModal-toolbar-item"
          onClick={e => e.preventDefault()}
        >
          {uploadingFile
            ? (
              <div style={{ textAlign: 'center' }}>
                <Spinner color='primary' style={{ width: '1.25rem', height: '1.25rem' }} />
              </div>
            ) : <ImageIcon />}
        </button>
      </form>
    );
  };

  const BlockButton = ({ format, Icon }) => {
    const editor = useSlate();
    const active = isBlockActive(editor, format);
    return (
      <button
        onClick={e => {
          e.preventDefault();
          toggleBlock(editor, format);
        }}
        className={`MessageModal-toolbar-item${active ? ' active' : '' }`}
        type="button"
      >
        <Icon />
      </button>
    );
  };

  const MarkButton = ({ format, Icon }) => {
    const editor = useSlate();
    const active = isMarkActive(editor, format);
    return (
      <button
        onClick={e => {
          e.preventDefault();
          toggleMark(editor, format);
        }}
        className={`MessageModal-toolbar-item${active ? ' active' : '' }`}
      >
        <Icon />
      </button>
    );
  };

  const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: n => LIST_TYPES.includes(!Editor.isEditor(n) && SlateElement.isElement(n) && n.type),
      split: true,
    });
    const newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  }

  const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) Editor.removeMark(editor, format);
    else Editor.addMark(editor, format, true);
  }

  const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
      match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });
    return !!match;
  }

  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? !!marks[format] : false;
  };

  const checkHotkey = e => {
    Object.entries(HOTKEYS).some(([hotkey, mark]) => {
      if (isHotkey(hotkey, e)) {
        e.preventDefault();
        toggleMark(editor, mark);
        return true;
      }
    });
  };

  const Element = ({ attributes, children, element }) => {
    switch (element.type) {
      case 'block-quote':
        return <blockquote {...attributes}>{children}</blockquote>;
      case 'bulleted-list':
        return <ul {...attributes}>{children}</ul>;
      case 'heading-one':
        return <h1 {...attributes}>{children}</h1>;
      case 'heading-two':
        return <h2 {...attributes}>{children}</h2>;
      case 'list-item':
        return <li {...attributes}>{children}</li>;
      case 'numbered-list':
        return <ol {...attributes}>{children}</ol>;
      case 'image':
        return <Image attributes={attributes} children={children} element={element} />;
      default:
        return <p {...attributes}>{children}</p>;
    }
  };

  const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) children = <strong>{children}</strong>;
    if (leaf.code) children = <code>{children}</code>;
    if (leaf.italic) children = <em>{children}</em>;
    if (leaf.underline) children = <u>{children}</u>;
    return <span {...attributes}>{children}</span>;
  };

  return (
    <Slate editor={editor} value={message} onChange={(val) => setMessage(val)}>
      <div className="MessageModal-toolbar">
        <BlockButton format="heading-one" Icon={H1Icon} />
        <BlockButton format="heading-two" Icon={H2Icon} />
        <div className="MessageModal-toolbar-divider" />
        <MarkButton format="bold" Icon={BoldIcon} />
        <MarkButton format="italic" Icon={ItalicIcon} />
        <MarkButton format="underline" Icon={UnderlineIcon} />
        <div className="MessageModal-toolbar-divider" />
        <BlockButton format="numbered-list" Icon={OrderedListIcon} />
        <BlockButton format="bulleted-list" Icon={UnorderedListIcon} />
        <InsertImageButton />
      </div>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder={placeholder}
        spellCheck
        autoFocus
        onKeyDown={checkHotkey}
      />
    </Slate>
  );
};
