import React from 'react';

export const Selector = ({ value, onChange }) => {

  return (
    <div className="Selector">
      <div className={value === 'license' ? 'active' : ''} onClick={() => onChange('license')}>License</div>
      <div className={value === 'passport' ? 'active' : ''} onClick={() => onChange('passport')}>Password</div>
    </div>
  );
};
