import React, { useEffect, useMemo, useState } from "react";
import { ColumnsDropdown } from "./ColumnsDropdown";
import { Search } from "./Search";
import { ReactComponent as AngleUpIcon } from "../../assets/tabs/angle-up.svg";
import { ReactComponent as AngleDownIcon } from "../../assets/tabs/angle-down.svg";
import "./table.scss";

export const Table = ({
  content,
  columns,
  CustomHeaderTabs,
  Pagination,
  sort,
  onChangeSort,
  sourcePage,
}) => {
  const activeColName = `${sourcePage}-activeColumns`;
  const [search, setSearch] = useState("");
  const [hovered, setHovered] = useState(false);
  const [activeColumns, setActiveColumns] = useState(() => {
    const activeCol = localStorage.getItem(activeColName);
    const initialValue = JSON.parse(activeCol);
    return initialValue || [...columns.map((col) => col.key)];
  });

  const preparedOptions = useMemo(() => {
    const allColumnsOption = {
      key: "all",
      label: "All columns",
      onClick: () => setActiveColumns([...columns.map((col) => col.key)]),
      active: columns.every((col) => activeColumns.includes(col.key)),
      borderBottom: true,
    };
    return [allColumnsOption, ...columns];
  }, [columns, activeColumns]);

  const onToggleColumn = (columnKey) => {
    setActiveColumns((activeColumns) => {
      if (activeColumns.includes(columnKey)) {
        const filtered = activeColumns.filter(
          (activeColumn) => activeColumn !== columnKey
        );
        localStorage.setItem(activeColName, JSON.stringify(filtered));
        return filtered;
      } else {
        localStorage.setItem(
          activeColName,
          JSON.stringify([...activeColumns, columnKey])
        );
        return [...activeColumns, columnKey];
      }
    });
  };

  return (
    <div className="Table">
      <div className="Table-wrapper">
        <div className="Table-header">
          {CustomHeaderTabs ? <CustomHeaderTabs /> : null}
          <div className="Table-options">
            <ColumnsDropdown
              className="Table-options-columns"
              options={preparedOptions}
              selectedOptions={activeColumns}
              onToggleOption={onToggleColumn}
            />
            <Search value={search} onChangeText={setSearch} />
          </div>
        </div>
        <div className="Table-scroll">
          <table className="Table-content">
            <thead>
              <tr className="Table-head-row">
                {columns
                  .filter((column) => activeColumns.includes(column.key))
                  .map((column) => (
                    <th
                      key={column.key}
                      className={`Table-cell
                    ${column.alignCenter ? " alignCenter" : ""}
                    ${column.small ? " smallCell" : ""}
                    ${column.large ? " largeCell" : ""}
                    ${column.extraLarge ? " extraLargeCell" : ""}
                    ${column.sortable ? " sortable" : ""}
                  `}
                      data-sort={column.sortable ? column.key : undefined}
                      onClick={column.sortable ? onChangeSort : undefined}
                    >
                      {column.label}
                      {column.key === sort?.col ? (
                        <span className="Table-cell-sort">
                          {sort.desc ? <AngleDownIcon /> : <AngleUpIcon />}
                        </span>
                      ) : null}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {content
                .filter(
                  (contentRow) =>
                    !search ||
                    contentRow.find(
                      (cell) =>
                        typeof cell.value === "string" &&
                        cell.value.indexOf(search) !== -1
                    )
                )
                .map((contentRow, index) => (
                  <tr
                    onMouseEnter={() => setHovered(index)}
                    onMouseLeave={() => setHovered(false)}
                    key={index}
                    className={`Table-row ${
                      index % 2 === 0 ? "Table-row-white" : ""
                    }`}
                  >
                    {columns
                      .filter((columnCell) =>
                        activeColumns.includes(columnCell.key)
                      )
                      .map((columnCell) => (
                        <td
                          key={columnCell.key}
                          className={
                            columnCell.key === "options" ||
                            columnCell.key === "actions"
                              ? `Table-cell
                          ${hovered === index ? "" : "hiddenButton"}
                          ${columnCell.alignCenter ? " alignCenter" : ""}
                          ${columnCell.noPadding ? " noPadding" : ""}
                          ${columnCell.smallFontSize ? " smallFontSize" : ""}
                          ${columnCell.small ? " smallCell" : ""}
                          ${columnCell.large ? " largeCell" : ""}
                        `
                              : `Table-cell
                          ${columnCell.alignCenter ? " alignCenter" : ""}
                          ${columnCell.noPadding ? " noPadding" : ""}
                          ${columnCell.smallFontSize ? " smallFontSize" : ""}
                          ${columnCell.small ? " smallCell" : ""}
                          ${columnCell.large ? " largeCell" : ""}
                        `
                          }
                        >
                          {contentRow.find(
                            (cell) => cell.key === columnCell.key
                          )?.value || ""}
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      {Pagination ? (
        <div className="Table-pagination">
          <Pagination />
        </div>
      ) : null}
    </div>
  );
};
