import React, { useState, useEffect } from 'react'
import axios from '../../axiosconfig'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroup,
  Row,
  Col,
  Label,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Form,
} from 'reactstrap'
import QRcode from 'qrcode.react'
import './addDevice.scss'
import './editDeviceModal.scss'

function EditDeviceModal(props) {
  const jwt = require('jsonwebtoken')
  const oldDevice = props.device
  const [device, setDevice] = useState(props.device)
  const [newPassword, setNewPassword] = useState('')
  const [zoneDropDownOpen, setZoneDropdown] = useState(false)
  const [qrData, setQrdata] = useState('{}')
  const toggleZone = () => setZoneDropdown((prevState) => !prevState)

  useEffect(() => {
    const token = jwt.sign(
      { deviceName: props.device.deviceName },
      'thisisthesecretkey',
    )
    let data = {
      device: oldDevice,
      token: token,
      domain: axios.defaults.baseURL,
    }

    if (axios.defaults.baseURL === 'http://localhost:5000') {
      data = { ...data, domain: 'https://logbook-pwa-dev.timbergrove.com' }
    }
    let unencrypted = JSON.stringify(data)

    setQrdata(unencrypted)
  }, [])

  return (
    <div className="card-wrapper delModalOverlay">
      <Card className="bg-white m-7 overflow-auto p-5">
        <CardHeader className="d-flex justify-content-between align-items-center pb-4">
          <h3 className="mb-0">Edit Device ({device.deviceName})</h3>
          <Button
            onClick={() => {
              props.setIsShowing(false)
            }}
          >
            X
          </Button>
        </CardHeader>
        <CardBody className="bg-white pb-5 pl-5 pr-5">
          <Form className="outline-dark pl-1 pr-1 col-12">
            <Row>
              <Col className="col-9">
                <Row className="d-flex align-items-center mb-3">
                  {/** ---------------- Display QR Code Switch -------------- */}
                  <FormGroup className="col mb-0">
                    <InputGroup className="input-group-merge align-items-center">
                      <div className="toggle-switch">
                        <Input
                          type="checkbox"
                          checked={device.qrSignin ? true : false}
                          value={device.qrSignin}
                          className="toggle-switch-checkbox"
                          name="toggleSwitchSigninQR"
                          id="toggleSwitchSigninQR"
                          onChange={(e) => {
                            console.log('qrSignin event', e.target.checked)
                            setDevice({ ...device, qrSignin: e.target.checked })
                          }}
                        />
                        <label
                          className="toggle-switch-label"
                          htmlFor="toggleSwitchSigninQR"
                        >
                          <span className="toggle-switch-inner" />
                          <span className="toggle-switch-switch" />
                        </label>
                      </div>
                      <Label className="ml-2 mb-0">
                        {' '}
                        Mobile Signin QR Code
                      </Label>
                    </InputGroup>
                  </FormGroup>
                  {/** ---------------- Thermal Device Switch -------------- */}
                  <FormGroup className="col mb-0">
                    <InputGroup className="input-group-merge ml-4 align-items-center">
                      <div className="toggle-switch">
                        <Input
                          type="checkbox"
                          checked={device.thermal ? true : false}
                          value={device.thermal}
                          className="toggle-switch-checkbox"
                          name="toggleSwitch"
                          id="toggleSwitch"
                          onChange={(e) => {
                            setDevice({ ...device, thermal: e.target.checked })
                          }}
                        />
                        <label
                          className="toggle-switch-label"
                          htmlFor="toggleSwitch"
                        >
                          <span className="toggle-switch-inner" />
                          <span className="toggle-switch-switch" />
                        </label>
                      </div>
                      <Label className="ml-2 mb-0"> Thermal device</Label>
                    </InputGroup>
                  </FormGroup>
                </Row>
                <Row className="pl-1">
                  <Col className="col">
                    <Row className="d-flex justify-space-between align-items-center">
                      {/** ----------------- QR Code Title Input ------------------ */}
                      <FormGroup className="col">
                        <Label>QR Code Title</Label>
                        <Input
                          type="text"
                          name="qrTitle"
                          value={device.qrTitle}
                          onChange={(e) => {
                            setDevice({ ...device, qrTitle: e.target.value })
                          }}
                        />
                      </FormGroup>
                      {/** ----------------- Days Valid Input ------------------ */}
                      <FormGroup className="col">
                        <Label>Days Valid</Label>
                        <Input
                          type="number"
                          name="daysValid"
                          min="0"
                          max="999"
                          value={device.daysValid ? device.daysValid : '14'}
                          disabled={device.qrSignin ? false : true}
                          onChange={(e) => {
                            setDevice({ ...device, daysValid: e.target.value })
                          }}
                        />
                      </FormGroup>
                      {/** ----------- Zone Assignment Input -------------- */}
                      <FormGroup className="col-3">
                        <Label>Zone Assignment</Label>
                        <InputGroup>
                          <Dropdown
                            isOpen={zoneDropDownOpen}
                            toggle={toggleZone}
                            direction="right"
                          >
                            <DropdownToggle caret>
                              {device.zoneName === ''
                                ? 'Unassigned'
                                : device.zoneName}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                name="zoneDropdown"
                                onClick={() => {
                                  setDevice({
                                    ...device,
                                    zoneName: 'Unassigned',
                                  })
                                }}
                                value={'Unassigned'}
                              >
                                Unassigned
                              </DropdownItem>
                              {props.zones
                                ? props.zones.map((value, index) => {
                                    return (
                                      <DropdownItem
                                        key={index}
                                        name="zoneDropdown"
                                        onClick={() => {
                                          setDevice({
                                            ...device,
                                            zoneName: value.zoneName,
                                          })
                                        }}
                                        value={value.zoneName}
                                      >
                                        {value.zoneName}
                                      </DropdownItem>
                                    )
                                  })
                                : null}
                            </DropdownMenu>
                          </Dropdown>
                        </InputGroup>
                      </FormGroup>
                    </Row>
                    <Row className="d-flex align-items-center mb-4">
                      {/** ------------------ Change Password Input ---------------- */}
                      <FormGroup className="col-6">
                        <Label>Change password</Label>
                        <Input
                          type="password"
                          name="password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </FormGroup>
                      {/** ------------------ QR Custom Code URL Input ---------------- */}
                      <FormGroup className="col-5">
                        <Label>QR Custom Code URL</Label>
                        <Input
                          type="text"
                          name="qrURL"
                          value={device.qrURL}
                          disabled={device.qrSignin ? true : false}
                          onChange={(e) => {
                            setDevice({ ...device, qrURL: e.target.value })
                          }}
                        />
                      </FormGroup>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col className="col-3 pt-4">
                <Col className="text-center">
                  <h4>Scan with mobile app to configure device</h4>
                  <QRcode value={qrData} />
                </Col>
              </Col>
              <Row className="col-12 justify-content-end mb-2">
                <Button
                  size="md"
                  color="light"
                  type="submit"
                  onClick={() => props.setIsShowing(false)}
                >
                  Cancel
                </Button>

                <Button
                  size="md"
                  color={oldDevice === device ? 'primary' : 'success'}
                  onClick={(e) =>
                    props.submitEditDevice(device, oldDevice, e, newPassword)
                  }
                >
                  Save
                </Button>
              </Row>
            </Row>

            {/*
            <Row>
              <Col md="12">
                <h3>{device.deviceName}</h3>
                <div className="container" style={{backgroundColor: "#fff"}}>
                  <div className="row align-items-center pt-3">
                    <FormGroup className="col">
                      <Label className="font-weight-bold">Zone Assignment</Label>
                      <InputGroup>
                        <Dropdown isOpen={zoneDropDownOpen} toggle={toggleZone}>
                          <DropdownToggle caret>
                            {
                              device.zoneName === ""
                                ? "Unassigned"
                                : device.zoneName
                            }
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              name="zoneDropdown"
                              onClick={() => {
                                setDevice({ ...device, zoneName: "Unassigned" });
                              }}
                              value={"Unassigned"}
                            >
                              Unassigned
                            </DropdownItem>
                            {props.zones
                              ? props.zones.map((value, index) => {
                                  return (
                                    <DropdownItem
                                      key={index}
                                      name="zoneDropdown"
                                      onClick={() => {
                                        setDevice({
                                          ...device,
                                          zoneName: value.zoneName,
                                        });
                                      }}
                                      value={value.zoneName}
                                    >
                                      {value.zoneName}
                                    </DropdownItem>
                                  );
                                })
                              : null}
                          </DropdownMenu>
                        </Dropdown>
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="col">
                      <Label className="font-weight-bold">QR Code Title</Label>
                      <Input type="text" name="qrTitle" value={device.qrTitle} onChange={(e) => {setDevice({ ...device, qrTitle: e.target.value})}} />
                    </FormGroup>

                    <FormGroup className="col mb-0">
                      <InputGroup className="input-group-merge ml-4 align-items-center">
                        <div className="toggle-switch">
                          <Input
                            type="checkbox"
                            checked={device.qrSignin ? true : false}
                            value={device.qrSignin}
                            className="toggle-switch-checkbox"
                            name="toggleSwitchSigninQR"
                            id="toggleSwitchSigninQR"
                            onChange={(e) => {
                              console.log('qrSignin event', e.target.checked)
                              setDevice({ ...device, qrSignin: e.target.checked });
                            }}
                          />
                          <label
                            className="toggle-switch-label"
                            htmlFor="toggleSwitchSigninQR"
                            // value={true}
                          >
                            <span className="toggle-switch-inner" />
                            <span className="toggle-switch-switch" />
                          </label>
                        </div>
                        <Label className="font-weight-bold ml-2 mb-0">
                          {" "}
                          Display Mobile Signin QR Code
                        </Label>
                      </InputGroup>
                    </FormGroup>
                  </div>

                  <div className="row align-items-center">
                    <FormGroup className="col">
                      <Label className="font-weight-bold">Days Valid</Label>
                      <Input type="number" name="daysValid" min="0" max="999" value={device.daysValid ? device.daysValid : "14"} disabled={device.qrSignin ? false : true} onChange={(e) => {setDevice({ ...device, daysValid: e.target.value})}} />
                    </FormGroup>

                    <FormGroup className="col">
                      <Label className="font-weight-bold">QR Custom Code URL</Label>
                      <Input type="text" name="qrURL" value={device.qrURL} disabled={device.qrSignin ? true : false} onChange={(e) => {setDevice({ ...device, qrURL: e.target.value})}} />
                    </FormGroup>

                    <FormGroup className="col mb-0">
                      <InputGroup className="input-group-merge ml-4 align-items-center">
                        <div className="toggle-switch">
                          <Input
                            type="checkbox"
                            checked={device.thermal ? true : false}
                            value={device.thermal}
                            className="toggle-switch-checkbox"
                            name="toggleSwitch"
                            id="toggleSwitch"
                            onChange={(e) => {
                              setDevice({ ...device, thermal: e.target.checked });
                            }}
                          />
                          <label
                            className="toggle-switch-label"
                            htmlFor="toggleSwitch"
                            // value={true}
                          >
                            <span className="toggle-switch-inner" />
                            <span className="toggle-switch-switch" />
                          </label>
                        </div>
                        <Label className="font-weight-bold ml-2 mb-0">
                          {" "}
                          Thermal device
                        </Label>
                      </InputGroup>
                    </FormGroup>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mt-2">
              <FormGroup className="col">
                <Label className="font-weight-bold">Change password</Label>
                <Input
                  type="password"
                  name="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </FormGroup>
              <Col className="text-center m-3">
                <h4 className="mb-3">
                  Scan with mobile app to configure device
                </h4>
                <QRcode className="ml-3" value={qrData} />
              </Col>
            </Row>

            <Row className="d-flex justify-content-end py-3 mr-3 buttons">
              <Button
                size="md"
                color="light"
                type="submit"
                onClick={() => props.setIsShowing(false)}
              >
                Cancel
              </Button>

              <Button
                size="md"
                color={oldDevice === device ? "primary" : "success"}
                onClick={(e) => props.submitEditDevice(device, oldDevice, e, newPassword)}
              >
                Save
              </Button>
            </Row>*/}
          </Form>
        </CardBody>
      </Card>
    </div>
  )
}

export default EditDeviceModal
