import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "../addDevice/addDevice.scss";
import axios from "../../axiosconfig";
import { Button, ButtonGroup, Input } from "reactstrap";
import { requestPWChange, QRPrescreenDataInput } from "../../actions/userActions";
import { sendImageToS3 } from "../../actions/mediaActions";
import FileUploader from "./FileUploader";
import validatePresScreenForm from "./validatePreScreenForm";
import SelectSearch from "react-select-search";
import { PrescreenBackground, TimbergroveLogo, GreenCheck, InvalidTokenDiv, HeaderText, LargeText, RegularText,
  CenterItemsDiv, FormDiv, MasterDiv, FormLabel, FormRow, FormField, CompleteDiv,
  HorizontalSeparator } from "./EntryFormComponents"
const url = require("url");

function QREntryForm(props) {
  const staffSelect = useRef()
  const companySelect = useRef()
  const [tokenValid, setTokenValid] = useState(false);
  const [sessionId, setSessionId] = useState(
    Math.floor(Math.random() * 1000000001)
  );
  const [imageId, setImageId] = useState(
    Math.floor(Math.random() * 1000000001)
  );
  const [email, setEmail] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [state, setuState] = useState("");
  const [dob, setDob] = useState("");
  const [idType, setIdType] = useState("License");
  const [idNumber, setIdNumber] = useState();
  const [ts, setTs] = useState(Date.now());
  const [image, setImage] = useState(Math.floor(Math.random() * 1000000001));
  const [selectedFile, setSelectedFile] = useState(null);

  const [completed, setCompleted] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contact, setContact] = useState({})
  const [pointOfContact, setPointOfContact] = useState('')
  const [companyName, setCompanyName] = useState('')

  const [staffContacts, setStaffContacts] = useState([])
  const [companyContacts, setCompanyContacts] = useState([])
  const [staffID, setStaffID] = useState('')
  const [companyID, setCompanyID] = useState('')

  const token = url.parse(props.location.search, true).query.token;

  useEffect(() => {
    getPrescreenStatus(token);
    getStaffContacts();
    getCompanyContacts();
  }, []);

  async function getPrescreenStatus(tok) {
    try {
      const response = await axios({
        url: "/admin/prescreen-status",
        method: "POST",
        data: { token: tok },
      });
      // console.log("status:", response.status);
      if (response.status === 200) {
        // console.log('DATA', response.data)
        setContact(response.data)
        setTokenValid(true);
      } else {
        setTokenValid(false);
      }
    } catch (e) {
      console.log(e);
      setTokenValid(false);
    }
  }

  async function getStaffContacts() {
    const response = await axios({
      url: "/employee/getEmployees",
      method: "POST"
    })
    console.log('staff', response.data)
    setStaffContacts(response.data)
  }

  async function getCompanyContacts() {
    const response = await axios({
      url: "/company/getCompanies",
      method: "POST"
    })
    console.log('comps', response.data)
    setCompanyContacts(response.data)
  }

  async function handleImageUpload(file) {
    getBase64(file).then((result) => {
      console.log("file selected: ", result);
      setSelectedFile(result);
    });
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async function submitUpdates(e) {
    console.log("submit Updates");
    console.log("image:", image);
    e.preventDefault();
    const data = {
      firstName: fName,
      lastName: lName,
      state: state,
      DOB: dob,
      idType: idType,
      number: idNumber,
      timestamp: ts,
      image: imageId,
      imageId: imageId,
      sessionId: sessionId,
      pointOfContact: staffID,
      companyName: companyID
    };

    setErrors(validatePresScreenForm(data));

    if (Object.keys(errors).length === 0) {
      props.QRPrescreenDataInput(data);

      setCompleted(true);
      setTokenValid(false);

      props.sendImageToS3(selectedFile, sessionId, imageId);
    }
  }

  const filterContact = (contacts) => {
    return (searchValue) => {
      return contacts.filter(c => c.name.toLowerCase().includes(searchValue.toLowerCase()))
    }
  }

  return (
    <PrescreenBackground>
      <TimbergroveLogo src={require('../../assets/tgs-logo.png')} />
      {!completed ? (
        <>
          <CenterItemsDiv>
            <HeaderText>Prescreen Entry</HeaderText>
            <MasterDiv>
              <FormDiv onSubmit={submitUpdates}>
                <FormRow>
                  <FormField>
                    <FormLabel>First Name*</FormLabel>
                      <Input
                        style={{background: "#F4F7FA"}}
                        placeholder="Enter First Name"
                        type="text"
                        name="firstName"
                        value={fName}
                        onChange={(e) => setFName(e.target.value)}
                        autoFocus
                        required
                      />
                    {errors.firstName && <p className="error">{errors.firstName}</p>}
                  </FormField>

                  <FormField>
                    <FormLabel>Last Name*</FormLabel>
                      <Input
                        style={{background: "#F4F7FA"}}
                        placeholder="Enter Last Name"
                        type="text"
                        name="lastName"
                        value={lName}
                        onChange={(e) => setLName(e.target.value)}
                        required
                      />
                    {errors.lastName && <p className="error">{errors.lastName}</p>}
                  </FormField>
                </FormRow>

                <FormRow>
                  <FormField>
                    <FormLabel>Date of Birth*</FormLabel>
                      <Input
                        style={{background: "#F4F7FA"}}
                        placeholder="Enter Date of Birth"
                        type="text"
                        name="dob"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        required
                      />
                    {errors.dob && <p className="error">{errors.dob}</p>}
                  </FormField>

                  {contact.email ?
                    <FormField>
                      <FormLabel>Email</FormLabel>
                        <Input
                          style={{background: "#F4F7FA"}}
                          placeholder="Enter Email"
                          type="text"
                          name="email"
                          value={contact.email}
                          // onChange={(e) => setContact({email: e.target.value})}
                          required
                        />
                    </FormField>
                  :
                    <FormField>
                      <FormLabel>Phone Number</FormLabel>
                        <Input
                          style={{background: "#F4F7FA"}}
                          placeholder="Enter Phone Number"
                          type="text"
                          name="sms"
                          value={contact.sms}
                          // onChange={(e) => setContact({email: e.target.value})}
                          required
                        />
                    </FormField>
                  }
                </FormRow>

                <FormRow>
                  {/* <FormField>
                    <FormLabel>Contact Name*</FormLabel>
                      <Input
                        style={{background: "#F4F7FA"}}
                        placeholder="Name of Person Visiting"
                        type="text"
                        name="pointOfContact"
                        value={pointOfContact}
                        onChange={(e) => setPointOfContact(e.target.value)}
                        autoFocus
                        required
                      />
                  </FormField> */}

                  <FormField>
                    <FormLabel>Contact Name*</FormLabel>
                    <SelectSearch
                      ref={staffSelect}
                      options={staffContacts.map(staff => {
                        return {name: `${staff.firstName} ${staff.lastName}`, value: `${staff._id}`}
                      })}
                      filterOptions={filterContact}
                      onChange={(e) => setStaffID(e)}
                      value={staffID}
                      placeholder="Name of Person Visiting"
                      className="searchselect"
                      printOptions="on-focus"
                      search />
                  </FormField>

                  {/* <FormField>
                    <FormLabel>Company Name*</FormLabel>
                      <Input
                        style={{background: "#F4F7FA"}}
                        placeholder="Name of Company Visiting"
                        type="text"
                        name="companyName"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        required
                      />
                  </FormField>
                </FormRow> */}

                  <FormField>
                    <FormLabel>Company Name*</FormLabel>
                      <SelectSearch
                        ref={companySelect}
                        options={companyContacts.map(company => {
                          return {name: `${company.company}`, value: `${company._id}`}
                        })}
                        filterOptions={filterContact}
                        onChange={(e) => setCompanyID(e)}
                        value={companyID}
                        placeholder="Name of Company Visiting"
                        className="searchselect"
                        printOptions="on-focus"
                        search />
                  </FormField>
                </FormRow>

                <HorizontalSeparator />

                <ButtonGroup style={{margin: "6px"}}>
                  <Button color="secondary" onClick={() => setIdType('License')} active={idType === 'License'}>License</Button>
                  <Button color="secondary" onClick={() => setIdType('Passport')} active={idType === 'Passport'}>Passport</Button>
                </ButtonGroup>

                <FormRow>
                  <FormField>
                    <FormLabel>Id Number*</FormLabel>
                      <Input
                        style={{background: "#F4F7FA"}}
                        placeholder="Enter ID Number"
                        type="password"
                        name="idNumber"
                        value={idNumber}
                        onChange={(e) => setIdNumber(e.target.value)}
                        required
                      />
                    {errors.idType && <p className="error">{errors.idType}</p>}
                  </FormField>

                  <FormField style={{width: "160px", flexGrow: 0}}>
                    <FormLabel>State*</FormLabel>
                      <Input
                        style={{background: "#F4F7FA"}}
                        placeholder="Enter State"
                        type="text"
                        name="state"
                        value={state}
                        onChange={(e) => setuState(e.target.value)}
                        required
                      />
                    {errors.state && <p className="error">{errors.state}</p>}
                  </FormField>
                </FormRow>

                <HorizontalSeparator />

                <FormField>
                  <FormLabel>Picture*</FormLabel>
                    <FileUploader
                      onFileSelectSuccess={handleImageUpload}
                      onFileSelectError={({ error }) => alert(error)}
                      required={true}
                    />
                </FormField>

                <Button size="md" color="success" style={{color: "white", margin: "6px"}} type="submit">Submit</Button>
                <FormLabel>* Indicates a Required Field</FormLabel>
              </FormDiv>

            </MasterDiv>
          </CenterItemsDiv>
        </>
      ) : (
        <>
          <CenterItemsDiv>
            <HeaderText>Attention</HeaderText>
            <MasterDiv>
              <CompleteDiv>
                <GreenCheck src={require('../../assets/greenCheck.png')} />
                <LargeText>Survey Completed</LargeText>
                <RegularText>Check your email or text messages for entry code</RegularText>
              </CompleteDiv>
            </MasterDiv>
          </CenterItemsDiv>
        </>
      )}
      </PrescreenBackground>
  );
}

export default connect(null, {
  requestPWChange,
  QRPrescreenDataInput,
  sendImageToS3,
})(QREntryForm);
