import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import "../securityApp.scss";

const PaginationTable = ({
  per_page,
  total_data,
  handleClick,
  current_page,
}) => {
  let pageNumbers = [];

  for (let i = 1; i <= Math.ceil(total_data / per_page); i++) {
    pageNumbers.push(i);
  }
  // console.log("current-page:", current_page);
  // console.log(current_page <= 1);

  return (
    <Pagination className="pagination-nav">
      {current_page <= 1 ? null : (
        <PaginationItem>
          <PaginationLink
            onClick={() => handleClick(current_page - 1)}
            previous
            href="#"
          />
        </PaginationItem>
      )}

      {pageNumbers.length === 1 ? null : (
        <>
          {pageNumbers.map((i) => (
            <PaginationItem key={i} active={current_page === i ? true : false}>
              <PaginationLink
                onClick={() => handleClick(i)}
                href="#"
              >
                {i}
              </PaginationLink>
            </PaginationItem>
          ))}
        </>
      )}

      {current_page === pageNumbers.length ? null : (
        <PaginationItem>
          <PaginationLink
            onClick={() => {
              console.log("current-page:", current_page);
              handleClick(current_page + 1);
            }}
            next
            href="#"
          />
        </PaginationItem>
      )}
    </Pagination>
  );
};

export default PaginationTable;
