export default function validateZone(values) {
    let errors = {};
    // let emailRegex = /[@a-zA-z0-9\.]/gm;
  
    if (!values.zoneName) {
      errors.zoneName = "Zone name is required";
    } else if (values.zoneName.length > 0 && values.zoneName.replaceAll(" ","").length === 0) {
      errors.zoneName = "Zone name must contain characters other than spaces";
    }
  
    return errors;
  }
  