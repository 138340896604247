import styled from 'styled-components';

export const PrescreenBackground = styled.div`
  background-color: #F4F7FA;
  height: 100vh;
`

export const TimbergroveLogo = styled.img`
  width: 170px;
  height: 24px;
  margin: 24px;
`

export const GreenCheck = styled.img`
  width: 80px;
  height: 80px;
  margin: 24px;
`

export const InvalidTokenDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  position: absolute;
  width: 480px;
  height: 116px;
  left: calc(50% - 480px/2);
  top: 120px;
  background: #FFFFFF;
  box-shadow: 0px 80px 40px rgba(0, 0, 0, 0.04), 0px 20px 32px rgba(0, 0, 0, 0.06), 0px 16px 16px rgba(0, 0, 0, 0.04), 0px 0px 10px rgba(0, 0, 0, 0.04);
  border-radius: 16px;

  @media only screen and (max-width: 600px) {
    width: 295px;
    left: calc(50% - 295px/2);
  }
`

export const HeaderText = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 35px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #333333
`

export const LargeText = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 35px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #212529;
`

export const RegularText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #212529;
`

export const CenterItemsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MasterDiv = styled.div`
  width: 796px;
  background: #FFFFFF;
  border-radius: 16px;
  margin-top: 43.5px;

  @media only screen and (max-width: 796px) {
    width: auto;
    left: calc(50% - 295px/2);
    margin: 50px;
    padding: 10px;
  }
`

export const FormDiv = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
  width: 480px;
  margin: 24px 0px 12px 158px;
  background: #FFFFFF;
  border: 1px solid #F4F7FA;
  box-sizing: border-box;
  border-radius: 8px;

  @media only screen and (max-width: 796px) {
    width: auto;
    margin: 50px 5px;
    padding: 10px;
  }
`

export const FormLabel = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-indent: 2px;
  color: #666666;
`

export const FormRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const FormField = styled.div`
  margin: 6px;
  flex-grow: 1;
`

export const CompleteDiv = styled.form`
display: flex;
flex-direction: column;
align-items: center;
padding-top: 100px;
height: 500px;
`

export const HorizontalSeparator = styled.div`
  border-bottom: 1px solid #E9ECEF;
  margin: 6px;
`

