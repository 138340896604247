import React, { useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Brivo from "../Brivo/Brivo";
import BlinkIdPage from "../BlinkIdPage/BlinkIdPage";
import ContactTables from "../ContactManagement/ContactTables";
import { TabButton } from "../TabButton/TabButton";
import { ReactComponent as FlowIcon } from "../../assets/tabs/flow.svg";
import { ReactComponent as BrandingIcon } from "../../assets/tabs/branding.svg";
import { ReactComponent as UserManageIcon } from "../../assets/sidemenu/user_manage.svg";
import { Col, Row, TabContent, TabPane } from "reactstrap";
import SimpleHeader from "../SimpleHeader/SimpleHeader";
import PermissionWrapper from "../permissions/PermissionWrapper";
import { SCOPES } from "../permissions/permission-map";

const Integrations = ({}) => {
  const location = useLocation();
  const history = useHistory();

  const activeTab = useMemo(() => {
    if (location.pathname.includes("/brivo")) return "1";
    else if (location.pathname.includes("/blinkId")) return "2";
    else return "3";
  }, [location]);

  const activeTabTitle = useMemo(() => {
    if (location.pathname.includes("/brivo")) return "Brivo Management";
    else if (location.pathname.includes("/blinkId"))
      return "BlinkID management";
    else return "Contact Management";
  }, [location]);

  return (
    <PermissionWrapper scopes={[SCOPES.integrations]}>
      <div className="ConfigureSteps">
        <SimpleHeader name={activeTabTitle} />
        <div
          style={{ width: "100%", display: "flex", padding: "2.5rem 2rem 0" }}
        >
          <Col xs={9}>
            <Row>
              <TabButton
                className="mr-2"
                name="Brivo"
                Icon={FlowIcon}
                active={location.pathname.includes("/brivo")}
                onClick={() => history.push("/brivo")}
              />
              <TabButton
                className="mr-2"
                name="BlinkID"
                Icon={BrandingIcon}
                active={location.pathname.includes("/blinkId")}
                onClick={() => history.push("/blinkId")}
              />
              <TabButton
                className="mr-2"
                name="Contacts"
                Icon={UserManageIcon}
                active={location.pathname.includes("/contacts")}
                onClick={() => history.push("/contacts")}
              />
            </Row>
          </Col>
        </div>
        <div style={{ padding: "2.5rem 2rem" }}>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Brivo />
            </TabPane>
            <TabPane tabId="2">
              <BlinkIdPage />
            </TabPane>
            <TabPane tabId="3">
              <ContactTables />
            </TabPane>
          </TabContent>
        </div>
      </div>
    </PermissionWrapper>
  );
};

export default Integrations;
