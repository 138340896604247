// Twilio video chat compnent
// developed for twilio-video module version 2.2.0
// based on index.js sample in https://github.com/twilio/video-quickstart-js.git
import React, { Component } from "react";
import { connect } from "react-redux";
import Video from "twilio-video";
import loading from "../../assets/loading.gif";
import "./TwilioVideo.scss";
import {
  getTwilioTokenClient,
  getTwilioTokenSecurity,
  createRoom,
  incomingCall,
  sendRoomInfo,
  destroyRoom,
} from "../../actions/mediaActions";
import { passFlagged, rejectFlagged } from "../../actions/userActions";
import { getSocketEnvironment } from "../../actions/environmentActions";
import { Button } from "reactstrap";
import axios from "../../axiosconfig";

class TwilioVideo extends Component {
  constructor(props) {
    console.log("constructor props:", props);
    super(props);
    this.state = {
      identity: null,
      roomName: "",
      roomNameErr: false, // Track error for room name TextField
      previewTracks: null,
      localMediaAvailable: false,
      hasJoinedRoom: false,
      activeRoom: "", // Track the current active room
      remoteWaiting: true,
      flag: props.flag,
      flagId: props.flagId,
      token: props.token,
    };

    this.joinRoom = this.joinRoom.bind(this);
    this.roomJoined = this.roomJoined.bind(this);
    this.detachParticipantTracks = this.detachParticipantTracks.bind(this);

    if (!this.props.socketURL) {
      this.props.getSocketEnvironment();
    }
  }

  async componentDidMount() {
    if (this.props.user === "client") {
      await this.props.getTwilioTokenClient();
    } else if (this.props.user === "security") {
      await this.props.getTwilioTokenSecurity();
    }

    console.log("flags: ", this.state.flag);
    console.log("flagId:", this.state.flagId);

    this.joinRoom();
    const data = {
      room: this.props.roomName,
    };
    if (this.props.user === "client") {
      console.log("[componentDidMount] sending room info", data);
      this.props.sendRoomInfo(data);
    }
    // attach listener for endCall messages
    this.props.socket.on("endCall", () => {
      this.handleEndCall(false);
    });
  }

  componentWillUnmount() {
    // remove listener for endCall messages
    this.props.socket.removeAllListeners("endCall");
  }

  joinRoom() {
    if (!this.props.roomName.trim()) {
      this.setState({ roomNameErr: true });
      return;
    }

    console.log("[joinRoom] Joining room '" + this.props.roomName + "'...");
    let connectOptions = {
      name: this.props.roomName,
    };

    if (this.state.previewTracks) {
      connectOptions.tracks = this.state.previewTracks;
    }

    console.log("twilio token", this.props.twilioToken);
    Video.connect(this.props.twilioToken, connectOptions).then((room) => {
      this.roomJoined(room);
    });
  }

  // Attach the Track to the DOM.
  attachTrack(track, container) {
    console.log("attach track", track);
    if (track.kind !== "data") {
      container.appendChild(track.attach());
    }
  }

  // Attach array of Tracks to the DOM.
  attachTracks(tracks, container) {
    tracks.forEach((track) => {
      this.attachTrack(track, container);
    });
  }

  // Detach given track from the DOM.
  detachTrack(track) {
    console.log("detach track", track);
    if (track.kind !== "data") {
      track.detach().forEach((element) => {
        element.remove();
      });
    }
  }

  // Appends remoteParticipant name to the DOM.
  appendName(identity, container) {
    const name = document.createElement("p");
    name.id = `participantName-${identity}`;
    name.className = "instructions";
    name.textContent = identity;
    container.appendChild(name);
  }

  // Removes remoteParticipant container from the DOM.
  removeName(participant) {
    if (participant) {
      let { identity } = participant;
      const container = document.getElementById(
        `participantContainer-${identity}`
      );
      container.parentNode.removeChild(container);
    }
  }

  // A new RemoteTrack was published to the Room.
  trackPublished(publication, container) {
    if (publication.isSubscribed) {
      this.attachTrack(publication.track, container);
    }
    publication.on("subscribed", (track) => {
      console.log(
        "[trackPublished] Subscribed to " + publication.kind + " track"
      );
      this.attachTrack(track, container);
    });
    publication.on("unsubscribed", this.detachTrack);
  }

  // A RemoteTrack was unpublished from the Room.
  trackUnpublished(publication) {
    console.log(
      "[trackUnpublished] " + publication.kind + " track was unpublished."
    );
  }

  // A new RemoteParticipant joined the Room
  participantConnected(participant, container) {
    let selfContainer = document.createElement("div");
    selfContainer.id = `participantContainer-${participant.identity}`;

    container.appendChild(selfContainer);
    // this.appendName(participant.identity, selfContainer);

    participant.tracks.forEach((publication) => {
      this.trackPublished(publication, selfContainer);
    });
    participant.on("trackPublished", (publication) => {
      this.trackPublished(publication, selfContainer);
    });
    participant.on("trackUnpublished", this.trackUnpublished);
  }

  // Detach the Participant's Tracks from the DOM.
  detachParticipantTracks(participant) {
    const tracks = this.getTracks(participant);
    tracks.forEach(this.detachTrack);
  }

  // Get the Participant's Tracks.
  getTracks(participant) {
    return Array.from(participant.tracks.values())
      .filter(function (publication) {
        return publication.track;
      })
      .map(function (publication) {
        return publication.track;
      });
  }

  roomJoined(room) {
    // Called when a participant joins a room
    console.log("[roomJoined] Joined as '" + this.props.twilioIdentity + "'");
    console.log(room)
    this.setState({
      activeRoom: room,
      localMediaAvailable: true,
      hasJoinedRoom: true,
    });

    // Attach LocalParticipant's Tracks, if not already attached.
    var previewContainer = this.refs.localMedia;
    console.log('previewContainer', previewContainer, 'this.refs', this.refs)
    if (previewContainer && !previewContainer.querySelector("video")) {
      this.attachTracks(
        this.getTracks(room.localParticipant),
        previewContainer
      );
    }

    // Attach the Tracks of the Room's Participants.
    var remoteMediaContainer = this.refs.remoteMedia;
    console.log('remoteMediaContainer', remoteMediaContainer)
    room.participants.forEach((participant) => {
      console.log(
        "[roomJoined] Already in Room: '" + participant.identity + "'"
      );
      this.participantConnected(participant, remoteMediaContainer);
    });

    // When a Participant joins the Room, attach its Tracks.
    room.on("participantConnected", (participant) => {
      console.log(
        "[roomJoined:participantConnected] Joining: '" +
          participant.identity +
          "'"
      );
      this.participantConnected(participant, remoteMediaContainer);
      this.setState({ remoteWaiting: false });
    });

    // When a Participant leaves the Room, detach its Tracks.
    room.on("participantDisconnected", (participant) => {
      console.log(
        "[roomJoined:participantDisconnected] Participant '" +
          participant.identity +
          "' left the room"
      );
      this.detachParticipantTracks(participant);
      this.removeName(participant);
    });

    // Once the LocalParticipant leaves the room, detach the Tracks
    // of all Participants, including that of the LocalParticipant.
    room.on("disconnected", () => {
      console.log("[roomJoined:disconnected] disconnected");
      if (this.state.previewTracks) {
        this.state.previewTracks.forEach((track) => {
          track.stop();
        });
      }
      this.detachParticipantTracks(room.localParticipant);
      room.participants.forEach(this.detachParticipantTracks);
      this.setState({
        hasJoinedRoom: false,
        localMediaAvailable: false,
        activeRoom: null,
      });
    });
  }

  leaveRoom() {
    this.state.activeRoom.disconnect();
    this.setState({ hasJoinedRoom: false, localMediaAvailable: false });
  }

  async passFlag(flagId) {
    console.log(flagId);
    console.log("this token:", this.state.token);
    try {
      const resp = await axios({
        url: "/admin/passFlagged",
        method: "POST",
        headers: { authorization: `Bearer ${this.state.token}` },
        data: { flagId: flagId },
      });
      if (resp.data) {
        console.log("dat:", resp.data);
      }
    } catch (e) {
      console.log("e", e);
    }
    this.props.history.push("/");
  }

  async rejectFlag(flagId) {
    console.log(flagId);
    console.log("this token:", this.state.token);
    try {
      const resp = await axios({
        url: "/admin/rejectFlagged",
        method: "POST",
        headers: { authorization: `Bearer ${this.state.token}` },
        data: { flagId: flagId },
      });
      if (resp.data) {
        console.log("dat:", resp.data);
      }
    } catch (e) {
      console.log("e", e);
    }
    this.props.history.push("/");
  }

  // clickedEnd is a boolean - true means this call is from End Call button
  // false means this call is from endCall message
  handleEndCall = (clickedEnd) => {
    console.log(`[entering handleEndCall()]`);
    if (this.state.activeRoom) {
      if (clickedEnd) {
        this.props.destroyRoom(this.props.roomName);
      }
      this.leaveRoom();
    }
    console.log(`[in handleEndCall()] this.props.user = ${this.props.user}`);
    if (this.props.user === "security") {
      let data = {
        room: "",
        incomingCall: false,
      };
      this.props.incomingCall(data);
      this.props.history.push("/");
    } else if (this.props.user === "client") {
      this.props.history.push("/welcome");
    }
  };

  render() {
    const userLink =
      this.props.user === "client"
        ? "/welcome"
        : this.props.user === "security"
        ? "/dashboard"
        : "";

    return (
      <>
        <div className="delModalOverlay">
              {this.state.remoteWaiting && this.props.user === "client" ? (
                <div className="loading">
                  <p className="loadingText">Connecting...</p>
                  <img src={loading} alt="loading"></img>
                </div>
              ) : (
                ""
              )}
              <div className="remoteMedia" ref="remoteMedia" id="remote-media">
                <div style={{position: "absolute", bottom: "10px", display: "flex", alignItems: "center"}}>
                  <div ref="localMedia" className="localMedia" style={{margin: "0vh 10vw"}} />
                  <Button onClick={() => this.handleEndCall(true)} className="d-flex" style={{color: "red", margin: "0vh 10vw", zIndex: "1"}}>hang up</Button>
                </div>
              </div>
            {this.state.flag === "" || this.state.flag === undefined ? null : (
              <div className="text-center pb-3 mt-3">
                <Button
                  color="secondary"
                  onClick={() => this.rejectFlag(this.state.flagId)}
                >
                  Fail
                </Button>

                <Button
                  size="md"
                  color="primary"
                  onClick={() => this.passFlag(this.state.flagId)}
                >
                  Pass
                </Button>
              </div>
            )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    twilioIdentity: state.twilioIdentity,
    twilioToken: state.twilioToken,
    roomName: state.roomName,
    canJoinRoom: state.canJoinRoom,
    user: state.user,
    socket: state.socket,
    socketURL: state.socketURL,
    flag: state.flag,
    flagId: state.flagId,
    token: state.token,
  };
};

export default connect(mapStateToProps, {
  getTwilioTokenClient,
  getTwilioTokenSecurity,
  createRoom,
  incomingCall,
  sendRoomInfo,
  destroyRoom,
  getSocketEnvironment,
  passFlagged,
  rejectFlagged,
})(TwilioVideo);