import {
  GET_LANGUAGES_CONFIGURATION,
  GET_LANGUAGES_CONFIGURATION_SUCCESS,
  GET_LANGUAGES_CONFIGURATION_FAILED,
  UPDATE_LANGUAGES_CONFIGURATION,
  UPDATE_LANGUAGES_CONFIGURATION_SUCCESS,
  UPDATE_LANGUAGES_CONFIGURATION_FAILED,
} from "../../actions/languagesConfiguration/types";

const initialState = {
  languagesConfigList: [],
  isLoadingLanguagesConfigList: false,
  isUpdatingLanguagesConfigList: false,
  languagesConfigListError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LANGUAGES_CONFIGURATION:
      return {
        ...state,
        isLoadingLanguagesConfigList: true,
        languagesConfigList: [],
        languagesConfigListError: undefined,
      };
    case GET_LANGUAGES_CONFIGURATION_SUCCESS:
      return {
        ...state,
        isLoadingLanguagesConfigList: false,
        languagesConfigList: action.payload,
      };
    case GET_LANGUAGES_CONFIGURATION_FAILED:
      return {
        ...state,
        isLoadingLanguagesConfigList: false,
        languagesConfigListError: action.payload,
      };
    case UPDATE_LANGUAGES_CONFIGURATION:
      return {
        ...state,
        isUpdatingLanguagesConfigList: true,
      };
    case UPDATE_LANGUAGES_CONFIGURATION_SUCCESS:
      return {
        ...state,
        isUpdatingLanguagesConfigList: false,
      };
    case UPDATE_LANGUAGES_CONFIGURATION_FAILED:
      return {
        ...state,
        isUpdatingLanguagesConfigList: false,
      };
    default:
      return state;
  }
};
