import React, { useEffect, useState } from "react";
import {
  Button,
  CustomInput,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const PictureModal = ({ isOpen, toggle, onAddPicture, defaultValues }) => {
  const [skipPictureForQR, setSkipPictureForQR] = useState(defaultValues?.skipPictureForQR);

  useEffect(() => {
    if (defaultValues?.skipPictureForQR) setSkipPictureForQR(defaultValues?.skipPictureForQR);
  }, [isOpen, defaultValues]);

  const onAdd = () => {
    onAddPicture({
      skipPictureForQR,
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="PictureModal">
      <ModalHeader toggle={toggle}>Portrait Picture</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup style={{ marginLeft: '25px' }}>
            <CustomInput
              onChange={() => setSkipPictureForQR(!skipPictureForQR)}
              checked={!!skipPictureForQR}
              type="switch"
              id='skipPictureForQR'
              name='skipPictureForQR'
              label="Take picture of pre-registered users only"
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
        <Button color="primary" onClick={onAdd}>Add</Button>
      </ModalFooter>
    </Modal>
  );
};

export default PictureModal;
