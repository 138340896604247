import React, { useRef, useCallback } from 'react'
import '../addDevice/addDevice.scss'
import Badge from './Badge'
import ReactToPrint from 'react-to-print'
import { Button, Card, CardHeader, CardBody, Row, Col } from 'reactstrap'

function PrintBadgeModal(props) {
    const badgeRef = useRef(null)

    const handleAfterPrint = useCallback(() => {
        // console.log('`onAfterPrint` called')
        props.setIsShowing(false)
    }, [])

    const handleOnBeforeGetContent = useCallback(() => {
        // console.log("`onBeforeGetContent` called")
    }, [])

    const handleBeforePrint = useCallback(() => {
        // console.log('`onBeforePrint` called')
    }, [])

    const reactToPrintContent = useCallback(() => {
        return badgeRef.current
    }, [badgeRef.current])

    const reactToPrintTrigger = useCallback(() => {
        return <Button size="md" color="primary">Print</Button>
    }, [])

    const getPageStyle = useCallback(() => {
        return ``
    }, [])

    return (
        <div className="card-wrapper delModalOverlay">
            <Card className="bg-white" style={{margin: "7rem 16rem 7rem 16rem"}}>
                <CardHeader className="d-flex justify-content-between align-items-center bg-white border-bottom-0 pb-0">
                    <h3 className="mb-0">Print Badge</h3>
                    <Button onClick={() => props.setIsShowing(false)}>X</Button>
                </CardHeader>
                <CardBody className="bg-white">
                    <Row>
                        <Col md="12" className="d-flex justify-content-center">
                            <Badge ref={badgeRef} user={props.user} />
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-end my-3 mr-3 buttons">
                        <Button size="md" color="light" onClick={() => props.setIsShowing(false)}>Cancel</Button>
                        <ReactToPrint removeAfterPrint
                            content={reactToPrintContent}
                            documentTitle={`${props.user.firstName}-${props.user.lastName}-${props.user.timestamp}`}
                            onAfterPrint={handleAfterPrint}
                            onBeforeGetContent={handleOnBeforeGetContent}
                            onBeforePrint={handleBeforePrint}
                            trigger={reactToPrintTrigger}
                            pageStyle={getPageStyle}
                        />
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
}

export default PrintBadgeModal