import { useState, useEffect } from "react";

const useFormZone = (callback, validateZone) => {
  const [values, setValues] = useState({
    zoneName: ""
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value,
    });
    }
  

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validateZone(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting ) {
      callback(values);
      if (!isSubmitting) {alert("Zone added!")};
      setIsSubmitting(false);
    }
  }, [callback, errors, isSubmitting]);

  return {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
    setErrors,
  };
};

export default useFormZone;
