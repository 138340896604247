import React, { useCallback, useEffect, useState } from 'react'
import '../addDevice/addDevice.scss'
import FileUploader from '../Start/FileUploader'
import { Button, Card, CardHeader, CardBody, FormGroup, Input, Row, Col, Label, Form } from 'reactstrap'
import { components } from 'react-select'
import { default as ReactSelect } from 'react-select'
import ReactCrop, {
    centerCrop,
    makeAspectCrop,
    Crop,
    PixelCrop,
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import getCroppedImg from "./utils";
import { clearImages, getImagesFromS3, viewImages } from "../../actions/mediaActions";
import { useDispatch, useSelector } from "react-redux";
import LoadingIndicator from "../VisitorLog/LoaderSpinner";

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    )
}

function EditZoneModal({zone, zones, setIsShowing, submitEdit}) {
    const [updatedZone, setUpdatedZone] = useState(zone)
    const [crop, setCrop] = useState();
    const [croppedAreaPixels, setCroppedAreaPixels] = useState()
    const [logo, setLogo] = useState('')
    const [splash, setSplash] = useState('')
    const [otherZones, setOtherZones] = useState(false)
    const [optionSelected, setOptionSelected] = useState([])
    const dispatch = useDispatch();
    const picturesLoading = useSelector(state => state.picturesLoading)
    const imageId = useSelector(state => state.imageId)

    useEffect(() => {
        if (zone?.logo) {
            dispatch(viewImages())
            dispatch(getImagesFromS3(undefined, zone?.logo?.split(':')[1], zone?.logo?.split(':')[0]))
        }
        return () => dispatch(clearImages());
    }, []);

    useEffect(() => {
        setLogo(imageId);
    }, [imageId]);

    const handleZoneUpdate = (e) => {
        setUpdatedZone({
            ...updatedZone,
            [e.target.name]: e.target.value
        })
    }

    const getBase64 = (file, storeage) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => storeage(reader.result)
        reader.onerror = (error) => alert(error)
    }

    const getZoneOptions = () => {
        return zones.filter(z => z._id != zone._id).map(z => {
            return {value: z._id, label: z.zoneName}
        })
    }

    return (
        <div className="card-wrapper delModalOverlay">
            <Card className="bg-white my-7" style={{marginLeft: "auto", marginRight: "auto", minWidth: "520px", overflowY: 'auto'}}>
                <CardHeader className="d-flex justify-content-between align-items-center bg-white border-bottom-0 pb-0">
                    <h3 className="mb-0">Edit Zone Configuration</h3>
                    <Button onClick={() => {setIsShowing(false)}}>X</Button>
                </CardHeader>
                <CardBody className="bg-white">
                    <Form className="outline-dark" onSubmit={async (e) => {
                        e.preventDefault();
                        let croppedImage;
                        if (logo) {
                            if (croppedAreaPixels) {
                                croppedImage = await getCroppedImg(
                                  logo,
                                  croppedAreaPixels,
                                );
                            } else {
                                const image = new Image();
                                image.src = logo;
                                croppedImage = await getCroppedImg(
                                  logo,
                                  {
                                      height: image.height,
                                      unit: "px",
                                      width: image.width,
                                      x: 0,
                                      y: 0,
                                  },
                                );
                            }
                            submitEdit(e, updatedZone, croppedImage, splash, optionSelected)
                        } else {
                            submitEdit(e, updatedZone, croppedImage, splash, optionSelected)
                        }
                    }}>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Zone Name</Label>
                                    <Input type="text" name="zoneName" defaultValue={zone.zoneName} value={updatedZone.zoneName} onChange={(e) => handleZoneUpdate(e)} disabled />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Building / Company Name</Label>
                                    <Input type="text" name="buildingName" defaultValue={zone.buildingName} value={updatedZone.buildingName} onChange={(e) => handleZoneUpdate(e)} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Logo</Label>
                                    <FileUploader onFileSelectSuccess={(file) => getBase64(file, setLogo)} onFileSelectError={({ error }) => alert(error)} required={false} />
                                    {picturesLoading ? (
                                      <LoadingIndicator />
                                    ) : (logo ? <div style={{
                                        position: 'relative',
                                        margin: '20px 0',
                                    }}>
                                        <p>Crop image:</p>
                                        <ReactCrop
                                          crop={crop}
                                          onChange={(_, percentCrop) => setCrop(percentCrop)}
                                          onComplete={(c) => setCroppedAreaPixels(c)}
                                          keepSelection
                                        >
                                            <img src={logo} alt="" />
                                        </ReactCrop>
                                    </div> : null)}
                                </FormGroup>
                                <FormGroup>
                                    <Label>Splash Screen</Label>
                                    <FileUploader onFileSelectSuccess={(file) => getBase64(file, setSplash)} onFileSelectError={({ error }) => alert(error)} required={false} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Apply this configuration to other zones</Label>
                                    <div className="d-flex align-items-center" style={{maxWidth: "460px"}}>
                                        <input type="radio" id="otgerNo" name="otherZone" onChange={() => setOtherZones(false)} defaultChecked />
                                        <label for="otherNo" className="mb-0 mx-2">No</label>
                                        <input type="radio" id="otherYes" name="otherZone" onChange={() => setOtherZones(true)} />
                                        <label for="otherYes" className="mb-0 mx-2">Yes</label>
                                        {otherZones ?
                                            <ReactSelect
                                                options={getZoneOptions()}
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={true}
                                                components={{Option}}
                                                onChange={(selected) => setOptionSelected(selected)}
                                                allowSelectAll={true}
                                                value={optionSelected}
                                                placeholder="Select Zones"
                                                isMulti
                                                isSearchable
                                            />
                                        : null}
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-end py-3 mr-3 buttons">
                            <Button size="md" color="light" onClick={() => setIsShowing(false)}>Cancel</Button>
                            <Button size="md" color="primary" type="submit">Save</Button>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}

export default EditZoneModal
