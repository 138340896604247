import React from 'react'
import { FormGroup, InputGroup, Input, Label } from 'reactstrap'
import "../addDevice/addDevice.scss"

function ToggleSwitch(props) {
    return (
        <FormGroup>
            <InputGroup className="input-group-merge ml-4">
                <div className="toggle-switch">
                <Input
                    type="checkbox"
                    checked={props.checked ? true : false}
                    value={props.value}
                    className="toggle-switch-checkbox"
                    name={props.id}
                    id={props.id}
                    onChange={(e) => props.cb(props.cbReturn, e.target.checked)}
                />
                <label className="toggle-switch-label" htmlFor={props.id}>
                    <span className="toggle-switch-inner" />
                    <span className="toggle-switch-switch" />
                </label>
                </div>
                <Label className="font-weight-bold ml-2">
                    {` ${props.label}`}
                </Label>
            </InputGroup>
        </FormGroup>
    )
}

export default ToggleSwitch