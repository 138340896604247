export default function validateLogin(values) {
  let errors = {};
  // let emailRegex = /[@a-zA-z0-9\.]/gm;

  if (!values.username) {
    errors.username = "username is required";
  }
  if (!values.uPassword) {
    errors.password = "password is required";
  }
  if (values.username && values.uPassword) {
    //console.log('no errors')
    delete errors.usernmame;
    delete errors.password;
  }
  //TODO
  //if(!compare username + password with db credentials){
  // errors.authentication = "username or password is incorrect"

  return errors;
}
