import openSocket from "socket.io-client";
import { onConnect } from "./userActions";
import * as actions from "./types";
import axios from "../axiosconfig";

export const getSocketEnvironment = () => async (dispatch) => {
  try {
    const response = await axios({
      url: "/environment",
      method: "GET",
    });
    //console.log(JSON.stringify(response));
    dispatch({
      type: actions.GET_SOCKET_URL,
      payload: response.data.socketURL,
    });
    const socket = openSocket(response.data.socketURL);

    if (socket) {
      dispatch(onConnect(socket));
    } else {
      console.log("unable to open socket: " + socket);
    }
  } catch (e) {
    console.log(e, "error");
  }
};
