import React, { useState, useEffect } from "react";
import { FaPhone } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroup,
  Row,
  Col,
  Label,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Form,
} from "reactstrap";

function IncomingCallModal(props) {
  let history = useHistory();
  const [flagText, setFlagText] = useState("");

  useEffect(() => {
    let flag_string = "";
    if (props.flag !== "") {
      if (props.flag.indexOf("q") !== -1) {
        flag_string = flag_string + "failing the questionaire";
      }
      if (props.flag.indexOf("t") !== -1) {
        if (flag_string.length > 0) {
          flag_string = flag_string + ", and ";
        }
        flag_string =
          flag_string + "the thermometer detected a temperature over 100°F";
      }
    }
    setFlagText(flag_string);
  }, [props]);

  return (
    <>
      <div className="delModalOverlay">
        <Card className="bg-white delModal pt-15  align-items-center height: 28vh">
          <CardHeader className="d-flex justify-content-between border-bottom-0 text-center">
            {/* <h3 className="mb-0">Admin Delete Confirmation Page</h3> */}
            <Col>
              <Row md="12">
                <h1>Incoming Call...</h1>
              </Row>
              <Row md="12">
                <h2>From: {props.caller}</h2>
              </Row>
              <Row>
                {props.flag === "" ? (
                  <h6 color="warning">User has not been flagged.</h6>
                ) : (
                  <h3>User is flagged for {flagText}.</h3>
                )}
              </Row>
            </Col>
          </CardHeader>
          <CardBody className="d-flex justify-content-around w-100">
            <Row className="d-flex justify-content-between w-100" xs="12">
              <Col className="d-flex justify-content-around">
                <div className="incomingCallBox">
                  <div className="ringing-phone-accept">
                    <FaPhone
                      onClick={() => {
                        props.answerCall();
                        history.push("/videoChat", { flagId: props.flagId });
                      }}
                    />
                  </div>
                </div>

                <div className="incomingCallBox2">
                  <div className="ringing-phone-decline">
                    <FaPhone onClick={props.ignoreCall} />
                  </div>
                </div>
              </Col>
            </Row>
            <br />
            <br />
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default IncomingCallModal;
