import { useState, useEffect } from "react";

const useFormPreRegistration = (callback, validateAddDevice) => {
  const [values, setValues] = useState({
    email: "",
    sms: "",
    commType: "email",
    daysValid: 14
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(event);
    console.log(name, value);

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    console.log("inside useFormPreReg", e);
    e.preventDefault();
    setErrors(validateAddDevice(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback(values);
      if (!isSubmitting) {
        alert("Device added!");
      }
      setIsSubmitting(false);
    }
  }, [callback, errors, isSubmitting]);

  return {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
    setErrors,
  };
};

export default useFormPreRegistration;
