export default function validateAdmin(values) {
  let errors = {};
  let emailRegex = /[@a-zA-z0-9\.]/gm;

  var validator = require("email-validator");


  if (!values.firstName) {
    errors.firstName = "First name is required";
  } else if (values.firstName.length > 0 && values.firstName.replaceAll(" ","").length === 0) {
    errors.firstName = "Name must contain characters other than spaces";
  }

  if (!values.lastName) {
    errors.lastName = "Last name is required";
  } else if (values.lastName.length > 0 && values.lastName.replaceAll(" ","").length === 0) {
    errors.lastName = "Name must contain characters other than spaces";
  }

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!validator.validate(values.email)) {
    errors.email = "please enter a valid email";
  }

  if (!values.username) {
    errors.username = "Username is required";
  } else if (values.username.length > 0 && values.username.replaceAll(" ","").length === 0) {
    errors.username = "User Name must contain characters other than spaces";
  }

  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 5) {
    errors.password = "Password must be at least 6 characters long";
  } else if (values.password.includes(' ')) {
    errors.password = "Passwords cannot include blank spaces"
  }

  return errors;
}
