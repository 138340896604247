import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import { networkUpdate } from "../actions/userActions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./securityApp.scss";
import Login from "./Start/Login";
import Sidenav from "./Sidenav/Sidenav";
import AddDevice from "./addDevice/addDevice";
import CreateAdmin from "./CreateAdmin/CreateAdmin";
import Dashboard from "./Dashboard/Dashboard";
import { ManualOnboarding } from "./ManualOnboarding";
import VisitorLog from "./VisitorLog/VisitorLog";
import TwilioVideo from "./TwilioVideo/TwilioVideo";
import AddZone from "./AddZone/AddZone";
import IncomingCallModal from "./IncomingCallModal";
import Brivo from "./Brivo/Brivo";
import BlinkIdPage from "./BlinkIdPage/BlinkIdPage";
import ConfigureSteps from "./ConfigureSteps/ConfigureSteps";
import ContactTables from "./ContactManagement/ContactTables";
//import ResetPasswordForm from "./Start/ResetPasswordForm";

import notificationSound from "../sounds/notification.mp3";
import {
  createRoom,
  searchForCall,
  incomingCall,
  answerIncomingCall,
} from "../actions/mediaActions";
import { defineUser, findUsers, autoLogin } from "../actions/userActions";
import { getSocketEnvironment } from "../actions/environmentActions";

import "react-perfect-scrollbar/dist/css/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/vendor/nucleo/css/nucleo.css";
import "../assets/vendor/font-awesome/css/font-awesome.css";
import "../assets/scss/argon-dashboard-pro-react.scss?v1.1.0";
import ForgotPasswordForm from "./Start/ForgotPasswordForm";
import ResetPasswordForm from "./Start/ResetPasswordForm";
import PrescreenEntryForm from "./Start/PrescreenEntryForm";
import QREntryForm from "./Start/QREntryForm";
import EmployeeEntryForm from "./Start/EmployeeEntryForm";
import SigninEntryForm from "./Start/SigninEntryForm";
import history from "../history";
import { Router } from "react-router";
import Integrations from "./Integrations";

class App extends Component {
  constructor(props) {
    super(props);
    this.props.autoLogin(() => {});
    this.state = {
      sidenavOpen: true,
      audioElem: new Audio(notificationSound),
      caller: "",
      flag: "",
      flagId: "",
    };

    if (!this.props.socketURL) {
      this.props.getSocketEnvironment();
    }
  }

  async componentDidMount() {
    this.state.audioElem.loop = true;

    if (
      window.location.pathname === "/dashboard" &&
      !this.props.isAuthenticated
    ) {
      window.location.href = "/";
    }

    this.props.defineUser("security");
    await this.props.searchForCall();
    if (this.props.socket) {
      // on getting a call
      this.props.socket.on("incomingCall", (data) => {
        console.log("incoming call:", data);
        if (!this.props.isAuthenticated) {
          console.log("unauthenticated users cannot accept incoming calls");
          return;
        }

        if (window.location.href.split("/").slice(-1)[0] === "videoChat") {
          console.log("user cannot accept another call while in a call.");
          return;
        }

        if (data.zone && this.props.admin.zoneName) {
          console.log(
            "looking at call zones:",
            data.zone,
            this.props.admin.zoneName
          );
          if (data.zone !== this.props.admin.zoneName) {
            console.log("call out of zone");
            return;
          }
        }

        this.setState({ caller: data.caller });
        if (data.flag) {
          this.setState({ flag: data.flag });
        } else {
          this.setState({ flag: "" });
        }
        if (data.regId) {
          this.setState({ flagId: data.regId });
        } else {
          this.setState({ flagId: "" });
        }
        if (data && data.incomingCall) {
          this.state.audioElem.play();
          this.props.incomingCall(data);
        } else if (!data.incomingCall) {
          this.state.audioElem.pause();
          this.props.incomingCall(data);
        }
      });
      // on new user entered location
      // this.props.socket.on("newEntry", () => {
      //   this.handleNewEntry();
      // });

      // on admin account updated
      this.props.socket.on("adminUpdated", (data) => {
        if (
          this.props.isAuthenticated &&
          this.props.admin._id === data.settings._id
        ) {
          this.props.networkUpdate(data.settings);
        }
      });

      // on server query network for active stations
      this.props.socket.on("rollCall", (deets) => {
        if (this.props.isAuthenticated) {
          let me = {
            _id: this.props.admin._id,
            username: this.props.admin.username,
            client: "admin",
            zone: this.props.admin.zoneName,
          };
          //console.log("checking in!:", me);
          this.props.socket.emit("checkin", me);
        }
      });
    } else {
      console.log("app unable to locate socket");
    }
  }

  componentWillUnmount() {
    this.props.socket.removeAllListeners("endCall");
    if (this.state.audioElem) {
      this.state.audioElem.pause();
    }
    this.props.socket.removeAllListeners("newEntry");
    this.props.socket.removeAllListeners("rollCall");
    this.props.socket.removeAllListeners("adminUpdated");
  }

  toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-show");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      document.body.classList.add("g-sidenav-show");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen,
    });
  };

  openSidenav = () => {
    if (document.body.classList.contains("g-sidenav-hidden")) {
      document.body.classList.remove("g-sidenav-hidden");
    }
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-show");
    }
  };

  closeSidenav = () => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-show");
    }
    if (!document.body.classList.contains("g-sidenav-hidden")) {
      document.body.classList.add("g-sidenav-hidden");
    }
    this.setState({ sidenavOpen: false });
  };

  answerCall = () => {
    console.log("inside answerCall here");
    this.props.answerIncomingCall(true);
    //this.props.history.push("/videoChat")
    if (this.state.audioElem) {
      this.state.audioElem.pause();
    }
  };

  ignoreCall = () => {
    console.log("ignore call button clicked here");
    this.props.answerIncomingCall(false);

    if (this.state.audioElem) {
      this.state.audioElem.pause();
    }
  };

  render() {
    return (
      <>
        {this.props.isAuthenticated ? (
          <div>
            <Router history={history}>
              <Sidenav
                {...this.props}
                toggleSidenav={this.toggleSidenav}
                openSidenav={this.openSidenav}
                closeSidenav={this.closeSidenav}
                sidenavOpen={this.state.sidenavOpen}
              />
            </Router>
            <div className="main-content" ref="mainContent">
              <BrowserRouter>
                <Switch>
                  <Route exact path="/" component={Login} />
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route
                    exact
                    path="/manual-onboarding"
                    component={ManualOnboarding}
                  />
                  <Route exact path="/visitor-log" component={VisitorLog} />
                  <Route exact path="/addDevice" component={AddDevice} />
                  <Route exact path="/createAdmin" component={CreateAdmin} />
                  <Route exact path="/addZone" component={AddZone} />
                  <Route
                    exact
                    path="/configureSteps"
                    component={ConfigureSteps}
                  />
                  <Route exact path="/contacts" component={Integrations} />
                  <Route exact path="/brivo" component={Integrations} />
                  <Route exact path="/blinkId" component={Integrations} />
                  <Route
                    exact
                    path="/videoChat"
                    component={TwilioVideo}
                    flag={this.state.flag}
                    flagId={this.state.flagId}
                  />
                </Switch>
                {this.props.incomingCallBoolean ? (
                  <IncomingCallModal
                    answerCall={this.answerCall}
                    ignoreCall={this.ignoreCall}
                    caller={this.state.caller}
                    flag={this.state.flag}
                    flagId={this.state.flagId}
                  />
                ) : null}
              </BrowserRouter>
            </div>
            {this.state.sidenavOpen ? (
              <div
                className="backdrop d-xl-none"
                onClick={() => this.closeSidenav}
              />
            ) : null}
          </div>
        ) : (
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route
                exact
                path="/forgotPassword"
                component={ResetPasswordForm}
              />
              <Route exact path="/prescreen" component={PrescreenEntryForm} />
              <Route exact path="/qr-entry" component={QREntryForm} />
              <Route
                exact
                path="/qrsignin/:deviceID"
                component={SigninEntryForm}
              />
              <Route exact path="/employeeinfo" component={EmployeeEntryForm} />
            </Switch>
          </BrowserRouter>
        )}
        <ToastContainer
          position="top-center"
          hideProgressBar
          style={{ zIndex: 100000 }}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.isAuthenticated,
    socket: state.socket,
    socketURL: state.socketURL,
    admin: state.admin,
    incomingCallBoolean: state.incomingCall,
    flag: state.flag,
    // answerIncomingCall: state.incomingCall
  };
};

export default connect(mapStateToProps, {
  autoLogin,
  createRoom,
  defineUser,
  searchForCall,
  incomingCall,
  findUsers,
  getSocketEnvironment,
  networkUpdate,
  answerIncomingCall,
})(App);
