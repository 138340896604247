import {
  GET_STEPS_CONFIGURATION,
  GET_STEPS_CONFIGURATION_SUCCESS,
  GET_STEPS_CONFIGURATION_FAILED,
  UPDATE_STEPS_CONFIGURATION,
  UPDATE_STEPS_CONFIGURATION_SUCCESS,
  UPDATE_STEPS_CONFIGURATION_FAILED,
} from "../../actions/stepsConfiguration/types";

const initialState = {
  stepsConfigList: [],
  isLoadingStepsConfigList: false,
  isUpdatingStepsConfigList: false,
  configListError: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STEPS_CONFIGURATION:
      return {
        ...state,
        isLoadingStepsConfigList: true,
        stepsConfigList: [],
        configListError: undefined,
      };
    case GET_STEPS_CONFIGURATION_SUCCESS:
      return {
        ...state,
        isLoadingStepsConfigList: false,
        stepsConfigList: action.payload,
      };
    case GET_STEPS_CONFIGURATION_FAILED:
      return {
        ...state,
        isLoadingStepsConfigList: false,
        configListError: action.payload,
      };
    case UPDATE_STEPS_CONFIGURATION:
      return {
        ...state,
        isUpdatingStepsConfigList: true,
      };
    case UPDATE_STEPS_CONFIGURATION_SUCCESS:
      return {
        ...state,
        isUpdatingStepsConfigList: false,
      };
    case UPDATE_STEPS_CONFIGURATION_FAILED:
      return {
        ...state,
        isUpdatingStepsConfigList: false,
      };
    default:
      return state;
  }
};
