import React, { useRef, useState } from "react";
import "./createAdmin.scss";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroup,
  Row,
  Col,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
} from "reactstrap";
// import axios from "axios";
import ConfirmAdminDeleteModal from "./ConfirmAdminDeleteModal";
import useClickOutsideListenerRef from "./useClickOutsideListenerRef";

function EditAdminModal(props) {
  //console.log("values to edit-modal: " + JSON.stringify(props.values));
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [values, setValues] = useState(props.values);
  const zones = props.zones;

  const [showModal, setShowModal] = useState(false);
  const [zoneDropDownOpen, setZoneDropdown] = useState(false);
  // const ref = useClickOutsideListenerRef(()=>setShowModal(false))
  const ref = useRef(false);

  const oldValues = props.values;
  const oldPage = props.page;
  const auth_levels = ["admin", "manager"];

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleZone = () => setZoneDropdown((prevState) => !prevState);

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    if (name === "isAdmin") {
      setValues({
        ...values,
        isAdmin: !values.isAdmin,
      });
    } else if (name === "zoneDropdown") {
      setValues({
        ...values,
        zoneName: value,
      });
    } else if (name.includes("dropdown")) {
      setValues({
        ...values,
        authType: value,
      });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  function submit() {
    //Validate the update form fields here

    // if (!values.email) {
    //   errors.email = "Email is required";
    // } else if (!validator.validate(values.email)) {
    //   errors.email = "please enter a valid email";
    // }

    if (values === oldValues) {
      alert("You must make changes in order to update.");
      props.back();
      return null;
    }
    console.log("Submitting", JSON.stringify(values));
    props.updateAdmin(values, oldPage);
  }

  const validator = () => {
    console.log("inside validator, values are: ", values);
    console.log("inside validator, values are: ", typeof values.lastName);

    let err = {};

    let emailRegex = /[@a-zA-z0-9\.]/gm;

    var email_val = require("email-validator");

    if (!values.firstName || values.firstName === "") {
      //setErrors({...errors, firstName: "First name is required"})
      err = { ...err, firstName: "First name is required" };
    } else if (
      values.firstName.length > 0 &&
      values.firstName.replaceAll(" ", "").length === 0
    ) {
      //setErrors({...errors, })
      err = {
        ...err,
        firstName: "Name must contain characters other than spaces",
      };
    }

    if (!values.lastName) {
      //console.log("last name is blank");
      //console.log("errors are: ", errors);
      //setErrors({...errors, })
      err = { ...err, lastName: "Last name is required" };
    } else if (
      values.lastName.length > 0 &&
      values.lastName.replaceAll(" ", "").length === 0
    ) {
      //setErrors({...errors, })
      err = {
        ...err,
        lastName: "Name must contain characters other than spaces",
      };
    }

    if (!values.email || values.email === "") {
      console.log("email is blank");
      //setErrors({...errors, });
      err = { ...err, email: "Email is required" };
    } else if (!email_val.validate(values.email)) {
      //setErrors({...errors, });
      err = { ...err, email: "please enter a valid email" };
    }

    if (!values.username || values.username === "") {
      //setErrors({...errors, });
      err = { ...err, username: "Username is required" };
    } else if (
      values.username.length > 0 &&
      values.username.replaceAll(" ", "").length === 0
    ) {
      //setErrors({...errors,});
      err = {
        ...err,
        username: "User Name must contain characters other than spaces",
      };
    }

    setErrors(err);
    console.log(" errors are: ", err);
    if (Object.keys(err).length === 0) {
      submit();
    } else {
      console.log("found errors, not submitting.");
    }

    return err;
  };

  function remove() {
    console.log("requesting delete of: " + JSON.stringify(values));
    props.removeAdmin(values, oldPage);
  }

  // useClickOutsideListenerRef(ref, () => {
  //   console.log("inside useClickOutsideListenerRef in editadminmodal");
  //   // setShowModal(false);
  //   props.back();
  // });

  return (
    <Col md="12">
      <div className="card-wrapper delModalOverlay">
        <Card className="bg-white">
          <CardHeader
            className="bg-white"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* <h3 className="mb-0">Edit {values.authType.charAt(0).toUpperCase() + values.authType.substring(1)} User</h3> */}
            <h3 className="mb-0">Edit User</h3>
            <Button
              onClick={() => {
                console.log("button clicked!");
                props.back();
              }}
            >
              X
            </Button>
          </CardHeader>
          <CardBody style={{ backgroundColor: "white" }}>
            <Form
              onSubmit={(e) => {
                validator();
                e.preventDefault();
                //console.log("submit edits");
              }}
            >
              <Row>
                <Col md="12">
                  <FormGroup>
                    <div>
                      <Label className="font-weight-bold">
                        {" "}
                        {values.authType.charAt(0).toUpperCase() +
                          values.authType.substring(1)}{" "}
                        Name
                      </Label>
                    </div>
                    <Row xs={12}>
                      {/* <div style={{display: "flex"}}> */}
                      <Col xs={6}>
                        <InputGroup className="input-group-merge input-group-alternative">
                          <Input
                            type="text"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            placeholder="First name"
                            xs="6"
                            inline="true"
                          />
                        </InputGroup>
                        {errors.firstName && (
                          <p className="error">{errors.firstName}</p>
                        )}
                      </Col>
                      <Col xs={6}>
                        <InputGroup className="input-group-merge input-group-alternative">
                          <Input
                            type="text"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            placeholder="Last name"
                            xs="6"
                            inline="true"
                          />
                        </InputGroup>
                        {errors.lastName && (
                          <p className="error">{errors.lastName}</p>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label className="font-weight-bold">
                      *
                      {values.authType.charAt(0).toUpperCase() +
                        values.authType.substring(1)}{" "}
                      Email
                    </Label>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <Input
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </InputGroup>
                    {errors.email && <p className="error">{errors.email}</p>}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label className="font-weight-bold">
                      *
                      {values.authType.charAt(0).toUpperCase() +
                        values.authType.substring(1)}{" "}
                      Username
                    </Label>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <Input
                        type="text"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                      />
                    </InputGroup>
                    {errors.username && (
                      <p className="error">{errors.username}</p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Row xs="12">
                      <Col xs="6">
                        <Label className="font-weight-bold">
                          Zone Assignment
                        </Label>
                        <InputGroup>
                          <Dropdown
                            isOpen={zoneDropDownOpen}
                            toggle={toggleZone}
                            style={{ width: "100%" }}
                          >
                            <DropdownToggle caret style={{ width: "100%" }}>
                              {values.zoneName ? values.zoneName : "Unassigned"}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                name="zoneDropdown"
                                onClick={handleChange}
                                value={"Unassigned"}
                              >
                                Unassigned
                              </DropdownItem>
                              {props.zones
                                ? props.zones.map((value, index) => {
                                    //console.log(value);
                                    return (
                                      <DropdownItem
                                        key={index}
                                        name="zoneDropdown"
                                        onClick={handleChange}
                                        value={value.zoneName}
                                      >
                                        {value.zoneName}
                                      </DropdownItem>
                                    );
                                  })
                                : null}
                              {/* <DropdownItem>
                              <Button
                                onClick={() => {
                                  console.log("add zone clicked");
                                  toggle();
                                }}
                              >
                                + Add new zone...
                              </Button>
                            </DropdownItem> */}
                            </DropdownMenu>
                          </Dropdown>
                        </InputGroup>
                      </Col>
                      {/* ) : null} */}
                      <Col xs="6">
                        <Label className="font-weight-bold">Access Level</Label>
                        <InputGroup>
                          <Dropdown
                            isOpen={dropdownOpen}
                            toggle={toggle}
                            style={{ width: "100%" }}
                          >
                            <DropdownToggle caret style={{ width: "100%" }}>
                              {values.authType.charAt(0).toUpperCase() +
                                values.authType.substring(1)}
                            </DropdownToggle>
                            <DropdownMenu>
                              {auth_levels.map((value, index) => {
                                return (
                                  <DropdownItem
                                    key={index}
                                    name="dropdown"
                                    onClick={handleChange}
                                    value={value}
                                  >
                                    {value.charAt(0).toUpperCase() +
                                      value.substring(1)}
                                  </DropdownItem>
                                );
                              })}
                            </DropdownMenu>
                          </Dropdown>
                        </InputGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12" className="mb-2">
                  <small>* Please change a field </small>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div className="text-right">
                    <Button
                      size="md"
                      color="light"
                      onClick={() => {
                        props.back();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="md"
                      color={oldValues === values ? "primary" : "success"}
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Col>
  );
}

export default EditAdminModal;
