import React, { useState } from "react";
import "./createAdmin.scss";
// import { removeDevice } from "../../actions/userActions";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  InputGroup,
  Row,
  Container,
  Col,
  Table,
  CardFooter,
  Label,
} from "reactstrap";

function ConfirmAdminDeleteModal(props) {
  const { adminToDelete, setToggleDelAdmin, remove, page } = props;

  return (
    <div className="delModalOverlay">
      <Card className="bg-white delModalAdmin height: 28vh">
        <CardHeader className="d-flex justify-content-between border-bottom-0">
          <h3 className="mb-0">Admin Delete Confirmation Page</h3>
          <Button
            onClick={() => {
              // console.log("button clicked!");
              setToggleDelAdmin(false);
            }}
          >
            X
          </Button>
        </CardHeader>
        <CardBody>
          <form>
            <Row>
              <Col md="12">
                <h3 className="pb-3" style={{ color: "darkgrey" }}>
                  Are you sure you want to delete {adminToDelete.firstName}{" "}
                  {adminToDelete.lastName}?
                </h3>
                {/* <div></div> */}
                <div className="text-right mt-3 mb-3 buttons">
                  <Button
                    size="md"
                    color="light"
                    type="submit"
                    // className="saveButton"
                    style={{
                      // backgroundColor: "#2DCE89",
                      color: "white",
                    }}
                    onClick={() => setToggleDelAdmin(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="md"
                    color="primary"
                    onClick={() => remove(adminToDelete, page)}
                    // className="deleteButton"
                    style={{
                      backgroundColor: "#EB3446",
                      color: "white",
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    </div>
  );
}

export default ConfirmAdminDeleteModal;
