import React, { useEffect, useState } from "react";
import { Button, CustomInput, Form, FormGroup, Input, InputGroup, InputGroupAddon, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { LANG_CODE_TO_LABEL } from "./MessageModal";
import { FormRow } from "../Start/EntryFormComponents";

const QuestionModal = ({ isOpen, toggle, onAddQuestion, defaultValues }) => {
  const [selectType, setSelectType] = useState('single');
  const [question, setQuestion] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [answerText, setAnswerText] = useState('');
  const [interruptOn, setInterruptOn] = useState(null);
  const [showSignature, setShowSignature] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    if (defaultValues?.selectType) setSelectType(defaultValues?.selectType);
    if (defaultValues?.question) setQuestion(defaultValues?.question);
    if (defaultValues?.additionalInfo) setAdditionalInfo(defaultValues?.additionalInfo);
    if (defaultValues?.interruptOn) setInterruptOn(defaultValues?.interruptOn);
    if (defaultValues?.showSignature) setShowSignature(defaultValues?.showSignature);
    if (defaultValues?.answers) setAnswers(defaultValues?.answers);
    if (defaultValues?.translations) setTranslations(defaultValues?.translations);
  }, [isOpen]);

  const onAddAnswer = () => {
    if (!answers.includes(answerText)) {
      setAnswers([...answers, answerText]);
      setAnswerText('');
    }
  };

  const onRemoveAnswer = (answerText) => {
    setAnswers(answers => answers.filter(answer => answer !== answerText));
  };

  const onAdd = () => {
    onAddQuestion({
      question,
      additionalInfo,
      selectType,
      answers,
      interruptOn,
      translations,
    });
  };

  const addLang = (lang) => {
    setTranslations({
      ...translations,
      [lang]: {
        question: '',
        answers: [],
      },
    })
  };

  const updateTranslation = (lang, key, value) => {
    setTranslations({
      ...translations,
      [lang]: {
        ...translations[lang],
        [key]: value,
      },
    })
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="QuestionModal">
      <ModalHeader toggle={toggle}>Add question</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="question">Question</Label>
            <Input
              className="QuestionModal-questionInput"
              type="textarea"
              name="question"
              id="question"
              value={question}
              placeholder="Write down a question for the visitor"
              onChange={(e) => setQuestion(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="additionalInfo">Additional info</Label>
            <Input
              className="QuestionModal-additionalInfoInput"
              type="textarea"
              name="additionalInfo"
              id="additionalInfo"
              value={additionalInfo}
              placeholder="Write down a additional info for the right column"
              onChange={(e) => setAdditionalInfo(e.target.value)}
            />
          </FormGroup>
          <div className="mb-4">
            {Object.entries(LANG_CODE_TO_LABEL).filter(([lang]) => !Object.keys(translations).includes(lang)).map(([lang, label]) => (
              <Button color="secondary" onClick={() => addLang(lang)}>Add version in {label}</Button>
            ))}
          </div>
          {Object.entries(translations).map(([lang, { question }]) => (
            <FormGroup>
              <Label for="question">Question ({LANG_CODE_TO_LABEL[lang]})</Label>
              <Input
                className="QuestionModal-questionInput"
                type="textarea"
                name="question"
                id="question"
                value={question}
                placeholder="Write down a question for the visitor"
                onChange={(e) => updateTranslation(lang, 'question', e.target.value)}
              />
            </FormGroup>
          ))}

          <FormGroup>
            <Label for="types" check>Select type</Label>
            <div>
              <CustomInput type="radio" id="exampleCustomRadio" name="single" value='single' label="Yes or No" checked={selectType === 'single'} onChange={(e) => setSelectType(e.target.name)} />
              <CustomInput type="radio" id="exampleCustomRadio2" name="multi" value='multi' label="Multiple Choice" checked={selectType === 'multi'} onChange={(e) => setSelectType(e.target.name)} />
              <CustomInput type="radio" id="exampleCustomRadio3" name="open" value='open' label="Open" checked={selectType === 'open'} onChange={(e) => setSelectType(e.target.name)} />
            </div>
          </FormGroup>
          {selectType === 'single' && (
            <>
              <FormGroup check style={{ marginLeft: '5px' }}>
                <CustomInput onChange={e => setInterruptOn(e.target.checked ? 'yes' : null)} checked={!!interruptOn} type="switch" id="exampleCustomSwitch" name="customSwitch" label="Interrupt flow on given answer" />
              </FormGroup>
              <FormGroup check>
                <CustomInput type="radio" id="yes" name="yes" value={interruptOn === 'yes'} label="If answer is Yes" checked={interruptOn === 'yes'} onChange={e => setInterruptOn(e.target.name)} />
                <CustomInput type="radio" id="no" name="no" value={interruptOn === 'no'} label="If answer is No" checked={interruptOn === 'no'} onChange={e => setInterruptOn(e.target.name)} />
              </FormGroup>
            </>
          )}
          {selectType === 'multi' && (
            <>
              <FormGroup>
                <InputGroup>
                  <Input value={answerText} onChange={(e) => setAnswerText(e.target.value)} />
                  <InputGroupAddon addonType="prepend">
                    <Button onClick={onAddAnswer}><i className="fa fa-plus ml-auto mr-2" /></Button>
                  </InputGroupAddon>
                </InputGroup>
                <div className="QuestionModal-answers">
                  {answers.map(ans => <div className="QuestionModal-answers-item">
                    {ans}
                    <i className="fa fa-close ml-1" onClick={() => onRemoveAnswer(ans)} />
                  </div>)}
                </div>
              </FormGroup>
              {Object.entries(translations).map(([lang, { answers: answersLocalized = [] }]) => (
                <>
                  <Label for="question" className="font-weight-bold mb-4">Answers localized in ({LANG_CODE_TO_LABEL[lang]})</Label>
                  {answers.map((ans, index) => (
                    <FormRow className="align-items-center">{ans} -> <Input style={{ width: '80%' }} value={answersLocalized[index]} onChange={(e) => {
                      const newArray = translations[lang].answers || [];
                      newArray[index] = e.target.value;
                      setTranslations({
                        ...translations,
                        [lang]: {
                          ...translations[lang],
                          answers: [...newArray],
                        },
                      });
                    }} />
                    </FormRow>
                  ))}
                </>
              ))}
            </>
          )}
          {selectType === 'open' && (
            <p className="QuestionModal-info">The user will we prompted to write down an answer</p>
          )}
          <FormGroup style={{ marginLeft: '25px', marginTop: '20px' }}>
            <CustomInput
              onChange={() => setShowSignature(!showSignature)}
              checked={!!showSignature}
              type='switch'
              id='showSignature'
              name='showSignature'
              label="Show signature pad"
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
        <Button color="primary" onClick={onAdd}>Add</Button>
      </ModalFooter>
    </Modal>
  );
};

export default QuestionModal;
