import concatenateReducers from 'redux-concatenate-reducers';

export const filteredReducer = (reducer) => {
  let knownKeys = Object.keys(reducer(undefined, { type: '@@FILTER/INIT' }));

  return (state, action) => {
    let filteredState = state;

    if (knownKeys.length && state !== undefined) {
      filteredState = knownKeys.reduce((current, key) => {
        current[key] = state[key];
        return current;
      }, {});
    }

    let newState = reducer(filteredState, action);

    let nextState = state;

    if (newState !== filteredState) {
      knownKeys = Object.keys(newState);
      nextState = {
        ...state,
        ...newState,
      };
    }

    return nextState;
  };
};

const filterReducer = (reducer) => {
  let lastState = undefined;
  return (state, action) => {
    if (lastState === undefined || state === undefined) {
      lastState = reducer(state, action);
      return lastState;
    }
    const filteredState = {};
    Object.keys(lastState).forEach( (key) => {
      filteredState[key] = state[key];
    });
    const newState = reducer(filteredState, action);
    lastState = newState;
    return newState;
  };
}

export const mergeReducers = (...reducers) => concatenateReducers(reducers.map((reducer) => filterReducer(reducer)));
