import lodash from 'lodash';

export const getCurrentSort = (sort, colName) => sort?.col === colName ? (sort.desc ? 'desc' : 'asc') : undefined;

export const sortArrByColumn = (e, sortState, initArr, mapColumnToDataName) => {
  const { col, desc } = sortState;
  const colSelected = e.target.dataset?.sort;
  if (colSelected) {
    const isDesc = colSelected === col ? !desc : false;
    const sortedArr = lodash.sortBy(
      initArr,
      (item) => {
        if (mapColumnToDataName) {
          return typeof mapColumnToDataName[colSelected] === 'function' ? mapColumnToDataName[colSelected](item) : mapColumnToDataName[colSelected];
        }
        return colSelected;
      },
    ) || [];
    const orderedArr = isDesc ? sortedArr.reverse() : sortedArr;
    return {
      newSort: {
        col: colSelected,
        desc: isDesc,
      },
      newArr: orderedArr,
    };
  }
  return null;
}

export const formatPhoneNumber = (number) => {
  let rawNumberArray = number.split('')
  let formatedNumberArray = []
  rawNumberArray = rawNumberArray.filter(n => !isNaN(parseInt(n)))
  rawNumberArray.forEach((n, i) => {
      if(i == 3 || i == 6) formatedNumberArray.push('-')
      if(i < 10) formatedNumberArray.push(n)
  })
  return formatedNumberArray.join('')
}
