import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import ConfigList from './ConfigList'
import Branding from './Branding'
import './ConfigureSteps.scss'
import { TabButton } from "../TabButton/TabButton";
import { ReactComponent as FlowIcon } from "../../assets/tabs/flow.svg";
import { ReactComponent as BrandingIcon } from "../../assets/tabs/branding.svg";
import { ReactComponent as LanguageIcon } from "../../assets/tabs/language.svg";
import { Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Col, Row,
    TabContent, TabPane } from 'reactstrap'
import { Languages } from "./Languages";

const ConfigureTabs = ({isLoadingStepsConfigList, configListError, steps, onSortEnd, changeMoveStatus,
    moveStatus, onEdit, onDelete, zones, zoneDropdownOpen, toggleZoneDropdown, currentZone, zonesLoading,
    setCurrentZone, addStep, addZone}) => {
    const history = useHistory();
    const tabInSearchParams = new URLSearchParams(history.location.search)?.get('tab');
    const [activeTab, setActiveTab] = useState(tabInSearchParams || '1');

    useEffect(() => {
        const searchParams = new URLSearchParams(history.location.search);
        searchParams.set('tab', activeTab);
        history.replace({ search: searchParams.toString() });
    }, [activeTab]);

    return (
        <>
            <div style={{ width: "100%", display: "flex", marginBottom: '1.5rem' }}>
                <Col xs={9}>
                    <Row>
                        <TabButton
                            className="mr-2"
                            name="Flow"
                            Icon={FlowIcon}
                            active={activeTab === '1'}
                            onClick={() => setActiveTab('1')}
                        />
                        <TabButton
                            className="mr-2"
                            name="Branding"
                            Icon={BrandingIcon}
                            active={activeTab === '2'}
                            onClick={() => setActiveTab('2')}
                        />
                        <TabButton
                            className="mr-2"
                            name="Languages"
                            Icon={LanguageIcon}
                            active={activeTab === '3'}
                            onClick={() => setActiveTab('3')}
                        />
                    </Row>
                </Col>
                {activeTab === '1' || activeTab === '3' ?
                    <div className="w-100 d-flex justify-content-end">
                        <Dropdown isOpen={zoneDropdownOpen} toggle={toggleZoneDropdown}>
                            <DropdownToggle caret>
                                {currentZone}
                            </DropdownToggle>
                            <DropdownMenu>
                                {zonesLoading ? (
                                    <div style={{ textAlign: 'center' }}>
                                    <Spinner color='primary' style={{ width: '3rem', height: '3rem' }} />
                                    </div>) : zones.map((zone) => (
                                    <DropdownItem key={zone._id} onClick={() => setCurrentZone(zone.zoneName)}>{zone.zoneName}</DropdownItem>
                                    )
                                )}
                            </DropdownMenu>
                        </Dropdown>
                        {activeTab === '1' ? <Button color="primary" style={{height: '43px'}} onClick={addStep}>
                            <i className="fa fa-plus ml-auto mr-2" />
                            Add step
                        </Button> : null}
                    </div>
                : null}
                {activeTab === '2' ?
                    <div className="w-100 d-flex justify-content-end">
                        <Button color="primary" style={{height: '43px'}} onClick={() => addZone(true)}>
                            <i className="fa fa-plus ml-auto mr-2" />
                            Add Zone
                        </Button>
                    </div>
                : null}
            </div>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    {isLoadingStepsConfigList
                        ? <div style={{ textAlign: 'center' }}><Spinner color='primary' style={{ width: '3rem', height: '3rem' }} /></div>
                        : (configListError ? (
                        <p className="ConfigureSteps-error">
                            Error while fetching list: {configListError}. Try to Reload the page
                        </p>
                        ) : (
                            <ConfigList
                                steps={steps}
                                onSortEnd={onSortEnd}
                                changeMoveStatus={changeMoveStatus}
                                moveStatus={moveStatus}
                                onEdit={onEdit}
                                onDelete={onDelete}
                            />
                      ))}
                </TabPane>
                <TabPane tabId="2">
                    <Branding zones={zones} />
                </TabPane>
                <TabPane tabId="3">
                    <Languages currentZone={currentZone} />
                </TabPane>
            </TabContent>
        </>
    )
}

export default ConfigureTabs
