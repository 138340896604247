import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";

const fieldsMap = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Date of Birth', key: 'dob' },
  { label: 'Document number', key: 'documentNumber' },
  { label: 'Email', key: 'email' },
  { label: 'Organization', key: 'org' },
  { label: 'Visiting Contact Name', key: 'visitingName' },
];

const ScanIdModal = ({ isOpen, toggle, onAddIdScan, defaultValues }) => {
  const [storableFields, setStorableFields] = useState([]);

  useEffect(() => {
    if (defaultValues?.fields) {
      setStorableFields(defaultValues.fields.filter(field => field.storable).map(field => field.key));
    }
  }, [isOpen, defaultValues]);

  const onChangeStorable = (fieldName) => {
    if (storableFields.includes(fieldName)) {
      setStorableFields(storableFields.filter(field => field !== fieldName));
    } else {
      setStorableFields([...storableFields, fieldName]);
    }
  };

  const onAdd = () => {
    onAddIdScan({
      fields: fieldsMap.map(field => ({
        key: field.key,
        storable: storableFields.includes(field.key),
      })),
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="QuestionModal">
      <ModalHeader toggle={toggle}>Scan Id</ModalHeader>
      <ModalBody>
        <Form>
          {fieldsMap.map(field => (
            <FormGroup key={field.key}>
              <Label for={field.key}>{field.label}</Label>
              <Row form style={{ alignItems: 'baseline', justifyContent: 'space-between' }}>
                <Col md={12}>
                  <FormGroup check>
                    <CustomInput
                      onChange={() => onChangeStorable(field.key)}
                      checked={!!storableFields.includes(field.key)}
                      type="switch"
                      id={`storable-${field.key}`}
                      name={`storable-${field.key}`}
                      label="Storable"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
          ))}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
        <Button color="primary" onClick={onAdd}>Add</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ScanIdModal;
