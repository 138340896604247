import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import axios from '../../axiosconfig'
import SimpleHeader from '../SimpleHeader/SimpleHeader'
import { Container, Form, FormGroup, Label, Input, Col, Button, Card, CardBody } from 'reactstrap'

function BlinkIdPage() {
  const token = useSelector((state) => state.token);
  const [blinkIdLicenseKey, setBlinkIdLicenseKey] = useState('');
  const [blinkIdLicenseExpirationDate, setBlinkIdLicenseExpirationDate] = useState('');

  const initData = async () => {
    const res = await axios({
      url: "/blink-id/status",
      method: "GET",
      headers: { authorization: `Bearer ${token}` },
    });
    setBlinkIdLicenseKey(res?.data?.blinkIdLicenseKey);
    setBlinkIdLicenseExpirationDate(res?.data?.blinkIdLicenseExpirationDate);
  }
  useEffect(() => {
    initData();
  }, []);

  const handleSave = async () => {
    const res = await axios({
      url: "/blink-id/update",
      method: "POST",
      data: { blinkIdLicenseKey, blinkIdLicenseExpirationDate },
      headers: { authorization: `Bearer ${token}` },
    });
    if (res?.status === 200) {
      toast.success("BlinkID license key was updated", {
        position: "bottom-center",
        className: "ConfigureSteps-toast",
        icon: false,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <div>
        <Card>
          <CardBody>
            <Form>
              <FormGroup row>
                <Label for="key" sm={3}>BlinkID license key</Label>
                <Col sm={5}>
                  <Input type="text" name="key" id="key" value={blinkIdLicenseKey} onChange={(e) => setBlinkIdLicenseKey(e.target.value)} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="expiration" sm={3}>BlinkID license expiration date</Label>
                <Col sm={5}>
                  <Input type="text" name="expiration" id="expiration" value={blinkIdLicenseExpirationDate} onChange={(e) => setBlinkIdLicenseExpirationDate(e.target.value)} />
                </Col>
              </FormGroup>
              <FormGroup check row>
                <Col sm={{ size: 10, offset: 2 }}>
                  <Button onClick={() => handleSave()}>Save</Button>
                </Col>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export default BlinkIdPage
