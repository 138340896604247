import React, { useState } from 'react'
import '../addDevice/addDevice.scss'
import { Button, Card, CardHeader, CardBody, FormGroup, Input, Row, Col, Label, Form } from 'reactstrap'

function EditGroupModal(props) {
    const [newResetTime, setNewResetTime] = useState(props.group.resetTime)

    const convertToTime = (cron) => {
        console.log('cron', cron)
        const cronArr = cron.split(' ')
        const minute = cronArr[0]
        const hour = cronArr[1]
        const time = new Date(0, 0, 0, hour, minute, 0, 0)
        const timeStr = time.toString().split(' ')[4]
        const hourStr = timeStr.split(':')[0]
        const minuteStr = timeStr.split(':')[1]
        return `${hourStr}:${minuteStr}`
    }

    const handleResetTimeChange = (time) => {
        console.log('time', time, time.split(':'))
        const minute = time.split(':')[1]
        const hour = time.split(':')[0]
        setNewResetTime(`${minute} ${hour} * * *`)
    }

    return (
        <div className="card-wrapper delModalOverlay">
            <Card className="bg-white" style={{margin: "7rem 16rem 7rem 16rem"}}>
                <CardHeader className="d-flex justify-content-between align-items-center bg-white border-bottom-0 pb-0">
                    <h3 className="mb-0">Edit Group</h3>
                    <Button onClick={() => props.setIsShowing(false)}>X</Button>
                </CardHeader>
                <CardBody className="bg-white">
                    <Form className="outline-dark">
                        <Row>
                            <Col md="12">
                                <h3>{props.group.groupName}</h3>
                                <div className="d-flex justify-content-center align-items-center">
                                    <FormGroup>
                                        <Label className="font-weight-bold">User Reset Time</Label>
                                        <Input type="time" name="resetTime" value={convertToTime(newResetTime)} onChange={(e) => handleResetTimeChange(e.target.value)} />
                                    </FormGroup>
                                </div>
                            </Col>
                        </Row>
                        <Row className="d-flex justify-content-end my-3 mr-3 buttons">
                            <Button size="md" color="light" type="submit" onClick={() => props.setIsShowing(false)}>Cancel</Button>
                            <Button size="md" color="success" onClick={() => props.handleSaveGroup(props.group, newResetTime)}>Save</Button>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </div>
    )
}

export default EditGroupModal