export default function validateResetPwForm(values) {
    let errors = {};
    // let emailRegex = /[@a-zA-z0-9\.]/gm;
  
    if (!values.password1) {
      errors.password1 = "please enter new password";
    } else if (values.password1.length < 6 ) {
      errors.password1 = "password must be at least 6 characters"
    }
    if (!values.password2) {
      errors.password2 = "please re-enter new password";
    } else if (values.password2.length < 6 ) {
      errors.password2 = "password must be at least 6 characters"
    }
    // if (values.password1 != values.password2){
    //     errors.password3 = "passwords don't match"
    // }
    if (values.password1 !== values.password2){
        errors.password3 = "passwords don't match"
    }

    return errors;
  }
  