import React, { useState } from 'react'
import Papa from 'papaparse'
import '../addDevice/addDevice.scss'
import { formatPhoneNumber } from '../../utils'
import { HorizontalSeparator } from '../Start/EntryFormComponents'
import { Button, Card, CardHeader, CardBody, FormGroup, Input, Row, Label, Form, Table } from 'reactstrap'
import { InputFilled } from "../ManualOnboarding/InputFilled";

function MultiCreateEmployeeModal(props) {
    const [expirationDays, setExpirationDays] = useState('60')
    const [employees, setEmployees] = useState([])

    const handleFileInput = (e) => {
        Papa.parse(e.target.files[0], {
            header: true,
            complete: parsedCSV
        })
    }

    const parsedCSV = (file) => {
        setEmployees(parsedEmployees(file.data))
    }

    const parsedEmployees = (rawEmployees) => {
        return rawEmployees.map(employee => {
            return {
                ...employee,
                phone: formatPhoneNumber(employee.phone),
                company: getCompanyID(employee.company),
                expirationDays: expirationDays
            }
        })
    }

    const getCompanyID = (company) => {
        const searchCompany = props.companies.find(comp => formatCompanyName(comp.company).includes(formatCompanyName(company)))
        return searchCompany._id || ''
    }

    const getCompanyName = (id) => {
        const searchCompany = props.companies.find(comp => comp._id == id)
        return searchCompany.company
    }

    const formatCompanyName = (name) => {
        return name.toLowerCase().replaceAll(' ', '').replaceAll(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
    }

    const saveUsers = (e) => {
        e.preventDefault()
        employees.forEach(employee => {
            props.handleSave(e, employee)
        })
        props.setIsShowing(false)
    }

    return (
        <div className="card-wrapper delModalOverlay ContactManagementModal">
            <Card className="bg-white" style={{margin: "7rem 16rem 7rem 16rem"}}>
                <CardHeader className="d-flex justify-content-between align-items-center bg-white border-bottom-0 pb-0">
                    <h3 className="mb-0 font-weight-400">Upload employee list</h3>
                    <div style={{ cursor: 'pointer' }} onClick={() => props.setIsShowing(false)}>x</div>
                </CardHeader>
                <CardBody className="bg-white">
                    <Form className="outline-dark" onSubmit={(e) => saveUsers(e)}>
                        <Row className="d-flex justify-content-around">
                            <FormGroup>
                                <Label className="font-weight-400">Upload employee CSV</Label>
                                <div className="csvUpload">
                                    <Input type="file" name="employees" onChange={handleFileInput} required />
                                    <div>{employees?.length ? 'Selected employees file' : 'Select File'}</div>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <InputFilled className="fieldRight" label="Days valid*" type="number" placeholder="Days valid" name="expirationDays" value={expirationDays} min="1" max="365" onChange={(e) => setExpirationDays(e.target.value)} required />
                            </FormGroup>
                        </Row>
                        <Row className="d-flex justify-content-end my-3 mr-3 buttons">
                            <Button color="secondary" size="md" onClick={() => props.setIsShowing(false)}>Cancel</Button>
                            <Button color="primary" size="md" type="submit">Save</Button>
                        </Row>
                    </Form>

                    {employees.length > 0 ?
                        <>
                            <HorizontalSeparator />
                            <Table className="align-items-center table-flush bg-white">
                                <thead className="thead-light">
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Phone Number</th>
                                        <th>Email</th>
                                        <th>Company</th>
                                    </tr>
                                </thead>
                                <tbody className="list">
                                    {employees.map((employee, index) => {
                                        return (
                                            <tr key={index} bgcolor={index % 2 ? '#F4F7FA' : 'white'}>
                                                <td>{employee.firstName}</td>
                                                <td>{employee.lastName}</td>
                                                <td>{employee.phone}</td>
                                                <td>{employee.email}</td>
                                                <td>{getCompanyName(employee.company) || '-'}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </>
                    : null}
                </CardBody>
            </Card>
        </div>
    )
}

export default MultiCreateEmployeeModal
