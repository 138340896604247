import React, { useState, useEffect } from "react";
import axios from "../../axiosconfig";
import { connect } from "react-redux";

import { Button, Card, Row, Container, Col } from "reactstrap";
import SimpleHeader from "../SimpleHeader/SimpleHeader";
import useForm from "./useFormZone";
import validateZone from "./validateZone";
import { addZone, removeZone, findZones } from "../../actions/userActions";
import { getSocketEnvironment } from "../../actions/environmentActions";
import ZoneConfirmDeleteModal from "./ZoneConfirmDeleteModal";
import PaginationTable from "../VisitorLog/TablePagination";
import AddZoneModal from "./AddZoneModal";
import { sortArrByColumn } from "../../utils";
import { Table } from "../Table/Table";
import PermissionWrapper from "../permissions/PermissionWrapper";
import { SCOPES } from "../permissions/permission-map";

function AddZone(props) {
  const {
    handleChange,
    handleSubmit,
    values,
    setValues,
    errors,
    setErrors,
  } = useForm(submit, validateZone);

  const zonesPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [zones, setZones] = useState(props.zones);
  const [currStatus, setCurrStatus] = useState([]);
  const [currentZonePage, setZonePage] = useState(1);
  const [zoneToEdit, setZoneToEdit] = useState();
  const [zoneDeleteModal, setZoneDeleteModal] = useState(false);
  const [showAddZoneModal, setShowAddZoneModal] = useState(false);
  const [hovered, setHovered] = useState(false);

  const [sort, setSort] = useState({
    col: null,
    desc: false,
  });

  const getStatus = async () => {
    try {
      const response = await axios({
        url: "/status",
        method: "GET",
        headers: { authorization: `Bearer ${props.token}` },
      });
      if (response.data) {
        console.log("CURRENT STATUS 1", response.data);
        setCurrStatus(response.data);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const getZones = async () => {
    try {
      const response = await axios({
        url: "/admin/findZones",
        method: "GET",
        headers: { authorization: `Bearer ${props.token}` },
      });
      console.log("fetch zones:", response.data);
      if (response.data) {
        let arr = [...response.data];
        arr.forEach((zone, index) => {
          zone.number = index + 1;
        });
        setZones(arr);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const remZone = (zone, pg) => {
    console.log("zone remove confirmed:", zone);
    props.removeZone(zone, props.token);
    setTimeout(() => {
      getZones();
      setZoneDeleteModal(false);
      setCurrentPage(pg);
    }, 50);
  };

  const requestRemoveZone = (zone) => {
    setZoneToEdit(zone);
    setZoneDeleteModal(true);
  };

  function submit(zone) {
    if (zone) {
      props.addZone(zone, props.token);
      setErrors({});
      alert(`zone ${values.zoneName} added`);
      setValues({ ...values, zoneName: "" });
      setTimeout(() => {
        getZones();
        setShowAddZoneModal(false);
      }, 250);
    } else {
      alert("no zone to add");
    }
  }

  useEffect(() => {
    getZones();
  }, [props.zones]);

  useEffect(() => {
    setTimeout(() => {
      getStatus();
    }, 250);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (props.socket) {
        props.socket.on("statusChange", (st) => {
          console.log("CURRENT STATUS 2", st);
          setCurrStatus(st);
        });
      }
      return function () {
        if (props.socket) {
          props.socket.removeAllListeners("statusChange");
        }
      };
    }, 1000);
  });

  const mapColumnToDataName = {
    zone: "zoneName",
    manager: (zone) => isActiveInZone(zone?.zoneName, "admin"),
    kiosk: (zone) => isActiveInZone(zone?.zoneName, "device"),
  };

  const onChangeSort = (e) => {
    const { newSort, newArr } = sortArrByColumn(
      e,
      sort,
      zones,
      mapColumnToDataName
    );
    setSort(newSort);
    setZones(newArr);
  };

  function isActiveInZone(zone, role) {
    let ret = false;
    for (let i = 0; i < currStatus.length; i++) {
      if (currStatus[i].zone === zone && currStatus[i].client === role) {
        ret = true;
      }
    }
    return ret;
  }

  let indexOfLastZone = currentZonePage * zonesPerPage;
  let indexOfFirstZone = indexOfLastZone - zonesPerPage;
  if (currentZonePage === 1) {
    indexOfLastZone = indexOfLastZone - 1;
  } else {
    indexOfFirstZone = indexOfFirstZone - 1;
    indexOfLastZone = indexOfLastZone - 1;
  }
  const currentZones = zones
    ? zones.slice(indexOfFirstZone, indexOfLastZone)
    : null;
  const zonePaginate = (pageNumber) => setZonePage(pageNumber);

  const getTableData = () => ({
    columns: [
      { key: "index", label: "# Number", alignCenter: true, small: true },
      { key: "zone", label: "Zone Name", sortable: true },
      { key: "manager", label: "Web Console", sortable: true },
      { key: "kiosk", label: "Kiosk", sortable: true },
      { key: "actions", label: "Actions", alignCenter: true, noPadding: true },
    ],
    content: (currentZonePage === 1
      ? [{ unassigned: true }, ...currentZones]
      : currentZones
    ).map((zone, index) => {
      return [
        { key: "index", value: zone.number || "-" },
        { key: "zone", value: zone.zoneName ? zone.zoneName : "Unassigned" },
        {
          key: "manager",
          value: isActiveInZone(zone.zoneName || "default", "admin") ? (
            <i
              className="fa fa-arrow-circle-o-up fa-2x"
              style={{ color: "green" }}
            ></i>
          ) : (
            <i
              className="fa fa-arrow-circle-o-down fa-2x"
              style={{ color: "red" }}
            ></i>
          ),
        },
        {
          key: "kiosk",
          value: isActiveInZone(zone.zoneName || "default", "device") ? (
            <i
              className="fa fa-arrow-circle-o-up fa-2x"
              style={{ color: "green" }}
            ></i>
          ) : (
            <i
              className="fa fa-arrow-circle-o-down fa-2x"
              style={{ color: "red" }}
            ></i>
          ),
        },
        {
          key: "actions",
          value: !zone.unassigned ? (
            <Button
              className="deleteButton"
              color="primary"
              onClick={() => requestRemoveZone(zone)}
              onMouseEnter={() => setHovered(index)}
              onMouseLeave={() => setHovered(false)}
              style={
                hovered === index
                  ? { opacity: 1.0, backgroundColor: "#EB3446", color: "white" }
                  : { opacity: 0.0 }
              }
            >
              <i className="fa fa-trash  mr-2" aria-hidden="true" />
              Delete
            </Button>
          ) : null,
        },
      ];
    }),
  });

  return (
    <PermissionWrapper scopes={[SCOPES.zoneManagement]}>
      <SimpleHeader name="Zone management" parentName="Zone" />
      <Container className="mt-6" fluid>
        <Row className="justify-content-start">
          <Col className="flex-grow-1">
            <Card style={{ zIndex: 0 }}>
              <Table
                CustomHeaderTabs={() => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                    className="pr-4"
                  >
                    <h3 className="mb-0">Current Zones</h3>
                    <Button
                      color="primary"
                      onClick={() => setShowAddZoneModal(true)}
                    >
                      <i className="fa fa-plus mr-2" />
                      Add New Zone
                    </Button>
                  </div>
                )}
                sourcePage="addZone"
                content={getTableData().content}
                columns={getTableData().columns}
                sort={sort}
                onChangeSort={onChangeSort}
                Pagination={() => (
                  <nav aria-label="...">
                    <PaginationTable
                      per_page={zonesPerPage}
                      current_page={currentZonePage}
                      total_data={zones.length + 1}
                      handleClick={zonePaginate}
                    />
                  </nav>
                )}
              />
            </Card>
            {zoneDeleteModal ? (
              <ZoneConfirmDeleteModal
                setZoneModal={setZoneDeleteModal}
                requestRemoveZone={requestRemoveZone}
                zoneToEdit={zoneToEdit}
                removeZone={remZone}
              />
            ) : null}
            {showAddZoneModal ? (
              <AddZoneModal
                isOpen={showAddZoneModal}
                toggle={() => setShowAddZoneModal(false)}
                handleSubmit={handleSubmit}
                values={values}
                handleChange={handleChange}
                errors={errors}
              />
            ) : null}
          </Col>
        </Row>
      </Container>
    </PermissionWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    devices: state.devices,
    zones: state.zones,
    token: state.token,
    admin: state.admin,
    socket: state.socket,
    socketURL: state.socketURL,
  };
};

export default connect(mapStateToProps, {
  addZone,
  removeZone,
  findZones,
  getSocketEnvironment,
})(AddZone);
