import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  Container,
  Row,
  Col,
  CardHeader,
  Nav,
  NavItem,
} from "reactstrap";
import List from "list.js";
import {
  getImagesFromS3,
  clearImages,
  viewImages,
} from "../../actions/mediaActions";
import {
  defineUser,
  findUsers,
  findPrescreens,
  toggleUserArchive,
} from "../../actions/userActions";
import { sortArrByColumn } from "../../utils";
import { onLogout } from "../../actions/userActions";
import { getSocketEnvironment } from "../../actions/environmentActions";
import PaginationTable from "./TablePagination";
import LoadingIndicator from "./LoaderSpinner";
import SimpleHeader from "../SimpleHeader/SimpleHeader";
import "../securityApp.scss";
import lodash from "lodash";
import PrintBadgeModal from "../PrintBadge/PrintBadgeModal";
import { Table } from "../Table/Table";
import { ReactComponent as PictureIcon } from "../../assets/tabs/picture.svg";
import PermissionWrapper from "../permissions/PermissionWrapper";
import { SCOPES } from "../permissions/permission-map";

const mapColumnToDataName = {
  time: "timestamp",
  firstName: "firstName",
  lastName: "lastName",
  state: "state",
  dob: "DOB",
  type: "idType",
  number: "number",
  flag: "flag",
};

class Security extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      incomingCall: false,
      modal: false,
      per_page: 10,
      current_page: 1,
      current_page_prescreens: 1,
      current_page_archived: 1,
      total_data: "",
      image: "",
      imageId: "",
      searchInput: "",
      searchPrescreens: "",
      prescreenSearchInput: "",
      currentUsers: [],
      filteredUsers: [],
      archivedUsers: [],
      total_archived: "",
      showFilteredUsers: true,
      currentPrescreens: [],
      total_data_prescreens: "",
      filteredPrescreens: [],
      showPrescreens: false,
      isSearching: false,
      filteredPSData: [],
      filteredData: [],
      activeTab: 1,
      sort: {
        col: null,
        desc: false,
      },
      showPrintBadgeModal: false,
      userToPrint: {},
    };

    this.toggle = this.toggle.bind(this);
    this.toggleTab = this.toggleTab.bind(this);
    this.getStatusIcon = this.getStatusIcon.bind(this);
    this.displayPrintModal = this.displayPrintModal.bind(this);
    this.printVisitorBadge = this.printVisitorBadge.bind(this);

    if (!this.props.URL) {
      this.props.getSocketEnvironment();
    }
  }

  async componentDidMount() {
    await this.props.findUsers(this.props.token);
    await this.props.findPrescreens(this.props.token);
    this.props.socket.on("newEntry", () => {
      this.handleNewEntry();
    });
    this.setUsers();
    this.setPrescreens();
    this.toggleTab("1");

    // this.setState({showPrescreens: false})
    // console.log(this.state.showPrescreens);
    new List(this.refs["first-list"], {
      valueNames: ["index", "time", "name", "state", "dob", "type", "number"],
      listClass: "list",
    });
  }

  async handleNewEntry() {
    await this.props.findUsers(this.props.token);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.users.length !== prevProps.users.length) {
      this.setUsers();
    }
    if (this.props.prescreens.length !== prevProps.prescreens.length) {
      this.setPrescreens();
    }
  }

  afterSetUsers() {
    this.formatUserData();
    this.formatArchivedData();
  }

  setUsers() {
    const filtered = this.props.users.filter((user) => user.isArchived != true);
    const archived = this.props.users.filter((user) => user.isArchived == true);
    this.setState(
      {
        total_data: filtered.length,
        filteredUsers: filtered,
        total_archived: archived.length,
        archivedUsers: archived,
      },
      () => {
        this.afterSetUsers();
      }
    );
  }

  printVisitorBadge(user) {
    this.setState({
      userToPrint: user,
    });
    this.displayPrintModal(true);
  }

  displayPrintModal(displayed) {
    this.setState({
      showPrintBadgeModal: displayed,
    });
  }

  async toggleUserArchive(user) {
    const updatedUser = await this.props.toggleUserArchive(
      user,
      this.props.token
    );
    this.setUsers();
  }

  onChangeSort = (e) => {
    const { sort, activeTab, filteredUsers, filteredPrescreens } = this.state;

    let initArr;
    if (activeTab === "1") {
      initArr = filteredUsers;
    } else {
      initArr = filteredPrescreens;
    }

    const { newSort } = sortArrByColumn(e, sort, initArr, mapColumnToDataName);
    this.setState(
      {
        sort: newSort,
      },
      () => {
        if (activeTab === "1") {
          this.formatUserData();
        } else {
          this.formatPrescreenData();
        }
      }
    );
  };

  setPrescreens() {
    // this.props.findPrescreens(this.props.token)
    this.setState(
      {
        total_data_prescreens: this.props.prescreens.length,
        filteredPrescreens: this.props.prescreens,
      },
      () => {
        this.formatPrescreenData();
      }
    );
  }

  formatUserData() {
    const filtered = this.props.users.filter((user) => user.isArchived != true);
    const indexOfLastUser = this.state.current_page * this.state.per_page;
    const indexOfFirstUser = indexOfLastUser - this.state.per_page;

    const sortedArr =
      lodash.sortBy(filtered, mapColumnToDataName[this.state.sort.col]) || [];
    const orderedArr = this.state.sort.desc ? sortedArr.reverse() : sortedArr;
    const currentUsers = orderedArr.slice(indexOfFirstUser, indexOfLastUser);
    this.setState({ currentUsers, filteredUsers: currentUsers });
  }

  formatArchivedData() {
    const archived = this.props.users.filter((user) => user.isArchived == true);
    const indexOfLastUser =
      this.state.current_page_archived * this.state.per_page;
    const indexOfFirstUser = indexOfLastUser - this.state.per_page;

    const sortedArr =
      lodash.sortBy(archived, mapColumnToDataName[this.state.sort.col]) || [];
    const orderedArr = this.state.sort.desc ? sortedArr.reverse() : sortedArr;
    const archivedUsers = orderedArr.slice(indexOfFirstUser, indexOfLastUser);
    this.setState({ archivedUsers });
  }

  formatUserSearchData(filteredData) {
    console.log("inside formatUserSearchData, filteredData:", filteredData);
    const indexOfLastUser = this.state.current_page * this.state.per_page + 1;
    const indexOfFirstUser = indexOfLastUser - this.state.per_page;

    const currentUsers = filteredData.slice(indexOfFirstUser, indexOfLastUser);
    this.setState({ currentUsers, filteredUsers: currentUsers });
  }

  formatPrescreenData() {
    const indexOfLastPrescreen =
      this.state.current_page_prescreens * this.state.per_page;
    const indexOfFirstPrescreen = indexOfLastPrescreen - this.state.per_page;

    const currentPrescreens = this.props.prescreens.slice(
      indexOfFirstPrescreen,
      indexOfLastPrescreen
    );
    this.setState({ currentPrescreens, filteredPrescreens: currentPrescreens });
  }

  formatPrescreenSearchData() {
    const indexOfLastPrescreen =
      this.state.current_page_prescreens * this.state.per_page;
    const indexOfFirstPrescreen = indexOfLastPrescreen - this.state.per_page;

    const currentPrescreens = this.state.filteredPSData.slice(
      indexOfFirstPrescreen,
      indexOfLastPrescreen
    );
    this.setState({ currentPrescreens, filteredPrescreens: currentPrescreens });
  }

  handlePaginationClick = (number) => {
    if (this.state.activeTab === "1") {
      console.log("setting page for visitors");
      this.setState(
        {
          current_page: number,
        },
        () => {
          this.formatUserData();
        }
      );
    } else if (this.state.activeTab === "2") {
      console.log("setting page for prescreens");
      this.setState(
        {
          current_page_prescreens: number,
        },
        () => {
          this.formatPrescreenData();
        }
      );
    } else if (this.state.activeTab === "3") {
      console.log("setting page for archived");
      this.setState(
        {
          current_page_archived: number,
        },
        () => {
          this.formatArchivedData();
        }
      );
    }
  };

  handleImages = (info) => {
    console.log("info", info);
    this.props.viewImages();
    this.props.getImagesFromS3(info.image, info.imageId, info.sessionId);
    this.setState({ firstName: info.firstName });
    this.clear();
    this.toggle();
  };

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  toggleTab = (tab) => {
    if (this.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  clear = () => this.props.clearImages();

  handleSetData = (data) => {
    this.setState({ filteredUsers: data });
  };

  handleSetPrescreens = (data) => {
    this.setState({ filteredPrescreens: data });
  };

  handleChange = (event) => {
    this.setState({ isSearching: true });

    if (this.state.showPrescreens) {
      console.log("handling prescreens search");
      this.setState({ prescreenSearchInput: event.target.value }, () => {
        this.globalPrescreensSearch();
      });
    } else {
      console.log("handling visitors search");
      this.setState({ searchInput: event.target.value }, () => {
        this.globalVisitorSearch();
      });
    }
  };

  handlePrescreens = () => {
    console.log("show prescreens button clicked");
    if (this.state.showPrescreens) {
      this.setState({ searchInput: "", isSearching: false });
    } else {
      this.setState({ prescreenSearchInput: "", isSearching: false });
    }
    // this.setState({showPrescreens: "true"})
    // console.log(this.state.showPrescreens)
    this.setState((prevState) => ({
      showPrescreens: !prevState.showPrescreens,
      showFilteredUsers: !prevState.showFilteredUsers,
    }));
    // console.log()
  };

  globalVisitorSearch = () => {
    this.setState({ isSearching: true });
    // if(this.state.isSearching){}

    console.log(
      "inside globalVisitorSearch",
      "isSearching:",
      this.state.isSearching
    );
    let { searchInput } = this.state;
    if (searchInput.length === 0) {
      this.setState({ isSearching: false });
    }
    let filteredData = this.props.users.filter((filteredUser) => {
      return (
        filteredUser.firstName
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        filteredUser.lastName
          .toLowerCase()
          .includes(searchInput.toLowerCase()) ||
        filteredUser.state.toLowerCase().includes(searchInput.toLowerCase()) ||
        filteredUser.DOB.includes(searchInput) ||
        filteredUser.idType.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.handleSetData(filteredData);
    this.setState({ filteredData: filteredData });
    if (this.state.isSearching) {
      this.formatUserSearchData(filteredData);
    }
    if (searchInput === "") {
      this.formatUserData();
    }
  };

  globalPrescreensSearch = () => {
    console.log("inside globalPrescreensSearch");
    let { filteredPrescreens } = this.state;
    console.log("filteredPrescreens", filteredPrescreens);
    let { prescreenSearchInput } = this.state;
    console.log(prescreenSearchInput);
    let filteredPSData = this.props.prescreens.filter((filteredPrescreens) => {
      return (
        filteredPrescreens.firstName
          .toLowerCase()
          .includes(prescreenSearchInput.toLowerCase()) ||
        filteredPrescreens.lastName
          .toLowerCase()
          .includes(prescreenSearchInput.toLowerCase()) ||
        filteredPrescreens.state
          .toLowerCase()
          .includes(prescreenSearchInput.toLowerCase()) ||
        filteredPrescreens.DOB.includes(prescreenSearchInput) ||
        filteredPrescreens.idType
          .toLowerCase()
          .includes(prescreenSearchInput.toLowerCase())
      );
    });
    this.setState({ filteredPSData });
    console.log("filteredPSData", filteredPSData);

    this.handleSetPrescreens(filteredPSData);

    if (prescreenSearchInput === "") {
      this.formatPrescreenData();
    }
  };

  getStatusIcon = (code) => {
    if (code.length === 0) {
      return (
        <i className="fa fa-circle" style={{ color: "gray" }}>
          clear
        </i>
      );
    }
    if (code.indexOf("q") !== -1 || code.indexOf("t") !== -1) {
      if (code.indexOf("p") !== -1) {
        return (
          <i className="fa fa-circle" style={{ color: "green" }}>
            pass
          </i>
        );
      }
      if (code.indexOf("f") !== -1) {
        return (
          <i className="fa fa-circle" style={{ color: "red" }}>
            reject
          </i>
        );
      }
      return (
        <i className="fa fa-circle" style={{ color: "orange" }}>
          warn
        </i>
      );
    }
  };

  get1TabData = () => {
    const columns = [
      { key: "index", label: "# Number", alignCenter: true, small: true },
      {
        key: "time",
        label: "Timestamp",
        sortable: true,
        large: true,
        smallFontSize: true,
      },
      { key: "firstName", label: "First Name", sortable: true },
      { key: "lastName", label: "Last Name", sortable: true },
      { key: "state", label: "State", sortable: true, smallFontSize: true },
      {
        key: "dob",
        label: "Date of Birth",
        sortable: true,
        smallFontSize: true,
      },
      { key: "type", label: "ID Type", sortable: true, smallFontSize: true },
      { key: "number", label: "ID Number", sortable: true },
      {
        key: "flag",
        label: "Flag",
        sortable: true,
        alignCenter: true,
        noPadding: true,
      },
      { key: "photos", label: "Photos", alignCenter: true, noPadding: true },
      { key: "email", label: "Email", alignCenter: true, noPadding: true },
      {
        key: "organization",
        label: "Organization",
        alignCenter: true,
        noPadding: true,
      },
      {
        key: "vcn",
        label: "Visiting Contact Name",
        alignCenter: true,
        noPadding: true,
      },
      {
        key: "printBadge",
        label: "Print badge",
        alignCenter: true,
        noPadding: true,
      },
    ];
    if (this.props.admin.authType === "admin") {
      columns.push({
        key: "archive",
        label: "Archive User",
        alignCenter: true,
        noPadding: true,
      });
    }
    return {
      columns,
      content: this.state.filteredUsers.map((user, index) => {
        const date = new Date(user.timestamp);
        const visitorDate = date.toLocaleString("en-US", {
          timeZone: "America/Chicago",
        });

        return [
          { key: "index", value: index + 1 },
          { key: "time", value: visitorDate },
          { key: "firstName", value: user.firstName },
          { key: "lastName", value: user.lastName },
          { key: "state", value: user.state },
          { key: "dob", value: user.DOB },
          { key: "type", value: user.idType },
          { key: "number", value: user.number },
          { key: "flag", value: this.getStatusIcon(user.flag) },
          { key: "email", value: user.email },
          { key: "organization", value: user.organization },
          { key: "visitingContactName", value: user.visitingContactName },
          {
            key: "photos",
            value: (
              <Button
                onClick={() => this.handleImages(user)}
                color="grey"
                size="md p-0"
                type="button"
              >
                <PictureIcon />
              </Button>
            ),
          },
          {
            key: "printBadge",
            value: (
              <Button
                onClick={() => this.printVisitorBadge(user)}
                color="light"
                size="sm"
                type="button"
              >
                Print
              </Button>
            ),
          },
          {
            key: "archive",
            value: (
              <Button
                onClick={() => this.toggleUserArchive(user)}
                color="danger"
                size="sm"
                type="button"
              >
                Archive
              </Button>
            ),
          },
        ];
      }),
    };
  };

  get2TabData = () => ({
    columns: [
      { key: "index", label: "# Number", alignCenter: true, small: true },
      {
        key: "time",
        label: "Timestamp",
        sortable: true,
        large: true,
        smallFontSize: true,
      },
      { key: "firstName", label: "First Name", sortable: true },
      { key: "lastName", label: "Last Name", sortable: true },
      { key: "state", label: "State", sortable: true, smallFontSize: true },
      {
        key: "dob",
        label: "Date of Birth",
        sortable: true,
        smallFontSize: true,
      },
      { key: "type", label: "ID Type", sortable: true, smallFontSize: true },
      { key: "number", label: "ID Number", sortable: true },
      { key: "photos", label: "Photos", alignCenter: true, noPadding: true },
      { key: "email", label: "Email", alignCenter: true, noPadding: true },
      {
        key: "organization",
        label: "Organization",
        alignCenter: true,
        noPadding: true,
      },
      {
        key: "vcn",
        label: "Visiting Contact Name",
        alignCenter: true,
        noPadding: true,
      },
      {
        key: "printBadge",
        label: "Print badge",
        alignCenter: true,
        noPadding: true,
      },
    ],
    content: this.state.filteredPrescreens.map((user, index) => {
      const date = new Date(user.timestamp);
      const visitorDate = date.toLocaleString("en-US", {
        timeZone: "America/Chicago",
      });

      return [
        { key: "index", value: index + 1 },
        { key: "time", value: visitorDate },
        { key: "firstName", value: user.firstName },
        { key: "lastName", value: user.lastName },
        { key: "state", value: user.state },
        { key: "dob", value: user.DOB },
        { key: "type", value: user.idType },
        { key: "number", value: user.number },
        { key: "email", value: user.email },
        { key: "organization", value: user.organization },
        { key: "visitingContactName", value: user.visitingContactName },
        {
          key: "photos",
          value: (
            <Button
              onClick={() => this.handleImages(user)}
              color="grey"
              size="md p-0"
              type="button"
            >
              <PictureIcon />
            </Button>
          ),
        },
        {
          key: "printBadge",
          value: (
            <Button
              onClick={() => this.printVisitorBadge(user)}
              color="light"
              size="sm"
              type="button"
            >
              Print
            </Button>
          ),
        },
      ];
    }),
  });

  get3TabData = () => ({
    columns: [
      { key: "index", label: "# Number", alignCenter: true, small: true },
      {
        key: "time",
        label: "Timestamp",
        sortable: true,
        large: true,
        smallFontSize: true,
      },
      { key: "firstName", label: "First Name", sortable: true },
      { key: "lastName", label: "Last Name", sortable: true },
      { key: "state", label: "State", sortable: true, smallFontSize: true },
      {
        key: "dob",
        label: "Date of Birth",
        sortable: true,
        smallFontSize: true,
      },
      { key: "type", label: "ID Type", sortable: true, smallFontSize: true },
      { key: "number", label: "ID Number", sortable: true },
      { key: "photos", label: "Photos", alignCenter: true, noPadding: true },
      {
        key: "printBadge",
        label: "Print badge",
        alignCenter: true,
        noPadding: true,
      },
      {
        key: "restore",
        label: "Restore user",
        alignCenter: true,
        noPadding: true,
      },
    ],
    content: this.state.archivedUsers.map((user, index) => {
      const date = new Date(user.timestamp);
      const visitorDate = date.toLocaleString("en-US", {
        timeZone: "America/Chicago",
      });

      return [
        { key: "index", value: index + 1 },
        { key: "time", value: visitorDate },
        { key: "firstName", value: user.firstName },
        { key: "lastName", value: user.lastName },
        { key: "state", value: user.state },
        { key: "dob", value: user.DOB },
        { key: "type", value: user.idType },
        { key: "number", value: user.number },
        {
          key: "photos",
          value: (
            <Button
              onClick={() => this.handleImages(user)}
              color="grey"
              size="md p-0"
              type="button"
            >
              <PictureIcon />
            </Button>
          ),
        },
        {
          key: "printBadge",
          value: (
            <Button
              onClick={() => this.printVisitorBadge(user)}
              color="light"
              size="sm"
              type="button"
            >
              Print
            </Button>
          ),
        },
        {
          key: "restore",
          value: (
            <Button
              onClick={() => this.toggleUserArchive(user)}
              color="primary"
              size="sm"
              type="button"
            >
              Restore
            </Button>
          ),
        },
      ];
    }),
  });

  getTabData = () => {
    const { activeTab } = this.state;
    if (activeTab === "1") {
      return this.get1TabData();
    } else if (activeTab === "2") {
      return this.get2TabData();
    }
    return this.get3TabData();
  };

  render() {
    const {
      per_page,
      total_data,
      current_page,
      total_archived,
      current_page_archived,
      showPrescreens,
      total_data_prescreens,
      current_page_prescreens,
      isSearching,
      filteredData,
      filteredPSData,
    } = this.state;

    return (
      <PermissionWrapper scopes={[SCOPES.visitorsLog]}>
        {this.state.showPrintBadgeModal ? (
          <PrintBadgeModal
            setIsShowing={this.displayPrintModal}
            user={this.state.userToPrint}
          />
        ) : null}
        <SimpleHeader
          name={showPrescreens ? "Prescreens Log" : "Visitors Log"}
          parentName="Security"
        />
        <div>
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggle}>
              {this.state.firstName}
            </ModalHeader>
            <ModalBody>
              {this.props.picturesLoading ? (
                <LoadingIndicator />
              ) : !this.props.images ? (
                <h5>There are no images to display</h5>
              ) : (
                <>
                  {this.props.image ? (
                    <img
                      className="userImages"
                      src={this.props.image}
                      alt="User"
                    ></img>
                  ) : null}
                  <img
                    className="userImages"
                    src={this.props.imageId}
                    alt="User ID"
                  ></img>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggle}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        <Container className="mt-2" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader
                  className="d-flex justify-content-between"
                  style={{ border: "none", paddingBottom: "0" }}
                >
                  <Row
                    style={{
                      display: "flex",
                      width: "104%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col className="d-flex align-items-center">
                      <h2 className="h2 mb-4">
                        {showPrescreens
                          ? "Latest Prescreen Entries"
                          : "Latest Visitor Entries"}
                      </h2>
                    </Col>
                    <Col>
                      <Row className="d-flex justify-content-between">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {showPrescreens
                            ? isSearching
                              ? `${filteredPSData.length} search results`
                              : null
                            : isSearching
                            ? `${filteredData.length} search results`
                            : null}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </CardHeader>

                <Table
                  sourcePage="visitorLog"
                  content={this.getTabData().content}
                  columns={this.getTabData().columns}
                  sort={this.state.sort}
                  onChangeSort={this.onChangeSort}
                  CustomHeaderTabs={() => (
                    <Nav tabs style={{ border: "none" }}>
                      <NavItem>
                        <Button
                          className="bg-secondary"
                          onClick={() => {
                            this.toggleTab("1");
                            this.setState({ showPrescreens: false });
                          }}
                          style={
                            this.state.activeTab === "1"
                              ? {
                                  padding: "0.5rem 1em",
                                  margin: 0,
                                  color: "#605FF3",
                                  fontWeight: "bold",
                                  borderColor: "rgb(94,114,228)",
                                }
                              : { padding: "0.5rem 1em", margin: 0 }
                          }
                        >
                          <i
                            className="fa fa-users mr-2"
                            aria-hidden="true"
                            style={
                              this.state.activeTab === "1"
                                ? { color: "#605FF3" }
                                : null
                            }
                          ></i>
                          Visitor Entries
                        </Button>
                      </NavItem>
                      <NavItem>
                        <Button
                          onClick={() => {
                            this.toggleTab("2");
                            this.setState({ showPrescreens: true });
                          }}
                          style={
                            this.state.activeTab === "2"
                              ? {
                                  padding: "0.5rem 1em",
                                  margin: "0 0 0 1rem",
                                  color: "#5e72e4",
                                  fontWeight: "bold",
                                  borderColor: "rgb(94,114,228)",
                                }
                              : { padding: "0.5rem 1em", margin: "0 0 0 1rem" }
                          }
                        >
                          <i
                            className="fa fa-qrcode mr-2"
                            aria-hidden="true"
                            style={
                              this.state.activeTab === "2"
                                ? { color: "#5e72e4" }
                                : null
                            }
                          ></i>
                          Pre-Screen Log
                        </Button>
                      </NavItem>
                      {this.props.admin.authType === "admin" ? (
                        <NavItem>
                          <Button
                            onClick={() => {
                              this.toggleTab("3");
                              this.setState({ showPrescreens: true });
                            }}
                            style={
                              this.state.activeTab === "3"
                                ? {
                                    padding: "0.5rem 1em",
                                    margin: "0 0 0 1rem",
                                    color: "#5e72e4",
                                    fontWeight: "bold",
                                    borderColor: "rgb(94,114,228)",
                                  }
                                : {
                                    padding: "0.5rem 1em",
                                    margin: "0 0 0 1rem",
                                  }
                            }
                          >
                            <i
                              className="fa fa-archive mr-2"
                              aria-hidden="true"
                              style={
                                this.state.activeTab === "3"
                                  ? { color: "#5e72e4" }
                                  : null
                              }
                            ></i>
                            Archive
                          </Button>
                        </NavItem>
                      ) : null}
                    </Nav>
                  )}
                  Pagination={() => (
                    <nav aria-label="...">
                      {this.state.activeTab === "1" ? (
                        <PaginationTable
                          per_page={per_page}
                          current_page={current_page}
                          total_data={total_data}
                          handleClick={this.handlePaginationClick}
                        />
                      ) : null}
                      {this.state.activeTab === "2" ? (
                        <PaginationTable
                          per_page={per_page}
                          current_page={current_page_prescreens}
                          total_data={total_data_prescreens}
                          handleClick={this.handlePaginationClick}
                        />
                      ) : null}
                      {this.state.activeTab === "3" ? (
                        <PaginationTable
                          per_page={per_page}
                          current_page={current_page_archived}
                          total_data={total_archived}
                          handleClick={this.handlePaginationClick}
                        />
                      ) : null}
                    </nav>
                  )}
                />
              </Card>
            </div>
          </Row>
        </Container>
      </PermissionWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
    users: state.users,
    prescreens: state.prescreens,
    images: state.images,
    image: state.image,
    imageId: state.imageId,
    isAuthenticated: state.isAuthenticated,
    token: state.token,
    picturesLoading: state.picturesLoading,
  };
};

export default connect(mapStateToProps, {
  defineUser,
  findUsers,
  findPrescreens,
  getImagesFromS3,
  onLogout,
  clearImages,
  viewImages,
  getSocketEnvironment,
  toggleUserArchive,
})(Security);
